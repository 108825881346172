import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledXframe from 'components/molecules/Frames/Xframe/Xframe.styles';
import {apiHost, apiPath} from 'helpers/hooks/api';
import {useAuthToken} from 'services/auth/auth.utils';
import constants from 'helpers/constants';

const injectStyle = `
  .xframe_overlay {
    opacity:    1; 
    background: white; 
    width:      100%;
    height:     100%; 
    z-index:    1000;
    top:        0; 
    left:       0; 
    position:   fixed; 
  }
  .xframe_loader {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background-color: #333;
    border-radius: 50%;  
    animation: xframe_loader 1s infinite ease-in-out;
  }
  @keyframes xframe_loader {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }`;

const injectScript = `
  // X-Frame-Bypass navigation event handlers
  try {
    if (!window.xframeEventsDone) {
      window.xframeEventsDone = true;
      const xframeEvents = {
        init: function() {	
          let self = this;
          function isValidElementClick(target) {
            try {
              new URL(target.href);
              return true;
            } catch {
              return false;
            }
          }
          
          function isValidElementSubmit(target) {
            try {
              new URL(target.form.action);
              return true;
            } catch {
              return false;
            }
          }
          
          function isCookieElement(target) {
            return target && (target.outerHTML.toLowerCase().indexOf('cookie') !== -1 || 
                             (target.parentElement && target.parentElement.outerHTML.toLowerCase().indexOf('cookie') !== -1));
          }
        
          document.addEventListener('click', e => {
            try {
              if (self.xFrameMainWindow) {
                let el = document.activeElement;
                if (!isValidElementClick(el)) {
                  el = e.target;
                  if (!isValidElementClick(el)) {
                    el = e.target.parentElement;
                    if (!isValidElementClick(el)) {
                      return;
                    }
                  }
                }
                
                e.preventDefault();
                e.stopPropagation();
            
                if (!isCookieElement(el)) {
                  self.xFrameMainWindow.postMessage(self.xFrameId + ':' + JSON.stringify({url: el.href}), '*');
                }
                return false; 
              }
            } catch(err) {
              console.log('xframe click error', err);
            }
          });
          
          document.addEventListener('submit', e => {
            try {
              if (self.xFrameMainWindow) {
                let el = document.activeElement;
                if (!isValidElementSubmit(el)) {
                  el = e.target;
                  if (!isValidElementSubmit(el)) {
                   return;
                  }
                }
                e.preventDefault();
                e.stopPropagation();
                if (!isCookieElement(el)) {
                  if (el.form.method === 'post') {
                    self.xFrameMainWindow.postMessage(self.xFrameId + ':' + JSON.stringify({url: el.form.action, options: {method: 'post', body: JSON.stringify(Object.fromEntries(new FormData(el.form)), '*')}}), '*'); 
                  } else {
                    self.xFrameMainWindow.postMessage(self.xFrameId + ':' + JSON.stringify({url: el.form.action + '?' + new URLSearchParams(new FormData(el.form))}), '*'); 
                  }
                }
                return false;
              }
            } catch(err) {
              console.log('xframe submit error', err);
            }
          });
          
          window.addEventListener('error', e => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          });
          
          window.addEventListener('load', e => {
            let connectId = '[CONNECT_ID]';
            if (document.body && document.body.querySelectorAll('div, span, a, p').length > 8) {
              const overLay = document.querySelector('.xframe_overlay');
              if (overLay) {
                overLay.style.visibility = 'hidden';
              }
              self.xFrameFailedToLoad = false;	
              if (!self.xFrameFailedToLoad) {
                self.xFrameMainWindow.postMessage(self.xFrameId + ':SUCCESS' + connectId, '*'); 
              } else {
                self.xFrameMainWindow.postMessage(self.xFrameId + ':ERROR', '*'); 
              }
            } else {
              self.xFrameFailedToLoad = true;	
              if (self.xFrameMainWindow) {
                self.xFrameMainWindow.postMessage(self.xFrameId + ':ERROR', '*'); 
              }
            }
          });
          
          window.addEventListener('message', e => {
            let connectId = '[CONNECT_ID]';
            if (e.data && e.data.endsWith(':CONNECT')) {           
              self.xFrameMainWindow = e.source;
              self.xFrameId = e.data.split(':')[0];
              if (!self.xFrameFailedToLoad) {
                self.xFrameMainWindow.postMessage(self.xFrameId + ':HELLO' + connectId, '*'); 
              } else {
                self.xFrameMainWindow.postMessage(self.xFrameId + ':ERROR', '*'); 
              }
            }
          });
        }
      }.init();
    }
  } catch(err) {
    //ignore 
  }`;

const pageStates = {
  LOAD: 1,
  WAITING: 2,
  SUCCESS: 3,
  FAILED: 4,
};

const pageAttributes = {
  SERVER: 1,
  HTTPS: 2,
  HTTP: 3,
  WWW: 4,
};

const pageSteps = [
  [],
  [pageAttributes.HTTP],
  [pageAttributes.HTTPS],
  [pageAttributes.WWW],
  [pageAttributes.HTTP, pageAttributes.WWW],
  [pageAttributes.HTTPS, pageAttributes.WWW]
]

const newPage = (id, url, options, step, server) => {
  return {
    id: id,
    url: url,
    actualUrl: url,
    state: pageStates.LOAD,
    options: options ?? {},
    step: step ?? 0,
    server: server ?? false
  }
};

function fetchProxy (url, token, options, server, i) {
  const proxy = [
    apiHost + apiPath + '/cors/',
    // 'https://jsonp.afeld.me/?url=',
    // 'https://cors-anywhere.herokuapp.com/'
  ];

  let tmpOptions = Object.assign({}, options),
    ownApi = proxy[i].startsWith(apiHost);

  if (ownApi) {
    tmpOptions.headers = Object.assign({}, tmpOptions.headers, {
      'x-access-token': token,
      'x-frame-header': !server
    });
  }

  tmpOptions.timeout = server ? 5000 : 1000;

  return utils.fetchWithTimeout(proxy[i] + url, tmpOptions)
    .then(res => {
      if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`);
      }

      const useServer = res.headers.get('X-Frame-Blocked') && res.headers.get('X-Frame-Blocked').toLowerCase() === 'true';

      if (!server && useServer) {
        return fetchProxy(url, token, options, true, i);
      } else {
        let redirect = res.headers.get('X-Frame-Redirected');
        if (redirect) {
          redirect = utils.baseUrl(url, redirect);
        }

        return {res, redirect, server};
      }
    })
    .catch((error) => {
      const next = (!server && ownApi) ? i : i + 1;
      if (next > (proxy.length - 1)) {
        return {res: null, error};
      }
      return fetchProxy(url, token, options, true, next);
    });
}

const Xframe = React.forwardRef((props, ref) => {
  const {
    url,
    state,
    onLoading,
    onWaiting,
    onFailed,
    onSuccess,
    ...innerProps
  } = useComponentProps(props, 'Xframe'); // possible child modifiers for theme

  const innerRef = useRef(null);
  const iframeIdRef = useRef(Math.ceil(Math.random() * constants.numbers.randomInt));
  const token = useAuthToken();

  const [internalState, setInternalState] = useState({
    id: utils.md5(url + iframeIdRef.current),
    iframeCount: iframeIdRef.current,
    history: [],
    pageIndex: -1
  });

  const connected = useRef({id: 0, count: 0, active: false});

  const doReset = useCallback((url) => {
    iframeIdRef.current += 1;
    url = utils.prefixUrl(url);
    setInternalState(utils.updater({
      startUrl: url,
      history: [newPage(iframeIdRef.current, url)],
      pageIndex: 0,
      src: null,
      srcDoc: null
    }, true));
  }, []);

  const stateMemo = useEffectItem(state);
  const xframe = useMemo(() => ({
    ref: innerRef,
    state: {...internalState, ...stateMemo},
    back: () => {
      setInternalState((current) => {
        if (current.history.length > 0 && current.pageIndex > 0) {
          const history = current.history[current.pageIndex].state === pageStates.FAILED ?
            current.history.slice(0, -1) : [...current.history];
          const success = history[current.pageIndex - 1].state === pageStates.SUCCESS;
          history[current.pageIndex - 1].state = success ?
            pageStates.WAITING : pageStates.LOAD;
          if (!success) {
            history[current.pageIndex - 1].step = 0;
            history[current.pageIndex - 1].server = false;
          }

          return {
            ...current,
            history,
            pageIndex: current.pageIndex - 1,
          };
        } else {
          return current;
        }
      });
    },
    forward: () => {
      setInternalState((current) => {
        if (current.pageIndex < current.history.length - 1) {
          const history = [...current.history];
          const success = history[current.pageIndex + 1].state === pageStates.SUCCESS;
          history[current.pageIndex + 1].state = success ?
            pageStates.WAITING : pageStates.LOAD;
          if (!success) {
            history[current.pageIndex + 1].step = 0;
            history[current.pageIndex + 1].server = false;
          }

          return {
            ...current,
            history,
            pageIndex: current.pageIndex + 1,
          }
        } else {
          return current;
        }
      });
    },
    reset: (url) => {
      doReset(url);
    },
    reload: () => {
      setInternalState((current) => {
        if (current.history.length > 0 && current.pageIndex < current.history.length) {
          const history = [...current.history];
          history[current.pageIndex].state = pageStates.LOAD;
          history[current.pageIndex].step = 0;
          history[current.pageIndex].server = false;

          return {
            ...current,
            history,
          };
        } else {
          return current;
        }
      })
    }
  }), [internalState, stateMemo, doReset]);

  // useEffectItem will trigger effects on changes because we force the change by making a copy...
  const currentPage = useEffectItem((xframe.state.pageIndex >= 0 && xframe.state.pageIndex < xframe.state.history.length) ?
    {...xframe.state.history[xframe.state.pageIndex]} : null);

  useImperativeHandle(ref, () => xframe);

  const pageUrl = useCallback((page) => {
    const attributes = pageSteps[page.step];

    try {
      let parsedUrl = new URL(page.actualUrl, xframe.state.startUrl);

      if (attributes.includes(pageAttributes.HTTPS)) {
        parsedUrl = new URL(parsedUrl.href.replace('http://', 'https://'));
      }
      if (attributes.includes(pageAttributes.HTTP)) {
        parsedUrl = new URL(parsedUrl.href.replace('https://', 'http://'));
      }
      if (parsedUrl.hostname.split('.').length === 2) {
        if (attributes.includes(pageAttributes.WWW)) {
          parsedUrl.hostname = 'www.' + parsedUrl.hostname;
        }
      }

      return parsedUrl.href;
    } catch (err) {
      return null;
    }
  }, [xframe.state.startUrl]);

  const handleFailed = useCallback((retry = false) => {
    setInternalState((current) => {
      if (current.history.length > 0 && current.pageIndex < current.history.length) {
        const history = [...current.history];
        const didSteps = history[current.pageIndex].step >= (pageSteps.length - 1);

        history[current.pageIndex].state = (!retry || didSteps) ? pageStates.FAILED : pageStates.LOAD;
        if (history[current.pageIndex].state !== pageStates.FAILED) {
          const currentUrl = pageUrl(history[current.pageIndex]);

          while (history[current.pageIndex].step < (pageSteps.length - 1)) {
            history[current.pageIndex].step += 1;

            if (pageUrl(history[current.pageIndex]) !== currentUrl) {
              break;
            }
          }

          if (history[current.pageIndex].step >= (pageSteps.length - 1)) {
            history[current.pageIndex].state = pageStates.FAILED;
          }
        }

        return {
          ...current,
          history,
        };
      } else {
        return current;
      }
    });
  }, [pageUrl]);

  const handleSuccess = useCallback(() => {
    setInternalState((current) => {
      if (current.history.length > 0 && current.pageIndex < current.history.length) {
        const history = [...current.history];
        if (history[current.pageIndex].state === pageStates.WAITING) {
          history[current.pageIndex].state = pageStates.SUCCESS;

          return {
            ...current,
            history,
          };
        }
      }
      return current;
    })
  }, []);

  const handleMessages = useCallback((e) => {
    if (e.data && typeof e.data === 'string' && e.data.startsWith(xframe.state.id + ':')) {
      let msg = e.data.replace(xframe.state.id + ':', '');
      if (msg === 'ERROR') {
        handleFailed(true);
      } else if (msg.startsWith('HELLO')) {
        if (msg === ('HELLO' + currentPage.connectId)) {
          connected.current.active = true; // stops trying
          handleSuccess();
        }
      } else if (msg.startsWith('SUCCESS')) {
        if (msg === ('SUCCESS' + currentPage.connectId)) {
          connected.current.active = true; // stops trying
        }
      } else {
        msg = JSON.parse(msg);
        setInternalState((current) => {
          iframeIdRef.current += 1;
          return {
            ...current,
            history: [...current.history, newPage(iframeIdRef.current, msg.url, msg.options, currentPage.step, currentPage.server)],
            pageIndex: current.history.length
          };
        });
      }
    }
  }, [currentPage, handleFailed, xframe.state.id, handleSuccess]);

  const handleIframeLoad = useCallback(() => {
    if (!currentPage.server) {
      handleSuccess();
    }
  }, [currentPage, handleSuccess]);

  const tryToConnect = useCallback(() => {
    if (!connected.current.active && connected.current.count > 0) {
      connected.current.count -= 1;
      if (xframe.ref?.current.contentWindow) {
        xframe.ref?.current.contentWindow.postMessage(xframe.state.id + ':CONNECT', '*');
      }
    }
  }, [xframe.ref, xframe.state.id]);

  const handleWaitingForSuccess = useCallback((page, actualUrl, src, server, connectId) => {
    try {
      if (actualUrl) {
        const parsedUrl = new URL(actualUrl);
        actualUrl = parsedUrl.href.endsWith('/') ? parsedUrl.href.slice(0, parsedUrl.href.length - 1) : parsedUrl.href;
      }
    } catch {
      /* SQUASH */
    }

    setInternalState((current) => {
      if (current.history.length > 0 && current.pageIndex < current.history.length) {
        const history = [...current.history];
        if (history[current.pageIndex].id === page.id) {
          history[current.pageIndex].state = pageStates.WAITING;
          history[current.pageIndex].actualUrl = actualUrl;
          history[current.pageIndex].src = !server ? src : null;
          history[current.pageIndex].srcDoc = server ? src : null;
          history[current.pageIndex].connectId = connectId;
          history[current.pageIndex].server = server;

          return {
            ...current,
            history,
          };
        }
      }
      return current;
    })
  }, []);

  const startLoad = useCallback((page) => {
    const parsedUrl = pageUrl(page);

    if (parsedUrl) {
      const defaultOptions = {
        mode: 'cors',
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
      };
      const server = true; // page.server; always server

      fetchProxy(parsedUrl, token, Object.assign({}, defaultOptions, page.options), server, 0)
        .then((fetchResult) => {
          if (fetchResult.error) {
            handleFailed(true);
          } else {
            let actualUrl = new URL(parsedUrl);
            if (fetchResult.redirect) {
              actualUrl = new URL(fetchResult.redirect);
            }

            const connectId = Math.ceil(Math.random() * constants.numbers.randomInt);
            if (!fetchResult.server) {
              handleWaitingForSuccess(page, fetchResult.redirect, actualUrl.href, false, connectId);
            } else {
              fetchResult.res.text()
                .then((data) => {
                  const srcDoc = data
                    .replace(/<head([^>]*)>/i, `<head$1>
                  <base href="${actualUrl.origin}${actualUrl.pathname}">
                  <style type="text/css">${injectStyle}</style>
                  <script type="text/javascript">${injectScript.replace(/\[CONNECT_ID\]/g, connectId)}</script>
                `)
                    .replace(/<body([^>]*)>/i, `<body$1>
                  <script type="text/javascript">${injectScript.replace(/\[CONNECT_ID\]/g, connectId)}</script>
                `);

                  handleWaitingForSuccess(page, fetchResult.redirect, srcDoc, true, connectId);
                });
            }
          }
        });
    } else {
      handleFailed(true);
    }
  }, [token, pageUrl, handleFailed, handleWaitingForSuccess]);

  useEffect(() => {
    if (url !== xframe.state.startUrl) {
      doReset(url);
    }
  }, [url, xframe.state.startUrl, doReset]);

  useEffect(() => {
    return utils.observeEvent(window, 'message', handleMessages);
  }, [handleMessages]);

  // events
  const onLoadingEvent = useEffectEvent(onLoading);
  const onWaitingEvent = useEffectEvent(onWaiting);
  const onFailedEvent = useEffectEvent(onFailed);
  const onSuccessEvent = useEffectEvent(onSuccess);

  useEffect(() => {
    if (currentPage?.state === pageStates.LOAD) {
      startLoad(currentPage);
      onLoadingEvent?.(currentPage.url, currentPage.actualUrl);
    } else if (currentPage?.state === pageStates.WAITING) {
      connected.current.active = false;
      connected.current.count = 10;

      setInternalState((current) => {
        if (current.history.length > 0 && current.pageIndex < current.history.length) {
          return {
            ...current,
            id: utils.md5(current.history[current.pageIndex].actualUrl + current.iframeCount + 1),
            iframeCount: current.iframeCount + 1,
            src: current.history[current.pageIndex].src,
            srcDoc: current.history[current.pageIndex].srcDoc
          }
        } else {
          return current;
        }
      });

      onWaitingEvent?.(currentPage.url, currentPage.actualUrl);
    } else if (currentPage?.state === pageStates.SUCCESS) {
      connected.current.count = 0;
      onSuccessEvent?.(currentPage.url, currentPage.actualUrl);
    } else if (currentPage?.state === pageStates.FAILED) {
      connected.current.count = 0;
      onFailedEvent?.(currentPage.url, currentPage.actualUrl);
    }
  }, [currentPage, startLoad, onLoadingEvent,
    onWaitingEvent, onFailedEvent, onSuccessEvent]);

  useEffect(() => {
    if (currentPage?.state === pageStates.WAITING) {
      const cleanup = [];
      if (currentPage.server) {
        cleanup.push(utils.observeInterval(tryToConnect, 100));
      }
      cleanup.push(utils.observeTimeout(handleFailed, 5000));

      return () => cleanup.forEach((cl) => cl());
    }
  }, [currentPage, handleFailed, tryToConnect]);

  innerProps.onLoad = innerProps.onLoad ?? handleIframeLoad;
  const sandbox = useMemo(() => {
    return [
      'allow-scripts',
      'allow-forms'
    ].join(' ');
  }, []);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledXframe ref={xframe.ref} {...innerProps}
                       key={xframe.state.id}
                       title={xframe.state.id}
                       sandbox={sandbox}
                       src={xframe.state.src}
                       srcDoc={xframe.state.srcDoc}
                       onLoad={handleIframeLoad}>
  </StyledXframe>
});

Xframe.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  state: PropTypes.object,
  url: PropTypes.string,
  onLoading: PropTypes.func,
  onWaiting: PropTypes.func,
  onFailed: PropTypes.func,
  onSuccess: PropTypes.func
};

Xframe.defaultProps = {
};

export default Xframe;
