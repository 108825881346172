import React, {useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledDialog from 'components/organisms/Dialogs/Dialog/Dialog.styles';
import utils from 'helpers/utils';
import Wrapper from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import dom from 'helpers/dom';
import SnackbarProvider from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

export const DialogContext = React.createContext(null)

export function useDialog () {
  return React.useContext(DialogContext);
}

const Dialog = React.forwardRef((props, ref) => {
  const {
    open,
    hide,
    width,
    maxWidth,
    minWidth,
    height,
    maxHeight,
    minHeight,
    placement,
    onClose,
    ...innerProps
  } = useComponentProps(props, 'Dialog', {
    static: ['hide'],
    variable: ['placement']
  });

  const innerRef = useRef(null);
  const snackBarRef = useRef(null);
  const [internalState, setInternalState] = useState({});

  const onCloseEvent = useEffectEvent(onClose);
  const handleClose = useCallback((e, reason) => {
    onCloseEvent?.(e, reason ?? 'closeButtonClick');
    setInternalState(utils.updater({open: false}, true));
  }, [onCloseEvent]);

  const dialog = useMemo(() => ({
    refs: {
      ref: innerRef,
      snackBarRef
    },
    state: {
      ...internalState,
      ...utils.cleanObject({open})
    },
    close: (e, reason) => {
      handleClose(e, reason);
    },
    showSnackbar: (...args) => {
      snackBarRef.current?.show(...args);
    },
    hideSnackbar: (...args) => {
      snackBarRef.current?.hide(...args);
    }
  }), [internalState, open, handleClose]);

  useImperativeHandle(ref, () => dialog);

  const handleKeyDown = (e) => {
    if (!e.defaultPrevented && dom.isSubmitFormEvent(e)) {
      e.preventDefault();
    }
  };

  const SnackbarProps = useMemo(() => ({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    }
  }), []);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <DialogContext.Provider value={dialog}>
    <StyledDialog ref={innerRef} {...innerProps}
                  onKeyDown={handleKeyDown}
                  maxWidth={utils.isNumber(maxWidth) ? 'sm' : maxWidth}
                  $width={width}
                  $minWidth={minWidth}
                  $maxWidth={maxWidth}
                  $height={height}
                  $minHeight={minHeight}
                  $maxHeight={maxHeight}
                  open={dialog.state.open}
                  onClose={handleClose}>
      <Wrapper reset>
        <SnackbarProvider ref={snackBarRef}
                          SnackbarProps={SnackbarProps}>
          {innerProps.children}
        </SnackbarProvider>
      </Wrapper>
    </StyledDialog>
  </DialogContext.Provider>
});

Dialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  open: PropTypes.bool,
  hide: PropTypes.bool,
  width: PropTypes.any,
  minWidth: PropTypes.any,
  maxWidth: PropTypes.any,
  height: PropTypes.any,
  minHeight: PropTypes.any,
  maxHeight: PropTypes.any,
  onClose: PropTypes.func
};

Dialog.defaultProps = {
  placement: 'center'
};

export default Dialog;
