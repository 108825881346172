import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledAnalyserCard = styled(Card)`
  position: relative;
  padding: ${props => props.theme.layout('1.5sp')} ${props => props.theme.layout('2sp')};
  background-color: unset;
  box-shadow: unset;
  border: 1px solid ${props => props.theme.property('palette.divider')};

  .AnalyserCard-content { 
    display: flex;
    gap: ${props => props.theme.layout('2sp')};
    position: relative;
    
    .AnalyserCard-logo {
      width: fit-content;
      .Icon {
        font-size: ${props => props.theme.fontSize(40)};
      }

      img {
        width: ${props => props.theme.layout(40)};
        height: ${props => props.theme.layout(40)};
      }
    }

    .AnalyserCard-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 1px;

      .AnalyserCard-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
      }
    }

    .AnalyserCard-details {
      display: flex;
      flex-direction: column;

      .AnalyserCard-detail {
        display: flex;
        overflow: hidden;

        &-label {
          min-width: ${props => props.theme.layout(88)};
          max-width: ${props => props.theme.layout(88)};
          color: ${props => props.theme.property('palette.text.secondary')};
        }
        
        &-value {
          flex-grow: 1;
          overflow: hidden;
          
          .ActionLink {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    
    .AnalyserCard-description {
      color: ${props => props.theme.property('palette.text.secondary')};
    }

    .AnalyserCard-icon {
      display: flex;
      align-items: center;
    }
  }
  
  ${ComponentStyles}
`;

StyledAnalyserCard.propTypes = {
  theme: PropTypes.object
};

StyledAnalyserCard.defaultProps = {}

export default StyledAnalyserCard;
