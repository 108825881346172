import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import StyledCollectionsActionbar
  from 'components/organisms/Snackbars/CollectionsActionbar/CollectionsActionbar.styles';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import useMediaQuery from '@mui/material/useMediaQuery';

const CollectionsActionbar = React.forwardRef((props, ref) => {
  const {
    tableProvider,
    listSelection,
    data,
    ...innerProps
  } = useComponentProps(props, 'CollectionsActionbar');

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const listState = useEffectItem(tableProvider.appliedListState());

  const collections = listSelection.dataSelection;
  const clearSelectionEvent = useEffectEvent(listSelection.clearSelection);
  const collectionDeleteEvent = useEffectEvent(tableProvider.updaters.deleteDataBulk);
  const collectionSelectedEvent = useEffectEvent(tableProvider.updaters.selectedItems);

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const actions = useMemo(() => {
    const actions = [];

    if (!mdDown) {
      actions.push({
        label: 'Remove Selection',
        icon: Close,
        ButtonProps: {
          variant: 'outlined',
          color: 'white'
        },
        onClick: () => {
          clearSelectionEvent?.();
        }
      });
    }

    if (listSelection.count <= listSelection.max) {
      actions.push({
        label: `Remove collection${listSelection.count > 1 ? 's' : ''}`,
        auth: utils.createAuth({attribute: 'collection.delete', meta: {collection: collections}}),
        icon: Delete,
        ButtonProps: {
          color: 'error'
        },
        onClick: (e) => {
          let selected;

          const handleConfirm = () => {
            if (!utils.isDefined(selected)) {
              return Promise.reject();
            }

            const collectionIds = selected?.map((e) => +e.collectionId);

            return collectionDeleteEvent?.({collectionIds})
              .then(() => {
                clearSelectionEvent?.();
              })
              .catch(() => {
                snackbar.show(`Removing collection${selected.length > 1 ? 's' : ''} failed`, null,
                  {color: 'error', autoHideDuration: constants.delay.error});
              });
          }

          const showDialog = () => {
            const question = `Are you sure you want to remove ${listSelection.count ?? 0} collection${listSelection.count > 1 ? 's' : ''}?`;
            const explanation = `This will remove the collection${listSelection.count > 1 ? 's' : ''} including all collection related data`

            dialogControl.show(<ConfirmDialog question={question}
                                              explanation={explanation}
                                              challenge={`Remove ${listSelection.count}`}
                                              challengeLabel="challenge"
                                              onConfirm={handleConfirm}
                                              ConfirmButtonProps={{
                                                children: `Remove collection${listSelection.count > 1 ? 's' : ''}`,
                                                color: 'error'
                                              }}/>, true);
          }

          showDialog();
          if (listSelection.count !== collections.length) {
            collectionSelectedEvent?.(listState, 0, listSelection.count, null, true, (collections) => {
              selected = collections;
            }, () => {
              dialogControl.hide();
              snackbar.show('Retrieving selection data failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
          } else {
            selected = collections;
          }

          e.preventDefault();
        }
      });
    }

    return actions;
  }, [listState, listSelection.count, listSelection.max, collections, collectionSelectedEvent,
    clearSelectionEvent, snackbar, dialogControl, collectionDeleteEvent, mdDown]);

  const renderTitle = () => {
    const count = utils.formatNumber(listSelection.count ?? 0);
    const title = `${count} collection${listSelection.count > 1 ? 's' : ''} selected`;
    if (actions?.length > 1) {
      return title;
    } else {
      const max = utils.formatNumber(listSelection.max ?? 0);
      return `${title} (max ${max})`
    }
  }

  return <StyledCollectionsActionbar ref={ref} {...innerProps}
                                     title={renderTitle()}
                                     actions={actions} />
});

CollectionsActionbar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tableProvider: PropTypes.any,
  listSelection: PropTypes.object,
  data: PropTypes.array
};

CollectionsActionbar.defaultProps = {};

export default CollectionsActionbar;
