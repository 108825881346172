import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import WizardContent from 'components/organisms/WizardContent/WizardContent/WizardContent';
import FormStyles from 'styles/mixins/Form.styles';

const StyledEntitiesAnalyseActionsWizardContent = styled(WizardContent)`
  &.EntitiesAnalyseActionsWizardContent {
    .Form-fields {
      display: flex;
      flex-wrap: wrap;
      column-gap: ${props => props.theme.layout('2sp')};
      
      &:has(.Form-group-settings) {
        .Form-group-actions {
          padding-bottom: ${props => props.theme.spacing(2)};
          border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
          margin-bottom: ${props => props.theme.layout('0.5sp')};
        }
      }
      
      .Form-group {
        &-actions {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          column-gap: ${props => props.theme.layout('2sp')};
          
          .FormControl {
            width: unset;
            min-width: ${props => props.theme.layout('40sp')};
            flex: 1 1 calc(50% - ${props => props.theme.layout('2sp')});
            &.FormField-name-otherActions {
              .FormLabel {
                visibility: hidden;
              }
              .FormHelperText {
                > * {
                  position: absolute;
                  bottom: -${props => props.theme.layout(18)}
                }
              }
            }
          }
        }
        &-matches {
          width: unset;
          min-width: ${props => props.theme.layout('32sp')};
          flex: 1 1 calc(50% - ${props => props.theme.layout('2sp')});
        }
        &-settings {
          width: unset;
          min-width: ${props => props.theme.layout('32sp')};
          flex: 1 1 calc(50% - ${props => props.theme.layout('2sp')});
          
          display: flex;
          flex-direction: column;
          gap: ${props => props.theme.layout('2sp')};
        }
      }
    }
  }
  
  ${FormStyles}
  ${ComponentStyles}
`;

StyledEntitiesAnalyseActionsWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseActionsWizardContent.defaultProps = {}

export default StyledEntitiesAnalyseActionsWizardContent;
