import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';
import FormStyles from 'styles/mixins/Form.styles';

const StyledEntitiesAnalyseDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(544)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(544)}, 86vw);
  }
  
  .EntitiesAnalyseDialog {
    &-content {
      &-type {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: ${props => props.theme.layout('1sp')};
        padding: ${props => props.theme.spacing(2)};

        .DataList {
          .DataList-list {
            .ListItem {
              > * {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  
  &.EntitiesAnalyseDialog-type {
    &-chat, &-match, &-tag, &-graph {
      .MuiDialog-paper {
        width: clamp(0px, ${props => props.theme.layout(800)}, 86vw);
        max-width: clamp(0px, ${props => props.theme.layout(800)}, 86vw);
        height: clamp(0px, ${props => props.theme.layout(800)}, 86vh);
        max-height: clamp(0px, ${props => props.theme.layout(800)}, 86vh);
      }
    }
  }
  
  ${FormStyles}
  ${ComponentStyles}
`;

StyledEntitiesAnalyseDialog.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseDialog.defaultProps = {}

export default StyledEntitiesAnalyseDialog;
