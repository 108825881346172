import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import utils from 'helpers/utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import {arrayMove} from '@dnd-kit/sortable';
import StyledStatusesForm from 'components/organisms/Forms/StatusesForm/StatusesForm.styles';
import StatusForm from 'components/organisms/Forms/StatusForm/StatusForm';

const StatusesForm = React.forwardRef((props, ref) => {
  const {
    statuses,
    required,
    readOnly,
    disabled,
    error,
    multiple,
    onChange,
    onError,
    ...innerProps
  } = useComponentProps(props, 'StatusesForm');

  const innerRef = useRef(null);
  const statusRefs = useRef({});

  const [internalState, setInternalState] = useState({
    statuses: []
  });

  const statusesForm = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    submit: (touch = true) => {
      return Promise.all(Object.keys(statusRefs.current).map((statusId) => {
        return statusRefs.current[statusId]?.ref?.submit?.(touch);
      }));
    },
    validate: (touch = true) => {
      return Promise.all(Object.keys(statusRefs.current).map((statusId) => {
        return statusRefs.current[statusId]?.ref?.validate?.(touch);
      }))
        .then((errors) => {
          return errors.reduce((o, f) => ({...o, ...utils.cleanObject(f)}), {});
        });
    },
    reset: (state) => {
      return Promise.all(Object.keys(statusRefs.current).map((statusId) => {
        return statusRefs.current[statusId]?.ref?.reset?.(state);
      }));
    }
  }), []);

  useImperativeHandle(ref, () => statusesForm);

  useLayoutEffect(() => {
    let maxPos = 0, minId = 0;

    const sts = (statuses ?? [])
      .map((s, idx) => {
        maxPos = Math.max(maxPos, idx);
        if (!utils.isDefined(s.statusId)) {
          minId = minId - 1;
        } else if (+s.statusId < 0) {
          minId = Math.min(minId, +s.statusId);
        }

        return {
          ...s,
          position: idx,
          statusId: !utils.isDefined(s.statusId) ? minId : +s.statusId
        }
      });

    if (!readOnly && sts.length === 0) {
      sts.push({statusId: minId - 1, position: maxPos + 1});
    }

    setInternalState(utils.updater({
      statuses: sts.sort((a, b) => +a.position - +b.position)
    }, true));
  }, [statuses, readOnly]);

  const handleAddStatus = () => {
    setInternalState((current) => {
      const maxPos = current.statuses.reduce((p, s) => Math.max(p, +s.position), 0);
      const minId = current.statuses.reduce((i, s) => +s.statusId < 0 ? Math.min(i, +s.statusId) : i, 0);

      return {
        ...current,
        statuses: current.statuses.concat([{statusId: minId - 1, position: maxPos + 1}])
      }
    });
  }

  const doErrors = () => {
    const errorIds = Object.keys(statusRefs.current).reduce((o, k) => {
      return statusRefs.current[k].ref && statusRefs.current[k].error ? {...o, [k]: true} : o;
    }, {});

    const error = !utils.isEmpty(errorIds);

    onError?.(error);
    setInternalState(utils.updater({ error, errorIds }, true));
  }

  const handleDragDrop = (id, containerId, position) => {
    let newValue = utils.clone(internalState.statuses, true);
    const idx = internalState.statuses.findIndex((s) => +s.statusId === +id);

    if (idx !== position) {
      newValue = arrayMove(internalState.statuses, idx, position);
      newValue.forEach((s, idx) => {
        s.position = idx;
      });

      setInternalState(utils.updater({statuses: newValue}, true));
      onChange?.(newValue);
    }
  }

  const handleChange = (status) => (values) => {
    const idx = internalState.statuses.findIndex((s) => +s.statusId === +status.statusId);
    
    if (idx !== -1) {
      const newValue = [
        ...internalState.statuses.slice(0, idx),
        {...status, ...values, label: values.name},
        ...internalState.statuses.slice(idx + 1),
      ];

      setInternalState(utils.updater({statuses: newValue}, true));
      onChange?.(newValue);
    }
  }

  const handleDelete = (status) => () => {
    const reset = statusRefs.current[status.statusId]?.ref?.reset;
    delete statusRefs.current[status.statusId];
    utils.retry(() => {
      reset?.({touched: {}});
    }, 3);

    const newValue = internalState.statuses
      .filter((s) => +s.statusId !== +status.statusId);
    setInternalState(utils.updater({statuses: newValue}, true));
    onChange?.(newValue);
    doErrors();
  }

  const handleRef = (status) => (ref) => {
    statusRefs.current[status.statusId] = {
      ...statusRefs.current[status.statusId],
      ref
    };
  }

  const handleError = (status) => (error) => {
    statusRefs.current[status.statusId] = {
      ...statusRefs.current[status.statusId],
      error
    };

    doErrors();
  }

  const renderStatus = (status, only) => {
    const hasError = internalState.statuses.filter((s) => !internalState.errorIds?.[s.statusId])
      .find((s) => !internalState.errorIds?.[status.statusId] &&
        +s.statusId !== +status.statusId && s.name?.toLowerCase() === status.name?.toLowerCase());

    return <StatusForm ref={handleRef(status)}
                       key={status.statusId}
                       className="StatusesForm-status"
                       status={status}
                       only={only}
                       error={error}
                       errorDup={Boolean(hasError)}
                       required={required || !only}
                       readOnly={readOnly}
                       disabled={disabled}
                       removable={internalState.statuses.length > 1}
                       hideActions={!multiple}
                       onChange={handleChange(status)}
                       onDelete={handleDelete(status)}
                       onError={handleError(status)}/>
  }

  return <StyledStatusesForm ref={innerRef} {...innerProps}>
    <DndContext>
      <List sortable={!(readOnly || disabled)}
            dragHandle={true}
            gap={8} catchFocus={false}
            onDragDrop={handleDragDrop}>
        {internalState.statuses?.map((status) => {
          return <ListItem key={status.statusId} data-key={status.statusId} density="densest">
            {renderStatus(status, internalState.statuses.length === 1)}
          </ListItem>
        })}
      </List>
      {(multiple && !(readOnly || disabled)) ?
        <Button variant="text"
                disabled={Boolean(error || internalState.error || internalState.statuses.find((st) => utils.isEmpty(st.name)))}
                startIcon={<Icon icon={Add} />}
                onClick={handleAddStatus}>
          Add status
        </Button> : null}
    </DndContext>
  </StyledStatusesForm>
});

StatusesForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  statuses: PropTypes.array,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func
};

StatusesForm.defaultProps = {
  multiple: true
};

export default StatusesForm;
