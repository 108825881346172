import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import List from '@mui/material/List';
import utils from 'helpers/utils';

const StyledList = styled(List)`
  outline: none;

  &.List-orientation {
    &-vertical {
      width: 100%;
      
      &.List {
        > .ListItem {
          height: unset;
        }
        
        ${props => utils.isDefined(props.$gap) ? css`
          ${(Boolean(props.$divider) || (Boolean(props.$virtualize) && !Boolean(props.$sortable))) ? css`
            > .ListItem:not(:last-child) {
              padding-bottom: ${props => props.theme.layout(props.$gap / 2)};
            }
        
            > .ListItem:not(:first-child) {
              padding-top: ${props => props.theme.layout(props.$gap / 2)};
            }
          `: (Boolean(props.$virtualize) && Boolean(props.$sortable)) ? css`
              > .ListItem {
                padding-bottom: ${props => props.theme.layout(props.$gap)};
              }
          ` : css`
            display: flex;
            flex-direction: column;
            row-gap: ${props => props.theme.layout(props.$gap)};
          `}
         ` : null}

        ${props => Boolean(props.$divider) ? css`
          > .ListItem:not(:last-child):has(*) {
            border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
          }
        ` : null}
      }
    }
    &-horizontal {
      height: 100%;
      
      &.List {
        > .ListItem {
          width: unset;
        }
        
        ${props => utils.isDefined(props.$gap) ? css`
          ${(Boolean(props.$divider) || (Boolean(props.$virtualize) && !Boolean(props.$sortable))) ? css`
            > .ListItem:not(:last-child) {
              padding-right: ${props => props.theme.layout(props.$gap / 2)};
            }
        
            > .ListItem:not(:first-child) {
              padding-left: ${props => props.theme.layout(props.$gap / 2)};
            }
          `: (Boolean(props.$virtualize) && Boolean(props.$sortable)) ? css`
              > .ListItem {
                padding-right: ${props => props.theme.layout(props.$gap)};
              }
          ` : css`
            display: flex;
            flex-direction: row;
            column-gap: ${props => props.theme.layout(props.$gap)};
          `}
        ` : null}
        
        ${props => Boolean(props.$divider) ? css`
          > .ListItem:not(:last-child):has(*) {
            border-right: 1px solid ${props => props.theme.property('palette.divider')};
          }
        ` : null}
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledList.propTypes = {
  theme: PropTypes.object
};

StyledList.defaultProps = {
}

export default StyledList;
