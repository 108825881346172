import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import IconButton from '@mui/material/IconButton';
import constants from 'helpers/constants';

const StyledIconButton = styled(IconButton)`
  &.IconButton-color-default {
    color: ${props => props.theme.property('palette.text.primary')};
  }

  ${props => props.theme.state2Selector('IconButton', null, 'selected')} {
    background-color: ${props => props.theme.state2Color(props.color, 'selected', constants.colorTypes.accentBackground, constants.colorTargets.background)}; 
  }
  ${props => props.theme.state2Selector('IconButton', null, 'disabled')} {
    pointer-events: none;
    color: ${props => props.theme.state2Color(props.color, 'disabled', constants.colorTypes.button, constants.colorTargets.foreground)};
  }
  
  .IconButton {
    &-badge {
      .MuiBadge-badge {
        right: ${props => props.theme.layout(-4)};
        top: ${props => props.theme.layout(-3)};
      }
      .MuiBadge-inline {
        &.MuiBadge-badge {
          position: relative;
          align-self: center;
          right: 0;
          top: 0;
          padding: ${props => props.theme.layout(5)};
          margin-left: ${props => props.theme.layout('0.5sp')};
        }
      }
    }
  }
  
  // sizes
  &.IconButton-size-smallest {
    font-size: ${props => props.theme.fontSize(14)};
  }
  &.IconButton-size-smaller {
    font-size: ${props => props.theme.fontSize(16)};
  }
  &.IconButton-size-small {
    font-size: ${props => props.theme.fontSize(18)};
  }
  &.IconButton-size-medium {
    font-size: ${props => props.theme.fontSize(24)};
  }
  &.IconButton-size-large {
    font-size: ${props => props.theme.fontSize(28)};
  }
  &.IconButton-size-larger {
    font-size: ${props => props.theme.fontSize(32)};
  }

  // density
  &.IconButton-density-sparser {
    padding: ${props => props.theme.spacing(1.5)};
    &.IconButton-outlined {
      padding: calc(${props => props.theme.spacing(1.5)} - 1px);
    }
  }
  &.IconButton-density-sparse {
    padding: ${props => props.theme.spacing(1.25)};
    &.IconButton-outlined {
      padding: calc(${props => props.theme.spacing(1.25)} - 1px);
    }
  }
  &.IconButton-density-normal {
    padding: ${props => props.theme.spacing(1)};
    &.IconButton-outlined {
      padding: calc(${props => props.theme.spacing(1)} - 1px);
    }
  }
  &.IconButton-density-dense {
    padding: ${props => props.theme.spacing(0.75)};
    &.IconButton-outlined {
      padding: calc(${props => props.theme.spacing(0.75)} - 1px);
    }
    &.IconButton-contained, &.IconButton-transparent, &.IconButton-outlined {
      border-radius: ${props => props.theme.radius(1.5)};
    }
  }
  &.IconButton-density-denser {
    padding: ${props => props.theme.spacing(0.5)};
    &.IconButton-outlined {
      padding: calc(${props => props.theme.spacing(0.5)} - 1px);
    }
    &.IconButton-contained, &.IconButton-transparent, &.IconButton-outlined {
      border-radius: ${props => props.theme.radius(1)};
    }
  }
  &.IconButton-density-densest {
    padding: ${props => props.theme.spacing(0.25)};
    &.IconButton-outlined {
      padding: calc(${props => props.theme.spacing(0.25)} - 1px);
    }
    &.IconButton-contained, &.IconButton-transparent, &.IconButton-outlined {
      border-radius: ${props => props.theme.radius(0.5)};
    }
  }

  // variants
  &.IconButton-outlined {
    border-radius: ${props => props.theme.radius(2)};
    
    ${props => props.theme.color2Css('IconButton-outlined', null, props.color, {
      interactive: props.$clickable,
      type: constants.colorTypes.outlined,
    })}

    ${props => props.theme.state2Selector('IconButton-outlined', null, 'default')} {
      background-color: transparent;
    }

    &.IconButton-outlined {
      &.IconButton-plainBorder {
        border: 1px solid ${props => props.theme.property('palette.divider')} !important;
      }
    }
    
    &.IconButton-flatBackground {
      ${props => props.theme.state2Selector('IconButton-outlined', null, 'default')} {
        background-color: ${props => props.theme.property(`palette.${props.color}.contrastText`, props.theme.property(`palette.secondary.contrastText`))};
      }
    }
    
    &.IconButton-grouped:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -${props => props.theme.layout(1)};
    }
    
    &.IconButton-grouped:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-color: transparent;
    }
  }
  
  &.IconButton-contained {
    border-radius: ${props => props.theme.radius(2)};
    
    ${props => props.theme.color2Css('IconButton-contained', null, props.color, {
      interactive: props.$clickable,
      type: constants.colorTypes.button,
    })}
    
    ${props => props.theme.state2Selector('IconButton-contained', null, 'disabled')}{
      color: ${props => props.theme.property('palette.action.disabled')} !important;
      background-color: ${props => props.theme.state2Color(props.color, 'default', constants.colorTypes.button, constants.colorTargets.background)};
      opacity: 0.6;
    }
  }
  
  &.IconButton-transparent {
    border-radius: ${props => props.theme.radius(2)};
    
    ${props => props.theme.color2Css('IconButton-transparent', null, props.color, {
      interactive: props.$clickable,
      type: constants.colorTypes.accentBackground,
    })}

    ${props => props.theme.state2Selector('IconButton-outlined', null, 'default')} {
      background-color: transparent;
    }

    ${props => props.theme.state2Selector('IconButton-transparent', null, 'disabled')} {
      opacity: 1;
      background-color: unset !important;
    }
  }

  &.IconButton-menu {
    ${props => props.theme.color2Css('IconButton', null, props.color, {
      interactive: props.$clickable,
      type: constants.colorTypes.accentText,
    })}
    
    // basic color is a little different from the default
    ${props => props.theme.state2Selector('IconButton', null, 'default')} {
      color: ${props => props.theme.property('palette.blueGrey.600')};
    }
    
    .Icon {
      font-size: inherit;
    }
  }

  &:not(.IconButton-clickable) {
    cursor: default;

    .MuiTouchRipple-root {
      display: none;
    }
  }
  
  // radius
  &.IconButton-radius-round {
    border-radius: 50% !important;

    &.IconButton-contained, &.IconButton-transparent, &.IconButton-outlined {
      border-radius: ${props => props.theme.radius(2)} !important;
    }
  }

  &.IconButton-radius-square {
    border-radius: ${props => props.theme.radius(1)};
  }

  &.IconButton-radius-circle {
    border-radius: 50% !important;
  }
  
  ${ComponentStyles}
`;

StyledIconButton.propTypes = {
  theme: PropTypes.object
};

StyledIconButton.defaultProps = {
}

export default StyledIconButton;
