import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledEntitiesQueryBar from 'components/organisms/Bars/EntitiesQueryBar/EntitiesQueryBar.styles';

const EntitiesQueryBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntitiesQueryBar');

  const tableProvider = useTable();
  const fieldData = tableProvider.fieldData;
  const filterGroupDefinitions = tableProvider.filterGroupDefinitions;

  const handleChange = (query, search, wizard) => {
    tableProvider.listState.setSearch(search);
    if (wizard) {
      tableProvider.listState.setFilter([{id: 'wizard', value: wizard}]);
    } else {
      tableProvider.listState.setFilter([]);
    }
    tableProvider.listState.setQuery(query);
  }

  const wizard = useMemo(() => {
    const filter = (tableProvider.listState.filter ?? []).find((f) => f.id === 'wizard');
    return filter ? (utils.toArray(filter.value, true)?.[0] ?? '') : '';
  }, [tableProvider.listState.filter]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesQueryBar ref={ref} {...innerProps}
                                 search={tableProvider.listState.search}
                                 wizard={wizard}
                                 initialised={tableProvider.listState.initialised}
                                 query={tableProvider.listState.query}
                                 filterGroups={filterGroupDefinitions}
                                 fieldData={fieldData}
                                 onChange={handleChange} />
});

EntitiesQueryBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntitiesQueryBar.defaultProps = {
};

export default EntitiesQueryBar;
