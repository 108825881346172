import React, {useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCard from 'components/atoms/Cards/Card/Card.styles';
import utils from 'helpers/utils';
import ButtonBase from 'components/atoms/Buttons/ButtonBase/ButtonBase';
import dom from 'helpers/dom';
import {withMemo} from 'helpers/wrapper';

const Card = withMemo(React.forwardRef((props, ref) => {
  const {
    onClick,
    disabled,
    fullWidth,
    fullHeight,
    disableRipple,
    selected,
    activateSelected,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Card', {
    children: ['button'],
    static: ['fullWidth', 'fullHeight', 'disabled', 'clickable', 'selected', 'focused', 'focusable', 'isLoading'],
    styled: ['color']
  });

  const initialised = useRef(false);
  const canActivate = useRef(!selected);
  const innerRef = useRef(null);
  const buttonRef = useRef(null);

  const [internalState, setInternalState] = useState({
    focusActive: false
  });

  const clickable = Boolean(onClick) && !disabled;

  useImperativeHandle(ref, () => innerRef.current);

  const handleMouseDown = (e) => {
    if (!e.defaultPrevented && dom.isPartOfClickable(e.target, innerRef.current) && clickable) {
      e.stopPropagation();
    }
  }

  const handleKeyDown = (e) => {
    if (!e.defaultPrevented && e.target === innerRef.current && clickable) {
      if (e.code === 'Space' || (e.code === 'Enter' && !dom.isNativeButton(e.target))) {
        e.target?.click();
        e.preventDefault();
      }
    }
  }

  const handleFocus = (e) => {
    innerProps.onFocus?.(e);
    setInternalState(utils.updater({focusActive: true}, true));
  }

  const handleBlur = (e) => {
    innerProps.onBlur?.(e);
    setInternalState(utils.updater({focusActive: false}, true));
  }

  const handleClick = (e) => {
    onClick?.(e);
    canActivate.current = false;
  }

  const handleRef = (element) => {
    innerRef.current = element;
    if (element && buttonRef.current) {
      buttonRef.current.style.borderRadius = dom.getComputedStyle(element).borderRadius;
    }
  }

  useLayoutEffect(() => {
    if (initialised.current) {
      if (canActivate.current && selected && activateSelected) {
        innerRef.current?.click();
      }
    } else if (activateSelected) {
      initialised.current = true;
    }
    canActivate.current = !selected;
  }, [selected, activateSelected]);

  innerProps.tabIndex = innerProps.tabIndex ?? (Boolean(onClick) ? 0 : -1);
  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: clickable,
    focused: internalState.focusActive
  });

  const renderCard = () => {
    return <StyledCard ref={handleRef} {...innerProps}
                       onFocus={handleFocus}
                       onBlur={handleBlur}
                       onMouseDown={handleMouseDown}
                       onKeyDown={handleKeyDown}
                       onClick={handleClick}>
      {innerProps.children}
    </StyledCard>
  }

  if (!isLoading && clickable && !disableRipple) {
    return <ButtonBase ref={buttonRef}
                       component="div"
                       className="Card-button"
                       focusable={false}
                       focusRipple={false}
                       fullWidth={fullWidth}
                       fullHeight={fullHeight}
                       style={{borderRadius: innerProps.theme.radius(1)}}
                       color={innerProps.$color}>
      {renderCard()}
    </ButtonBase>;
  } else {
    return renderCard();
  }
}));

Card.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  disabled: PropTypes.bool,
  disableRipple: PropTypes.bool
};

Card.defaultProps = {
  children: 'Card text',
  focusable: true,
  color: 'primary'
};

export default Card;
