import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledCategoryCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  row-gap: ${props => props.theme.layout('1sp')};

  padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(1)};
  
  .CategoryCard {
    &-heading {
      width: 100%;
      min-height: ${props => props.theme.layout('3sp')};
      max-height: ${props => props.theme.layout('3sp')};
      display: flex;
      justify-content: space-between;
      
      &:not(:has(.CategoryCard-chip)) {
        justify-content: flex-end;
      }

      .CategoryCard-selected {
        margin-top: ${props => props.theme.layout(2)};
        margin-right: ${props => props.theme.layout(2)};
      }
    }
    
    &-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: ${props => props.theme.layout('1sp')};
      
      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: ${props => props.theme.layout('1sp')};
      }
    }

    &-title {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      text-align: center;
      max-width: 100%;
    }
    
    &-subtitle {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      text-align: center;
    }
    
    &-icon {
      display: flex;
      flex-wrap: nowrap;
      gap: 0;
      
      color: ${props => props.theme.property(`palette.${props.color}.main`)};
      
      svg {
        font-size: ${props => props.theme.fontSize(40)};
      }
    }
  }
  
  // variants
  &.CategoryCard-inline {
    padding: ${props => props.theme.spacing(1.5)};

    &.CategoryCard-selected {
      .CategoryCard {
        &-body-text {
          margin-right: ${props => props.theme.layout(8)};
        }
      }
    }
    
    .CategoryCard {
      &-heading {
        position: absolute;
        top: ${props => props.theme.layout('0.5sp')};
        right: ${props => props.theme.layout('0.5sp')};
        width: calc(100% - ${props => props.theme.layout('1sp')});
      }
      
      &-body {
        flex-direction: row;
        column-gap: ${props => props.theme.layout('1.5sp')};
        align-items: center;
        
        &-text {
          flex-grow: 1;
          width: 1px;
          align-items: flex-start;
          row-gap: 0;
        }
      }
      
      &-title, &-subtitle, &-credits {
        text-align: left;

        > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-subtitle {
        text-align: left;
      }

      &-credits {
        margin-top: ${props => props.theme.layout(-2)};
        .Badge {
          margin-top: ${props => props.theme.layout(-1)};
          margin-right: ${props => props.theme.layout(3)};
        }
      }

      &-icon {
        display: flex;
        align-items: center;
        
        svg {
          font-size: ${props => props.theme.fontSize(24)};
        }
      }
    }
  }
  
  // sizes
  &.CategoryCard-size-smaller {
    row-gap: ${props => props.theme.layout('0.5sp')};
    
    .CategoryCard {
      &-body {
        row-gap: ${props => props.theme.layout('0.5sp')};
      }
      
      &-icon {
        svg {
          font-size: ${props => props.theme.fontSize(32)};
        }
      }

      &-title {
        > * {
          font-size: ${props => props.theme.fontSize(14)};
        }
      }
      &-subtitle {
        > * {
          font-size: ${props => props.theme.fontSize(10)};
        }
      }
    }
  }

  &.CategoryCard-iconDensity-dense {
    .CategoryCard-icon {
      svg:not(:first-child) {
        margin-left: ${props => props.theme.layout(-10)};
      }
    }
  }
  
  &.CategoryCard-iconDensity-denser {
    .CategoryCard-icon {
      svg:not(:first-child) {
        margin-left: ${props => props.theme.layout('-1.5sp')};
      }
    }
  }

  &.CategoryCard-standard {
    &.CategoryCard-density-dense {
      row-gap: ${props => props.theme.layout('0sp')};

      .CategoryCard {
        &-body {
          row-gap: ${props => props.theme.layout('1sp')};

          &-text {
            row-gap: ${props => props.theme.layout('1sp')};
          }
        }
      }

      &.CategoryCard-size-smaller {
        .CategoryCard {
          &-body {
            row-gap: ${props => props.theme.layout('0.5sp')};

            &-text {
              row-gap: ${props => props.theme.layout('0.5sp')};
            }
          }
        }
      }
    }

    &.CategoryCard-density-sparse {
      row-gap: ${props => props.theme.layout('2sp')};

      .CategoryCard {
        &-body {
          row-gap: ${props => props.theme.layout('1sp')};

          &-text {
            row-gap: ${props => props.theme.layout('1sp')};
          }
        }
      }

      &.CategoryCard-size-smaller {
        .CategoryCard {
          &-body {
            row-gap: ${props => props.theme.layout('0.5sp')};

            &-text {
              row-gap: ${props => props.theme.layout('0.5sp')};
            }
          }
        }
      }
    }

    &.CategoryCard-density-sparser {
      row-gap: ${props => props.theme.layout('2sp')};

      .CategoryCard {
        &-body {
          row-gap: ${props => props.theme.layout('2sp')};

          &-text {
            row-gap: ${props => props.theme.layout('2sp')};
          }
        }
      }

      &.CategoryCard-size-smaller {
        .CategoryCard {
          &-body {
            row-gap: ${props => props.theme.layout('1sp')};

            &-text {
              row-gap: ${props => props.theme.layout('1sp')};
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCategoryCard.propTypes = {
  theme: PropTypes.object
};

StyledCategoryCard.defaultProps = {
}

export default StyledCategoryCard;
