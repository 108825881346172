import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';
import Typography from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import ActionAvatar from 'components/molecules/Avatars/ActionAvatar/ActionAvatar';
import StyledUserField from 'components/molecules/Fields/UserField/UserField.styles';
import AvatarOption from 'components/molecules/Options/AvatarOption/AvatarOption';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';

const UserField = withMemo(React.forwardRef((props, ref) => {
  const {
    size,
    ...innerProps
  } = useComponentProps(props, 'UserField');

  const getTagProps = (option) => {
    const avatar = `${utils.personName(option.firstName, option.lastName)} (${option.username})`;
    const color = utils.string2Color(avatar);
    return {
      icon: <Avatar color={color} size="tiny">{utils.avatarLabel(avatar)}</Avatar>
    };
  }

  const renderOption = (option, props) => {
    const avatar = `${utils.personName(option.profile.firstName, option.profile.lastName)} (${option.profile.username})`;
    const name = utils.personName(option.profile.firstName, option.profile.lastName);

    return <AvatarOption className="UserField-option"
                         avatar={avatar} label={name}
                         size={size}
                         ActionAvatarProps={{disabled: props?.disabled}} />
  }

  const renderReadOnlyOption = (option, props) => {
    const avatar = `${utils.personName(option.profile.firstName, option.profile.lastName)} (${option.profile.username})`;
    const name = utils.personName(option.profile.firstName, option.profile.lastName);

    const avatarLabel = utils.avatarLabel(avatar);
    const color = utils.string2Color(avatar);

    return <Box className="UserField-readOnlyOption">
      <ActionAvatar size="smaller"
                    action={{
                      label: avatarLabel,
                      tooltip: avatar
                    }}
                    color={color}
                    disabled={props?.disabled}
                    TooltipProps={{enterDelay: 0}}/>
      <Typography variant="body2">{name}</Typography>
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledUserField ref={ref} {...innerProps}
                          getTagProps={getTagProps}
                          renderOption={renderOption}
                          renderReadOnlyOption={renderReadOnlyOption}/>
}));

UserField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  size: PropTypes.string,
  showLinkIcon: PropTypes.bool
};

UserField.defaultProps = {
  size: 'small'
};

export default UserField;
