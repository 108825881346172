import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUploadFiles} from 'helpers/hooks/utils';
import StyledEntitiesUploadWizardContent
  from 'components/organisms/WizardContent/EntitiesUploadWizardContent/EntitiesUploadWizardContent.styles';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import {useSourcePreProcess, useSourceUpload} from 'services/source/source.hooks';
import Box from 'components/atoms/Layout/Box/Box';
import {H6} from 'components/atoms/Text/Typography/Typography';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import sample from 'assets/samples/CatalistUploadTemplate.xlsx';
import Link from 'components/atoms/Links/Link/Link';

const EntitiesUploadWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    isSource,
    file,
    onComplete,
    onError,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'EntitiesUploadWizardContent');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const data = wizard.data[wizard.dataKey];
  const state = wizard.getStepState();
  const collectionId = data.params.collectionId;

  const snackbar = useSnackbar();
  const uploadSource = useSourceUpload();
  const preProcessFileSource = useSourcePreProcess();
  const [uploadState, setUploadState, uploadFiles] = useUploadFiles(state.uploadState);

  const uploadFilesEvent = useEffectEvent(uploadFiles);
  const onErrorEvent = useEffectEvent(onError);
  const setDataEvent = useEffectEvent(wizard.setData);
  const setStateEvent = useEffectEvent(wizard.setStepState);

  const uploadWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
      formRef: formRef
    },
    submit: () => {
      return formRef.current?.submit()
        .catch(() => {
          onErrorEvent?.('Please check if all fields have the correct values');
        });
    }
  }), [onErrorEvent]);

  useImperativeHandle(ref, () => uploadWizardContent);

  const FileFieldProps = useMemo(() => {
    const fileState = uploadState ?
      Object.keys(uploadState).reduce((o, k) => {
        const split = k.split('_');
        o[split[1]] = uploadState[k];
        return o;
      }, {}) : null;

    return {
      FormFieldProps: {
        status: fileState,
        minSize: 1,
        maxSize: constants.numbers.GB
      }
    }
  }, [uploadState]);

  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'file',
      label: 'File',
      entity: !isSource ? 'entitiesUpload' : 'source',
      type: constants.formFieldTypes.file,
      validation: constants.formFieldValidationTypes.file,
      conversion: constants.formFieldConversionTypes.none,
      initial: data.file,
      validate: (value, testContext) => {
        if (state.fileError) {
          return testContext.createError({message: state.fileError});
        } else {
          return true;
        }
      },
      required: true,
      ...utils.mergeObjects({
        FormFieldProps: {
          hiddenLabel: true,
          multiple: false,
          addDescription: false,
          fileDescription: 'Excel, Csv, Json or Pdf document',
          types: ['xls', 'xlsx', 'csv', 'json', 'pdf']
        }
      }, FileFieldProps)
    });

    if (isSource) {
      fields.push({
          name: 'name',
          label: 'Name',
          entity: 'source',
          type: constants.formFieldTypes.text,
          initial: data.name ?? null,
          required: true,
          FormFieldProps: {
            fullWidth: false,
          }
        },
        {
          name: 'comment',
          label: 'Description',
          inlineLabel: 'description',
          placeholder: 'Describe this source',
          type: constants.formFieldTypes.textarea,
          validation: constants.formFieldValidationTypes.text,
          conversion: constants.formFieldConversionTypes.value,
          entity: 'source',
          initial: '',
          FormFieldProps: {
            fullWidth: false,
            minRows: 2,
          }
        });
    }

    fields.push({
      name: 'existingEntities',
      label: 'What do you want to do with companies that already appear in the database?',
      entity: !isSource ? 'entitiesUpload' : 'source',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.list,
      conversion: constants.formFieldConversionTypes.value,
      initial: [
        data.params.fileEnrichProfiles ? 'fileEnrichProfiles' : null,
        data.params.fileOverwriteValues ? 'fileOverwriteValues' : null
      ].filter((_) => (_)),
      options: [
        {value: 'fileEnrichProfiles', label: 'Only enrich existing companies'},
        {value: 'fileOverwriteValues', label: 'Overwrite existing company data'}
      ],
      FormFieldProps: {
        multiple: true,
        ListProps: {
          catchFocus: false
        }
      }
    });

    return utils.initializeFormFields(fields, {...data, ...state});
  }, [FileFieldProps, data, state, isSource]);

  const doChange = (field, value) => {
    onDirty?.(true);

    if (field) {
      if (field.name === 'file') {
        wizard.resetNextSteps?.();
        setUploadState({});
        setStateEvent?.({fileError: null});

        const extraProps = !isSource ? {name: value?.name} : {};

        setDataEvent?.((current) => ({
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            file: value,
            fileId: null,
            fields: null,
            params: {
              ...current[wizard.dataKey].params,
              fileName: value?.name,
              config: null
            },
            ...extraProps,

            // try setting setting name
            name: (utils.isEmpty(current[wizard.dataKey]?.name) || (utils.filename2Name(current[wizard.dataKey]?.file?.name) === current[wizard.dataKey]?.name)) ? (
              value ? utils.filename2Name(value?.name) : ''
            ) : current[wizard.dataKey]?.name
          }
        }));
      } else if (field.name === 'existingEntities') {
        setDataEvent?.((current) => ({
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            params: {
              ...current[wizard.dataKey].params,
              fileEnrichProfiles: Boolean(utils.toArray(value).find((v) => v.value === 'fileEnrichProfiles')),
              fileOverwriteValues: Boolean(utils.toArray(value).find((v) => v.value === 'fileOverwriteValues'))
            }
          }
        }));
      } else {
        setDataEvent?.((current) => ({
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            [field.name]: value
          }
        }));
      }
    }
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    if (hasErrors) {
      onError?.('Please check if all fields have the correct values');
    } else {
      onError?.(null);
    }
  }

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    doChange(field, value);
  };

  const handleSubmit = (values, actions) => {
    onDirty?.(true, true); // set submitting, on complete or error resets

    if (!utils.isDefined(data.fileId)) {
      uploadSource.mutation.mutateAsync({
        filename: values.file?.name,
        filesize: values.file?.size,
        filetype: values.file?.type || constants.filetypes.default,
        tempFile: true
      })
        .then((res) => {
          const upload = utils.camelcase(res.response.data?.data);
          const name = values.file?.name;

          return uploadFilesEvent?.([{
            file: values.file,
            id: 'file_0',
            url: upload?.uploadUrl,
            name
          }], true)
            .then(() => {
              return preProcessFileSource.mutation.mutateAsync({
                collectionId,
                fileId: upload?.key
              })
                .then((res) => {
                  setDataEvent?.((current) => ({
                    ...current,
                    [wizard.dataKey]: {
                      ...current[wizard.dataKey],
                      fileId: upload?.key,
                      fields: utils.camelcase(res.response.data.data.fields),
                      params: {
                        ...current[wizard.dataKey].params,
                        config: utils.camelcaseEx(res.response.data.data.mapping, true)
                      }
                    }
                  }));
                  onComplete?.(Boolean(step?.resetNextSteps));
                });
            });
        })
        .catch(() => {
          setStateEvent?.({fileError: 'Please provide a valid file'});
          actions.setFieldError('file', 'Please provide a valid file');
          setUploadState((current) => ({
            ...current,
            file_0: {
              ...current?.file_0,
              success: false,
              error: true,
              loading: false
            }
          }));
          onErrorEvent?.('Upload failed.');
          snackbar.show('Invalid file, upload failed', null, {color: 'error', autoHideDuration: constants.delay.error});
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      actions.setSubmitting(false);
      onComplete?.(Boolean(step?.resetNextSteps));
    }
  };

  useEffect(() => {
    setStateEvent?.({uploadState})
  }, [setStateEvent, uploadState]);

  const doChangeEvent = useEffectEvent(doChange);
  useEffect(() => {
    if (file && !data.file) {
      doChangeEvent?.({name: 'file'}, file)
    }
  }, [file, data.file, doChangeEvent]);

  const renderSubtitle = () => {
    return <Box className="EntitiesUploadWizardContent-subtitle">
      <H6>Do you have doubts about the structure of your file? Then <Link href="#" onClick={() => utils.fileDownload('sample.xlsx', sample)}>download</Link> our sample file</H6>
    </Box>
  };

  return <StyledEntitiesUploadWizardContent ref={ref} {...innerProps}>
    {renderSubtitle()}
    <InlineForm ref={formRef}
                fields={fields}
                onValidating={handleValidating}
                onChangeDirect={handleChangeDirect}
                onSubmit={handleSubmit}/>
  </StyledEntitiesUploadWizardContent>
});

EntitiesUploadWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  onDirty: PropTypes.func,
};

EntitiesUploadWizardContent.defaultProps = {};

export default EntitiesUploadWizardContent;
