import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledScoreLinearProgress = styled(Box)`
  &.ScoreLinearProgress {
    position: relative;
    height: ${props => props.theme.layout(20)};
    .LinearProgress {
      background-color: ${props => props.theme.property('palette.divider')};
      height: 100%;
      border-radius: ${props => props.theme.radius(2.5)};
      
      .MuiLinearProgress-bar {
        border-radius: ${props => props.theme.radius(2.5)};
      }
    }
    .Typography {
      position: absolute;
      top: 0;
      right: calc(${props => `${100 - props.$score}%`} + ${props => props.theme.layout(6)});
    }
  }

  &.ScoreLinearProgress-size-medium {
    height: ${props => props.theme.layout(16)};
    .LinearProgress {
      height: 100%;
    }
    .Typography {
      font-size: ${props => props.theme.fontSize(10)};
    }
  }
  &.ScoreLinearProgress-size-smallish {
    height: ${props => props.theme.layout(12)};
    .LinearProgress {
      height: 100%;
    }
    .Typography {
      font-size: ${props => props.theme.fontSize(8)};
    }
  }
  &.ScoreLinearProgress-size-small {
    height: ${props => props.theme.layout(8)};
    .LinearProgress {
      height: 100%;
    }
  }
  &.ScoreLinearProgress-size-smaller {
    height: ${props => props.theme.layout(6)};
    .LinearProgress {
      height: 100%;
    }
  }
  
  ${ComponentStyles}
`;

StyledScoreLinearProgress.propTypes = {
  theme: PropTypes.object
};

StyledScoreLinearProgress.defaultProps = {}

export default StyledScoreLinearProgress;
