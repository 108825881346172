import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import utils from 'helpers/utils';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import constants from 'helpers/constants';
import ListMappingForm from 'components/organisms/Forms/ListMappingForm/ListMappingForm';
import StyledListMappingsForm from 'components/organisms/Forms/ListMappingsForm/ListMappingsForm.styles';

const ListMappingsForm = React.forwardRef((props, ref) => {
  const {
    lists,
    team,
    mappedFields,
    connection,
    fieldData,
    required,
    readOnly,
    disabled,
    error,
    errorFrom,
    errorTo,
    multiple,
    onChange,
    onError,
    ...innerProps
  } = useComponentProps(props, 'ListMappingsForm');

  const innerRef = useRef(null);
  const listRefs = useRef({});

  const [internalState, setInternalState] = useState({
    lists: []
  });

  const listMappingsForm = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    submit: (touch = true) => {
      return Promise.all(Object.keys(listRefs.current).map((listId) => {
        return listRefs.current[listId]?.ref?.submit?.(touch);
      }));
    },
    validate: (touch = true) => {
      return Promise.all(Object.keys(listRefs.current).map((listId) => {
        return listRefs.current[listId]?.ref?.validate?.(touch);
      }))
        .then((errors) => {
          return errors.reduce((o, f) => ({...o, ...utils.cleanObject(f)}), {});
        });
    },
    reset: (state) => {
      return Promise.all(Object.keys(listRefs.current).map((listId) => {
        return listRefs.current[listId]?.ref?.reset?.(state);
      }));
    }
  }), []);

  useImperativeHandle(ref, () => listMappingsForm);

  useLayoutEffect(() => {
    let minId = 0;

    const lsts = (lists ?? [])
      .map((s) => {
        if (!utils.isDefined(s.id)) {
          minId = minId - 1;
        } else if (+s.id < 0) {
          minId = Math.min(minId, +s.id);
        }

        return {
          ...s,
          id: !utils.isDefined(s.id) ? minId : +s.id
        }
      });

    if (!readOnly && lsts.length === 0) {
      let direction = constants.data.mappingDirections.filter((md) => md.connections.find((c) => c === connection?.type))[0];
      lsts.push({id: minId - 1, direction: direction?.value});
    }

    setInternalState(utils.updater({
      lists: lsts
    }, true));
  }, [lists, readOnly, connection?.type]);

  const handleAddList = () => {
    setInternalState((current) => {
      const direction = constants.data.mappingDirections.filter((md) => md.connections.find((c) => c === connection?.type))[0];
      const minId = current.lists.reduce((i, l) => +l.id < 0 ? Math.min(i, +l.id) : i, 0);

      return {
        ...current,
        lists: current.lists.concat([{id: minId - 1, direction: direction.value}])
      }
    });
  }

  const doErrors = () => {
    const errorIds = Object.keys(listRefs.current).reduce((o, k) => {
      return listRefs.current[k].ref && listRefs.current[k].error ? {...o, [k]: true} : o;
    }, {});

    const error = !utils.isEmpty(errorIds);

    onError?.(error);
    setInternalState(utils.updater({ error, errorIds }, true));
  }

  const handleChange = (list) => (values) => {
    const idx = internalState.lists.findIndex((l) => +l.id === +list.id);
    
    if (idx !== -1) {
      const newValue = [
        ...internalState.lists.slice(0, idx),
        {...list, ...values},
        ...internalState.lists.slice(idx + 1),
      ];

      setInternalState(utils.updater({lists: newValue}, true));
      onChange?.(newValue);
    }
  }

  const handleDelete = (list) => () => {
    const reset = listRefs.current[list.id]?.ref?.reset;
    delete listRefs.current[list.id];
    utils.retry(() => {
      reset?.({touched: {}});
    }, 3);

    const newValue = internalState.lists
      .filter((l) => +l.id !== +list.id);

    setInternalState(utils.updater({lists: newValue}, true));
    onChange?.(newValue);
    doErrors();
  }

  const handleRef = (list) => (ref) => {
    listRefs.current[list.id] = {
      ...listRefs.current[list.id],
      ref
    };
  }

  const handleError = (list) => (error) => {
    listRefs.current[list.id] = {
      ...listRefs.current[list.id],
      error
    };

    doErrors();
  }

  const renderList = (list, only) => {
    const hasErrorFrom = errorFrom || internalState.lists
      .filter((l) => !internalState.errorIds?.[l.id])
      .filter((ml) => !internalState.errorIds?.[list.id] && ml.from?.id === list.from?.id).length > 1;
    const hasErrorTo = errorTo || internalState.lists
      .filter((l) => !internalState.errorIds?.[l.id])
      .filter((ml) => !internalState.errorIds?.[list.id] && ml.to?.id === list.to?.id).length > 1;

    return <ListMappingForm ref={handleRef(list)}
                            key={list.id}
                            className="ListMappingsForm-list"
                            list={list}
                            only={only}
                            team={team}
                            mappedFields={mappedFields}
                            fieldData={fieldData}
                            connection={connection}
                            error={error}
                            errorFrom={Boolean(hasErrorFrom)}
                            errorTo={Boolean(hasErrorTo)}
                            required={required || !only}
                            readOnly={readOnly}
                            disabled={disabled}
                            removable={true}
                            hideActions={!multiple}
                            onChange={handleChange(list)}
                            onDelete={handleDelete(list)}
                            onError={handleError(list)}/>
  }

  return <StyledListMappingsForm ref={innerRef} {...innerProps}>
    <List gap={8} catchFocus={false}>
      {internalState.lists?.map((list) => {
        return <ListItem key={list.id} data-key={list.id} density="densest">
          {renderList(list, internalState.lists.length === 1)}
        </ListItem>
      })}
    </List>
    {(multiple && !(readOnly || disabled)) ?
      <Button variant="text"
              disabled={Boolean(error || errorFrom || errorTo || internalState.error || (internalState.lists.find((f) => utils.isEmpty(f.from?.id) || utils.isEmpty(f.to?.id))))}
              startIcon={<Icon icon={Add} />}
              onClick={handleAddList}>
        Add list
      </Button> : null}
  </StyledListMappingsForm>
});

ListMappingsForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  lists: PropTypes.array,
  team: PropTypes.object,
  mappedFields: PropTypes.array,
  connection: PropTypes.object,
  fieldData: PropTypes.object,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorFrom: PropTypes.bool,
  errorTo: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func
};

ListMappingsForm.defaultProps = {
  multiple: true
};

export default ListMappingsForm;
