import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Paper from 'components/atoms/Papers/Paper/Paper';

const StyledInfoPaper = styled(Paper)`
  &.InfoPaper {
    display: flex;
    position: relative;
    padding: ${props => props.theme.spacing(1)};
    border: 1px solid ${props => props.theme.property('palette.divider')};
    border-radius: ${props => props.theme.radius(1)};
    background-color: ${props => props.theme.property(`palette.${props.$color}.states.selected`)};
  }
  
  .InfoPaper {
    &-icon {
      margin-right: ${props => props.theme.spacing(0.5)};
    }

    &-info {
      .Typography {
        white-space: normal;  
      }
    }

    &-action {
      position: absolute;
      top: ${props => props.theme.spacing(0.5)};
      right: ${props => props.theme.spacing(0.5)};
    }
  }
  
  &.InfoPaper-radius-round {
    border-radius: ${props => props.theme.radius(2)};
  }
  
  ${ComponentStyles}
`;

StyledInfoPaper.propTypes = {
  theme: PropTypes.object
};

StyledInfoPaper.defaultProps = {
}

export default StyledInfoPaper;
