import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Img from 'components/atoms/Images/Img/Img';
import StyledAnalyserCard from 'components/organisms/Cards/AnalyserCard/AnalyserCard.styles';

const AnalyserCard = React.forwardRef((props, ref) => {
  const {
    analyser,
    title,
    description,
    icon,
    img,
    variant,
    onClick,
    IconProps,
    ImgProps,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'AnalyserCard', {
    variable: ['type']
  });

  const renderTitle = () => {
    return title ?? analyser?.label;
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    type: analyser?.value
  });

  return <StyledAnalyserCard ref={ref} {...innerProps} onClick={onClick}>
    <Box className="AnalyserCard-content">
      {(icon ?? analyser.icon) ? <Box className="AnalyserCard-logo">
        {utils.isReactElement(icon ?? analyser.icon) ? (icon ?? analyser.icon) : <Icon icon={icon ?? analyser.icon} isLoading={isLoading} {...IconProps}/>}
      </Box> : null}
      {(!(icon ?? analyser.icon) && (img ?? analyser.img)) ? <Box className="AnalyserCard-logo">
        {utils.isReactElement(img ?? analyser.img) ? (img ?? analyser.img) : <Img src={img ?? analyser.img} alt="logo" isLoading={isLoading} {...ImgProps}/>}
      </Box> : null}
      <Box className="AnalyserCard-info">
        <H6 className="AnalyserCard-title" showTooltip={true}
            min={8} max={20}
            isLoading={isLoading}>{renderTitle()}</H6>
        <Box className="AnalyserCard-description">
          <Typography isLoading={isLoading} variant="body2">{description ?? analyser.description}</Typography>
        </Box>
      </Box>
      <Box className="AnalyserCard-icon">
        <Icon size="smaller" icon={ChevronRight} />
      </Box>
    </Box>
  </StyledAnalyserCard>
});

AnalyserCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  analyser: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  img: PropTypes.any,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  IconProps: PropTypes.object,
  ImgProps: PropTypes.object
};

AnalyserCard.defaultProps = {};

export default AnalyserCard;
