import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useAnalyseCleanup, useAnalyseAssistant} from 'services/analyse/analyse.hooks';
import {useCollectionUploadCreate} from 'services/collection/upload/upload.utils';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Send from '@mui/icons-material/Send';
import Link from 'components/atoms/Links/Link/Link';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import {linkModifier} from 'helpers/hooks/links';
import Typography, {P, Span} from 'components/atoms/Text/Typography/Typography';
import EntitiesUploadDialog from 'components/organisms/Dialogs/EntitiesUploadDialog/EntitiesUploadDialog';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import Img from 'components/atoms/Images/Img/Img';
import Box from 'components/atoms/Layout/Box/Box';
import Chip from 'components/atoms/Chips/Chip/Chip';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import StyledEntitiesAnalyseChatDialogContent
  from 'components/organisms/Dialogs/EntitiesAnalyseChatDialogContent/EntitiesAnalyseChatDialogContent.styles';

const EntitiesAnalyseChatDialogContent = React.forwardRef((props, ref) => {
  const {
    collection,
    tableProvider,
    dirty,
    onDirty,
    onClose,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseChatDialogContent', {
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const messageFieldRef = useRef(null);
  const conversationRef = useRef(null);
  const formRef = useRef(null);

  const listState = useEffectItem(tableProvider.appliedListState());
  const entitySelectedEvent = useEffectEvent(tableProvider.updaters?.selectedItems);

  const [internalState, setInternalState] = useState({
    dirty: false,
    submitting: false,
    inputDisabled: true,
    entityIds: null,
    assistant: constants.data.lookup('assistantTypes', constants.analyse.assistantTypes.entityAnalyserMini),
    conversation: {
      context: {},
      messages: [{
        assistant: constants.data.lookup('assistantTypes', constants.analyse.assistantTypes.entityAnalyserMini).value,
        content: {
          type: 'text',
          text: {
            value: 'Welcome to company analyser, how can i assist?'
          }
        }
      }]
    }
  });

  const authorize = useAuthorize();
  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const analyseAssistant = useAnalyseAssistant();
  const analyseCleanup = useAnalyseCleanup();
  const createUpload = useCollectionUploadCreate();

  const entitiesAnalyseChatDialogContent = useMemo(() => {
    const state = {
      ...internalState,
      ...utils.cleanObject({dirty})
    };

    return {
      refs: {
        ref: innerRef,
        formRef,
        messageFieldRef,
        conversationRef
      },
      state: state,
      cleanup: () => {
        const fileIds = Object.keys(state.conversation.context).reduce((a, k) => {
          return a.concat(state.conversation.context[k]?.fileIds ?? [])
        }, []);
        const vectorStoreIds = Object.keys(state.conversation.context).reduce((a, k) => {
          return a.concat(state.conversation.context[k]?.vectorStoreIds ?? [])
        }, []);

        analyseCleanup.mutation.mutateAsync({
          fileIds,
          vectorStoreIds
        })
          .catch(() => {
            /* SQUASH */
          });
      }
    }
  }, [internalState, dirty, analyseCleanup.mutation]);

  useImperativeHandle(ref, () => entitiesAnalyseChatDialogContent);

  const fields = useMemo(() => ([{
    name: 'message',
    placeholder: 'Type your message',
    type: constants.formFieldTypes.textarea,
    validation: constants.formFieldValidationTypes.text,
    initial: null,
    FormFieldProps: {
      ref: messageFieldRef,
      disabled: entitiesAnalyseChatDialogContent.state.submitting,
      autoFocus: true,
      hiddenLabel: true,
      variant: 'standard',
      size: 'small',
      debounce: false,
      minRows: 1,
      maxRows: 8,
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        disableUnderline: true
      }
    }
  }]), [entitiesAnalyseChatDialogContent.state.submitting]);

  const handleSubmit = (values, actions) => {
    const getSelection = () => {
      if (!utils.isDefined(entitiesAnalyseChatDialogContent.state.conversation.context[entitiesAnalyseChatDialogContent.state.assistant.value]?.threadId)) {
        if (!utils.isDefined(entitiesAnalyseChatDialogContent.state.entityIds)) {
          return utils.asPromiseCallback(entitySelectedEvent)(listState, 0, tableProvider.list?.meta?.resultsCount, null, true)
            .then((entities) => {
              setInternalState((current) => {
                return {
                  ...current,
                  entityIds: entities.map((e) => e.entityId)
                };
              });

              return entities.map((e) => e.entityId);
            })
            .catch(() => {
              snackbar.show('Retrieving company data failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
        } else {
          return Promise.resolve(entitiesAnalyseChatDialogContent.state.entityIds);
        }
      } else {
        return Promise.resolve([]);
      }
    }

    setInternalState(utils.updater({submitting: true}, true));
    actions.resetForm();

    setInternalState((current) => {
      return {
        ...current,
        conversation: {
          ...current?.conversation,
          messages: current?.conversation.messages.concat([{
            assistant: entitiesAnalyseChatDialogContent.state.assistant.value,
            user: values['message']
          }])
        }
      };
    });

    getSelection()
      .then((entityIds) => {
        return analyseAssistant.mutation.mutateAsync({
          message: values['message'],
          assistantType: entitiesAnalyseChatDialogContent.state.assistant.value,
          collectionId: collection?.collectionId,
          entityIds: entityIds,
          context: {
            ...entitiesAnalyseChatDialogContent.state.conversation.context[entitiesAnalyseChatDialogContent.state.assistant.value],
            credits: entitiesAnalyseChatDialogContent.state.conversation.context.credits
          }
        })
          .then((res) => {
            setInternalState((current) => {
              return {
                ...current,
                conversation: {
                  ...current?.conversation,
                  context: {
                    ...current?.conversation?.context,
                    credits: res.response.data.data.context?.credits ?? 0,
                    [entitiesAnalyseChatDialogContent.state.assistant.value]: res.response.data.data.context
                  },
                  messages: res.response.data.data.messages?.length > 0 ?
                    current?.conversation.messages.concat(res.response.data.data.messages.map((m) => ({
                      ...m,
                      assistant: entitiesAnalyseChatDialogContent.state.assistant.value
                    }))) :
                    current?.conversation.messages.concat([{
                      assistant: entitiesAnalyseChatDialogContent.state.assistant.value,
                      analyser: 'Could not answer your message in time'
                    }])
                },
                inputDisabled: false
              };
            });
          })
          .catch((error) => {
            let msg = 'Failed to process your message';
            if (error?.response?.status === constants.http.status.conflict) {
              msg = '0 credits left in your wallet';
            }

            setInternalState((current) => {
              return {
                ...current,
                conversation: {
                  ...current?.conversation,
                  messages: current?.conversation.messages.concat([{
                    assistant: entitiesAnalyseChatDialogContent.state.assistant.value,
                    error: msg
                  }])
                },
                inputDisabled: false
              };
            });
          });
      })
      .finally(() => {
        setInternalState(utils.updater({submitting: false}, true));
        actions.setSubmitting(false);

        const focus = () => {
          return messageFieldRef.current?.focus();
        }

        utils.retry(focus, 3);
      });
  };

  const assistantAction = useMemo(() => {
    return {
      label: entitiesAnalyseChatDialogContent.state.assistant.label,
      color: entitiesAnalyseChatDialogContent.state.assistant.value === constants.analyse.assistantTypes.entityAnalyserMini ? 'greyScale' : 'success',
      onClick: () => {
        const next = entitiesAnalyseChatDialogContent.state.assistant.value === constants.analyse.assistantTypes.entityAnalyserMini ?
          constants.data.lookup('assistantTypes', constants.analyse.assistantTypes.entityAnalyserFull) :
          constants.data.lookup('assistantTypes', constants.analyse.assistantTypes.entityAnalyserMini);

        setInternalState((current) => {
          const showMessage = utils.isDefined(current?.conversation.context[entitiesAnalyseChatDialogContent.state.assistant.value]?.threadId) ||
            utils.isDefined(current?.conversation.context[next.value]?.threadId);
          const resume = utils.isDefined(current?.conversation.context[next.value]?.threadId);

          return {
            ...current,
            assistant: next,
            conversation: {
              ...current?.conversation,
              messages: showMessage ? current?.conversation.messages.concat((
                !resume ? [{
                  assistant: next.value,
                  analyser: `I see you have switched the type of conversation from ${entitiesAnalyseChatDialogContent.state.assistant.label} to ${next.label}.` +
                    ' The next message you send will start a new conversation. You can resume you previous conversation by switching back.' +
                    '       \nWhat can i do for you today? Feel free to ask.'
                }] : [{
                  assistant: next.value,
                  analyser: `I see you have switched the type of conversation from ${entitiesAnalyseChatDialogContent.state.assistant.label} to ${next.label}.` +
                    ' We will resume the previous conversation. Feel free to ask.'
                }]
              )) : [{...current?.conversation.messages[0], assistant: next.value}]
            }
          };
        });
      },
      ActionChipProps: {
        size: 'small',
        radius: 'round',
        variant: entitiesAnalyseChatDialogContent.state.assistant.value === constants.analyse.assistantTypes.entityAnalyserMini ? 'outlined' : 'filled'
      },
      ChipProps: {
        disabled: entitiesAnalyseChatDialogContent.state.submitting
      }
    }
  }, [entitiesAnalyseChatDialogContent.state.assistant, entitiesAnalyseChatDialogContent.state.submitting]);

  const sendAction = useMemo(() => ({
    icon: <Icon icon={Send} size="smaller" />,
    tooltip: 'Send',
    onClick: () => {
      formRef.current?.submit();
    },
    IconButtonProps: {
      disabled: entitiesAnalyseChatDialogContent.state.inputDisabled || entitiesAnalyseChatDialogContent.state.submitting,
      size: 'smaller',
      density: 'sparse',
      color: 'primary',
      variant: 'contained'
    }
  }), [entitiesAnalyseChatDialogContent.state.inputDisabled, entitiesAnalyseChatDialogContent.state.submitting]);

  useEffect(() => {
    conversationRef.current?.scrollTo({top: conversationRef.current?.scrollHeight, behavior: 'smooth'});
  }, [entitiesAnalyseChatDialogContent.state.submitting, entitiesAnalyseChatDialogContent.state.assistant?.value]);

  const handleChangeDirect = (e) => {
    setInternalState(utils.updater({inputDisabled: !(e.target.value?.length > 0)}, true));
  };

  const handleValidating = (isValidating, isDirty) => {
    onDirty?.(isDirty ? true : entitiesAnalyseChatDialogContent.state.dirty);
  }

  const renderMessage = (message, idx) => {
    const parseLink = (href, type) => {
      if (href.includes(`/${type}`)) {
        return href.replace('data', '').replace('text', '').replace('base64', '').replace(`${type}`, '').replace(/[:;,/]+/gi, '');
      } else {
        return null;
      }
    }

    const markDownComponents = {
      a: ({node, ...props}) => {
        if (node.properties?.href?.startsWith('blob:')) {
          const split = node.properties?.href.split(/[&?]/);
          const params = {};
          split.slice(1).forEach((s) => {
            const split = decodeURIComponent(s).split('=');
            if (split.length === 2) {
              params[split[0]] = split[1];
            }
          });

          if (params.filename) {
            return <Link {...props} download={params.filename} href={split[0]}>{props.children}</Link>
          } else {
            return <Link {...props} href={split[0]}>{props.children}</Link>
          }
        } else if (parseLink(node.properties?.href, 'filter')) {
          const params = parseLink(node.properties?.href, 'filter');
          let valid = false;
          try {
            valid = utils.toArray(JSON.parse(atob(params)))
              .filter((id) => +id > 0).length > 0;
          } catch (e) {
            /* SQUASH */
          }

          if (valid) {
            return <ActionLink action={{
              label: props.children,
              navigation: {
                to: `?custom=analyse:${params}`,
                resetSearchParams: true
              },
              onClick: (e) => {
                if (!linkModifier(e)) {
                  onClose?.(e, 'closeButtonClick');
                }
              }
            }}/>
          } else {
            return <Span color="error">It's not possible to show in Catalist</Span>
          }
        } else if (parseLink(node.properties?.href, 'upload')) {
          const canUpdate = collection && authorize({attribute: 'collection.entity.create', meta: {collection}});

          if (canUpdate) {
            let blob;
            try {
              blob = utils.base64ToBlob(parseLink(node.properties?.href, 'upload'))
            } catch (e) {
              /* SQUASH */
            }

            if (blob) {
              return <ActionLink action={{
                label: props.children,
                onClick: (e) => {
                  const file = utils.blobToFile(blob, 'companies.csv');

                  const handleSubmit = (upload) => {
                    return createUpload({
                      name: upload.name,
                      params: upload.params,
                      period: upload.period,
                      subType: '',
                      type: upload.type
                    }, collection)
                      .then(() => {
                        snackbar.show('File upload is scheduled', null,
                          {color: 'success', autoHideDuration: constants.delay.success});
                        dialogControl.hide();
                      });
                  };

                  const handleClose = () => {
                    dialogControl.hide();
                  };

                  dialogControl.show(<EntitiesUploadDialog onSubmit={handleSubmit}
                                                           EntitiesUploadWizardProps={{
                                                             collection,
                                                             file
                                                           }}/>, true, handleClose);

                  e.preventDefault();
                }
              }}/>
            } else {
              return <Span color="error">Invalid file provided</Span>
            }
          } else {
            return <Span color="error">Saving company data is not allowed</Span>
          }
        } else if (parseLink(node.properties?.href, 'invalid')) {
          let error;
          try {
            error = atob(parseLink(node.properties?.href, 'invalid'));
          } catch (e) {
            /* SQUASH */
          }
          return <Span color="error">{error ?? 'Invalid'}</Span>
        } else {
          return <Link {...props}>{props.children}</Link>
        }
      }
    }

    const fileToDataUrl = (file_data, file_info, isImage) => {
      let contentType = isImage ? 'image/png' : 'text/plain';

      if (file_info?.filename?.toLowerCase()?.endsWith('.html')) {
        contentType = 'text/html';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.pdf')) {
        contentType = 'application/pdf';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.xlsx')) {
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.xls')) {
        contentType = 'application/vnd.ms-excel';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.csv')) {
        contentType = 'text/csv';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.json')) {
        contentType = 'application/json';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.png')) {
        contentType = 'image/png';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.jpeg') || file_info?.filename?.toLowerCase()?.endsWith('.jpg')) {
        contentType = 'image/jpeg';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.gif')) {
        contentType = 'image/gif';
      }

      let url;
      try {
        url = URL.createObjectURL(utils.base64ToBlob(file_data, contentType));

        if (!isImage) {
          url += (file_info.filename ? `?filename=${file_info.filename.split('/').slice(-1)[0]}` : '') +
            (contentType ? `${file_info.filename ? '&' : '?'}contentType=${contentType}` : '');
        }
      } catch (e) {
        /* SQUASH */
      }

      return url ?? `data:text/invalid;base64,${btoa('Invalid file provided')}`;
    }

    const processAnnotations = (content, attachments) => {
      let text = content?.text?.value;

      utils.toArray(content?.text?.annotations).forEach((annotation) => {
        if (annotation.type === 'file_path') {
          const file = attachments.find((a) => a.file_id === annotation.file_path.file_id);
          if (file) {
            let url = fileToDataUrl(file.file_data, file.file_info, false);

            text = utils.replace(text, annotation.text, url, 'gi');
          }
        } else if (annotation.type === 'file_citation') {
          text = utils.replace(text, annotation.text, '', 'gi');
        }
      });

      // fix possibly bad links
      return text.replace(/(\[.*\]\([^)}]+)\}+/g, '$1)');
    }

    const renderResult = () => {
      return utils.toArray(message?.content).map((c, idx) => {
        if (c?.type === 'text') {
          return <P key={idx}>
            {idx === 0 ? <Span className="title">Analyser:</Span> : null}{idx === 0 ? <br/> : null}
            <Markdown components={markDownComponents}>{processAnnotations(c, message.attachments)}</Markdown>
          </P>
        } else if (c?.type === 'image_url') {
          return <P key={idx}>
            {idx === 0 ? <Span className="title">Analyser:</Span> : null}{idx === 0 ? <br/> : null}
            <Img alt={c?.image_url?.url?.split('/').slice(-1)[0] ?? 'image'}
                 src={c?.image_url?.url}/>
          </P>
        } else if (c?.type === 'image_file') {
          return <P key={idx}>
            {idx === 0 ? <Span className="title">Analyser:</Span> : null}{idx === 0 ? <br/> : null}
            <Img alt={c?.image_info?.filename?.split('/').slice(-1)[0] ?? 'image'}
                 src={fileToDataUrl(c?.image_data, c?.image_info, true)}/>
          </P>
        } else {
          return <P key={idx}>
            {idx === 0 ? <Span className="title">Analyser:</Span> : null}{idx === 0 ? <br/> : null}
            {JSON.stringify(c)}
          </P>
        }
      });
    }

    const msgAssistant = constants.data.lookup('assistantTypes', message?.assistant);
    return <Box key={idx} className="EntitiesAnalyseChatDialogContent-message">
      <Chip className="EntitiesAnalyseChatDialogContent-message-chip"
            showTooltip={false}
            size="small"
            radius="round"
            variant={msgAssistant.value === constants.analyse.assistantTypes.entityAnalyserMini ? 'outlined' : 'filled'}
            color={msgAssistant.value === constants.analyse.assistantTypes.entityAnalyserMini ? 'greyScale' : 'success'}
            label={msgAssistant.label}/>

      {message.error ? <P>
        <Span className="title">Error:</Span><br />
        <Span color="error"><Markdown>{message.error}</Markdown></Span>
      </P> : (message.user ? <P>
        <Span className="title">User:</Span><br />
        <Markdown>{message.user}</Markdown>
      </P> : (message.analyser ? <P>
        <Span className="title">Analyser:</Span><br />
        <Markdown>{message.analyser}</Markdown>
      </P> : renderResult()))}
    </Box>
  }

  return <StyledEntitiesAnalyseChatDialogContent ref={innerRef} {...innerProps}>
    <Box ref={conversationRef} className="EntitiesAnalyseChatDialogContent-conversation">
      {entitiesAnalyseChatDialogContent.state.conversation.messages.map((message, idx) => {
        return renderMessage(message, idx);
      })}
      {entitiesAnalyseChatDialogContent.state.submitting ? <Box className="EntitiesAnalyseChatDialogContent-loader">
        <CircularProgress />
        <P>Please wait</P>
      </Box> : null}
    </Box>
    <Box className="EntitiesAnalyseChatDialogContent-footer">
      <Box className="EntitiesAnalyseChatDialogContent-info">
        <Typography className="EntitiesAnalyseChatDialogContent-credits" variant="subtitle2">
          {utils.formatNumber(Math.round(entitiesAnalyseChatDialogContent.state.conversation.context?.credits ?? 0))} credits
        </Typography>
        <ActionChip action={assistantAction} />
      </Box>
      <Box className="EntitiesAnalyseChatDialogContent-form">
        <InlineForm ref={formRef}
                    fields={fields}
                    onValidating={handleValidating}
                    onChangeDirect={handleChangeDirect}
                    onSubmit={handleSubmit} />
        <ActionIconButton action={sendAction} />
      </Box>
    </Box>
  </StyledEntitiesAnalyseChatDialogContent>
});

EntitiesAnalyseChatDialogContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  dirty: PropTypes.bool,
  onDirty: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

EntitiesAnalyseChatDialogContent.defaultProps = {
};

export default EntitiesAnalyseChatDialogContent;
