import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Paper from 'components/atoms/Papers/Paper/Paper';

const StyledSplitBar = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.property('palette.background.paper')};
  border-radius: ${props => props.theme.radius(2)};

  &.SplitBar {
    > *:first-child {
      flex-grow: 1;
    }
    > *:last-child {
      border-left: 1px solid ${props => props.theme.property('palette.divider')};
    }
  }
  
  .SplitBar {
    &-actions {
      display: flex;
      padding: ${props => props.theme.spacing(1.5)};
      gap: ${props => props.theme.layout('1sp')};
      
      .Button {
        white-space: nowrap;
      }
    }

    &-context {
      padding: ${props => props.theme.spacing(1.5)};
    }
  }
  
  ${ComponentStyles}
`;

StyledSplitBar.propTypes = {
  theme: PropTypes.object
};

StyledSplitBar.defaultProps = {}

export default StyledSplitBar;
