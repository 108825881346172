import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFieldInline from 'components/molecules/Inlines/FieldInline/FieldInline.styles';
import utils from 'helpers/utils';
import FormField from 'components/organisms/Fields/FormField/FormField';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import Edit from '@mui/icons-material/Edit';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import FieldInlineEdit from 'components/organisms/InlineEdits/FieldInlineEdit/FieldInlineEdit';
import {withMemo} from 'helpers/wrapper';

const FieldInline = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    fieldData,
    canUpdate,
    onChange,
    DropDownPopperProps,
    ...innerProps
  } = useComponentProps(props, 'FieldInline', {
    static: ['canUpdate']
  });

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);

  const formFields = useMemo(() => {
    return fields.map((field) => {
      const formField = utils.fields2FormFields([field], fieldData)[0];
      const initialValues = utils.fields2InitialValues([formField]);
      return {
        ...formField,
        value: initialValues[formField.name],
        readOnly: true,
        FormFieldProps: utils.mergeObjects(formField.FormFieldProps, {
          size: 'smaller',
          fullWidth: true,
          minWidth: null,
          variant: 'inlineLabel',
          hiddenLabel: true,
          hiddenHelperText: true,
          hiddenPlaceholder: true
        }, true)
      };
    });
  }, [fields, fieldData]);

  const handleToggle = (open) => {
    if (!open) {
      setBusy(false);
    }
    setOpen(open);
  };

  const handleFieldClick = () => {
    if (!open || !busy) {
      if (open) {
        setBusy(false);
      }
      setOpen((current) => !current);
    }
  }

  const handleChange = (field, value, onSuccess, onError) => {
    const handleError = () => {
      onError?.();
      setOpen(true);
      setBusy(true);
    };
    onChange?.(field, value, onSuccess, handleError);
  };

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledFieldInline ref={ref} {...innerProps}>
    <Box ref={anchorRef}
         className="FieldInline-content"
         onClick={canUpdate ? handleFieldClick : null}>
      {formFields.map((formField, idx) => {
        return <FormField key={idx}
                          className="FieldInline-field"
                          field={formField}
                          value={formField.value}
                          {...formField.FormFieldProps}/>
      })}
      {canUpdate ? <Icon className="FieldInline-edit"
                         size="tiny"
                         icon={Edit}/> : null}
    </Box>
    <DropdownPopper anchorEl={anchorRef.current}
                    open={open}
                    fullWidth={true}
                    autoClose={!busy}
                    onToggle={handleToggle}
                    density="densest"
                    {...DropDownPopperProps}>
      <FieldInlineEdit fields={fields}
                       onChange={handleChange}
                       onBusy={setBusy} />
    </DropdownPopper>
  </StyledFieldInline>
}));

FieldInline.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  canUpdate: PropTypes.bool,
  onChange: PropTypes.func,
  DropDownPopperProps: PropTypes.object
};

FieldInline.defaultProps = {};

export default FieldInline;
