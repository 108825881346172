import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from '@mui/material/Dialog';
import utils from 'helpers/utils';

const StyledDialog = styled(Dialog)`
  &.Dialog-hide {
    display: none;
  }
  
  &.Dialog {
    .MuiDialog-paper {
      > .Wrapper {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .notistack-SnackbarContainer {
          position: absolute;
          margin-bottom: 0;
          margin-right: 0;
          margin-left: -${props => props.theme.layout('0.75sp')};
          margin-top: -${props => props.theme.layout('0.5sp')};
        }
      }
    }

    ${props => utils.isNumber(props.$width) ? css`
      .MuiDialog-paper {
        width: clamp(0px, ${props.theme.layout(props.$width)}, 86vw);
        min-width: clamp(0px, ${props.theme.layout(props.$width)}, 86vw);
        max-width: clamp(0px, ${props.theme.layout(props.$width)}, 86vw);
      }
    ` : css``}

    ${props => utils.isNumber(props.$maxWidth) ? css`
      .MuiDialog-paper {
        max-width: clamp(0px, ${props.theme.layout(props.$maxWidth)}, 86vw);
      }
    ` : css``}

    ${props => utils.isNumber(props.$minWidth) ? css`
      .MuiDialog-paper {
        height: ${props.$minWidth > 0 ? `clamp(0px, ${props.theme.layout(props.$minWidth)}, 86vw)` : 'unset'};
        min-width: clamp(0px, ${props.theme.layout(props.$minWidth)}, 86vw);
      }
    ` : css``}

    ${props => utils.isNumber(props.$height) ? css`
      .MuiDialog-paper {
        height: clamp(0px, ${props.theme.layout(props.$height)}, 86vh);
        min-height: clamp(0px, ${props.theme.layout(props.$height)}, 86vh);
        max-height: clamp(0px, ${props.theme.layout(props.$height)}, 86vh);
      }
    ` : css``}

    ${props => utils.isNumber(props.$maxHeight) ? css`
      .MuiDialog-paper {
        max-height: clamp(0px, ${props.theme.layout(props.$maxHeight)}, 86vh);
      }
    ` : css``}

    ${props => utils.isNumber(props.$minHeight) ? css`
      .MuiDialog-paper {
        height: ${props.$minHeight > 0 ? `clamp(0px, ${props.theme.layout(props.$minHeight)}, 86vh)` : 'unset'};
        min-height: clamp(0px, ${props.theme.layout(props.$minHeight)}, 86vh);
      }
    ` : css``}
  }


  &.Dialog-placement {
    &-top {
      .MuiDialog-container {
        align-items: flex-start;

        .MuiDialog-paper {
          margin-top: 8vh;
        }
      }
    }
    &-bottom {
      .MuiDialog-container {
        align-items: flex-end;

        .MuiDialog-paper {
          margin-bottom: 8vh;
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    .MuiDialog-paper {
      > .Wrapper {
        .notistack-SnackbarContainer {
          margin-left: -${props => props.theme.layout('1sp')};
          margin-top: -${props => props.theme.layout('0.75sp')};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDialog.propTypes = {
  theme: PropTypes.object
};

StyledDialog.defaultProps = {}

export default StyledDialog;
