import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DataList from 'components/organisms/Lists/DataList/DataList';

const StyledArticlesList = styled(DataList)`
  &.ArticlesList {
    .ListItem {
      &:focus {
        padding-left: 20px;
      }
    }
  }

  ${ComponentStyles}
`;

StyledArticlesList.propTypes = {
  theme: PropTypes.object
};

StyledArticlesList.defaultProps = {}

export default StyledArticlesList;
