import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledGraphFrame from 'components/molecules/Frames/GraphFrame/GraphFrame.styles';

const injectStyle = `
  body {
    padding: 0 0.5rem;
    margin: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    
    font-family: 'Roboto', 'Helvetica', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    
    img {
      max-width: 100%;
      max-height: 100%;
    }
    
    canvas {
      max-width: 100% !important;
      max-height: calc(100% - 32px) !important;
      margin: auto;
    }
    
    .modebar-container {
      right: 8px !important;
    }
    .modebar-container > * > *:first-child {
      display: none !important;
    }
    .modebar-container > * > *:last-child {
      display: none !important;
    }
  }`;

const GraphFrame = React.forwardRef((props, ref) => {
  const {
    srcDoc,
    ...innerProps
  } = useComponentProps(props, 'GraphFrame');

  const innerRef = useRef(null);

  const srcDocMemo = useMemo(() => {
    return srcDoc ? srcDoc
      .replace(/<head([^>]*)>/i, `<head$1>
                  <base href="${window.location.href}">
                  <style type="text/css">${injectStyle}</style>
                `) : srcDoc;
  }, [srcDoc]);

  const graphFrame = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    source: srcDocMemo
  }), [srcDocMemo]);

  useImperativeHandle(ref, () => graphFrame)

  const sandbox = useMemo(() => {
    return [
      'allow-scripts',
      'allow-forms'
    ].join(' ');
  }, []);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledGraphFrame ref={innerRef} {...innerProps} srcDoc={srcDocMemo} sandbox={sandbox}>
  </StyledGraphFrame>
});

GraphFrame.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  state: PropTypes.object,
  url: PropTypes.string,
  onLoading: PropTypes.func,
  onWaiting: PropTypes.func,
  onFailed: PropTypes.func,
  onSuccess: PropTypes.func
};

GraphFrame.defaultProps = {
};

export default GraphFrame;
