import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import AccordionSummary from 'components/atoms/Accordions/AccordionSummary/AccordionSummary';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import AccordionDetails from 'components/atoms/Accordions/AccordionDetails/AccordionDetails';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Component from 'components/organisms/Utils/Component/Component';
import Badge from 'components/atoms/Badges/Badge/Badge';
import StyledTableFiltersContentFilter
  from 'components/organisms/TableFilters/TableFiltersContentFilter/TableFiltersContentFilter.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import dom from 'helpers/dom';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {CloseFilled} from 'assets/icons';

const TableFiltersContentFilter = React.forwardRef((props, ref) => {
  const {
    filter,
    filterGroup,
    tableFilters,
    fieldData,
    onChange,
    onClear,
    ...innerProps
  } = useComponentProps(props, 'TableFiltersContentFilter');

  const innerRef = useRef(null);

  const [expanded, setExpanded] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const filterFields = useMemo(() => {
    const initial = !(filter.type === constants.formFieldTypes.list) ? filter.initial :
      utils.toArray(filter.initial, true).map((v) => {
        return (filter.conversion === constants.formFieldConversionTypes.label) ? {
          label: v.toString().startsWith('-') ? v.toString().slice(1) : v,
          indeterminate: v.toString().startsWith('-')
        } : {
          value: v.toString().startsWith('-') ? v.toString().slice(1) : v,
          indeterminate: v.toString().startsWith('-')
        }
      });

    return [{
      ...filter,
      initial,
      conversion: (filter.type === constants.formFieldTypes.list) ? constants.formFieldConversionTypes.none :
        filter.conversion,
      immediate: filter.type !== constants.formFieldTypes.textarea,
      FormFieldProps: utils.mergeObjects(
        filter.type === constants.formFieldTypes.list ? {
          indeterminate: true,
          InputProps: {
            indeterminateIcon: <Icon icon={CloseFilled} color="error"/>
          },
          ListProps: {
            catchFocus: false
          }
        } : {}, filter.FormFieldProps)
    }]
  }, [filter]);

  const handleChange = (field, value) => {
    if (filter.type === constants.formFieldTypes.list) {
      if (filter.conversion === constants.formFieldConversionTypes.label) {
        value = value.map((v) => `${v?.indeterminate ? '-' : ''}${v?.labelValue ?? v?.label ?? v}`);
      } else {
        value = value.map((v) => `${v?.indeterminate ? '-' : ''}${v?.value ?? v}`);
      }
    }

    onChange?.(filter, value);
  }

  const handleExpand = (e, expanded) => {
    setExpanded(expanded);
  }

  const handleEntered = () => {
    utils.retry(() => {
      if (innerRef.current) {
        dom.scrollIntoView(innerRef.current);
      }
    }, 3);
  }

  useLayoutEffect(() => {
    setExpanded(false);
  }, [tableFilters.state.searchValue]);

  const renderFilterBadge = (filter) => {
    if (filter.activeCount > 0) {
      return <Badge badgeContent={<Tooltip title={'Clear'}>
                                    <Span onClick={(e) => {
                                            onClear?.(filter);
                                            e.preventDefault();
                                            e.stopPropagation();
                                          }}
                                          onMouseDown={(e) => e.stopPropagation()}>
                                      {filter.activeCount}
                                    </Span>
                                  </Tooltip>}
                    clickable={true} />
    } else {
      return null;
    }
  }

  const renderFilterTitle = (filter) => {
    return <Typography className="TableFiltersContentFilter-title" variant="subtitle2">
      <Markdown>{filter.label}</Markdown>
    </Typography>
  }

  const renderFilter = (filter) => {
    const renderedFilter = <InlineForm className="TableFiltersContentFilter-filter"
                                       autoTouch={true}
                                       fields={filterFields}
                                       fieldData={fieldData}
                                       onChange={handleChange}/>

    if (filter.Filter) {
      return <Component Original={filter.Filter}
                        className="TableFiltersContent-filter"
                        tableFilters={tableFilters}
                        filterGroup={filterGroup}
                        filter={filter}
                        onFilterChange={handleChange}
                        renderedFilter={renderedFilter} />
    } else {
      return renderedFilter;
    }
  }

  return <StyledTableFiltersContentFilter ref={innerRef} {...innerProps}
                                          expanded={expanded}
                                          onChange={handleExpand}
                                          elevation={0}
                                          density="dense"
                                          square
                                          disableGutters
                                          TransitionProps={{
                                            unmountOnExit: true,
                                            onEntered: handleEntered
                                          }}>
    <AccordionSummary badge={renderFilterBadge(filter)}
                      expandIcon={<Icon icon={ArrowDropDown} />}>
      {renderFilterTitle(filter)}
    </AccordionSummary>
    <AccordionDetails>
      {renderFilter(filter)}
    </AccordionDetails>
  </StyledTableFiltersContentFilter>
});

TableFiltersContentFilter.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  filter: PropTypes.object,
  filterGroup: PropTypes.object,
  tableFilters: PropTypes.object,
  fieldData: PropTypes.object,
  onChange: PropTypes.func,
  onClear: PropTypes.func
};

TableFiltersContentFilter.defaultProps = {
};

export default TableFiltersContentFilter;
