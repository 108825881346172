import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Wizard from 'components/organisms/Wizards/Wizard/Wizard';

const StyledEntitiesAnalyseGraphWizard = styled(Wizard)`
  .Wizard {
    &-content {
      padding: 0 !important;
    }
    &-step {
      &-content {
        padding: ${props => props.theme.spacing(3)};
        
        &-criteria {
          .Form-fields {
            .FormField {
            }
          }
        }
      }
    }
    .ToolbarWrapper-toolbar {
      padding-right: 0;
    }
  }
  
  &.EntitiesAnalyseGraphWizard {
    .ToolbarWrapper-toolbar {
      padding-right: 0 !important;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitiesAnalyseGraphWizard.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseGraphWizard.defaultProps = {}

export default StyledEntitiesAnalyseGraphWizard;
