import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import DealflowPieChart from 'components/organisms/Charts/DealflowPieChart/DealflowPieChart';
import StyledDealflowBreakdownGraphCard
  from 'components/organisms/Cards/DealflowBreakdownGraphCard/DealflowBreakdownGraphCard.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useClientStatisticDealflow} from 'services/client/statistic/statistic.utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import {useLinkNavigate} from 'helpers/hooks/links';

const DealflowBreakdownGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowBreakdownGraphCard');

  const tableProvider = useTable();
  const graphState = tableProvider.graphState;
  const [dealflow, setDealflow] = useState(null);

  const navigate = useLinkNavigate();

  const listState = useEffectItem(tableProvider.appliedListState());
  const [dealflowBreakdown, isLoading] = useClientStatisticDealflow(null, listState.search, listState.filter);

  useEffect(() => {
    if (dealflowBreakdown) {
      setDealflow(dealflowBreakdown
        .filter(df => df.id > 0)
        .map(df => ({
          count: df.value,
          label: df.label,
          status: df.id
        })));
    }
  }, [dealflowBreakdown]);

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'breakdown': {
        ...current?.['breakdown'],
        visibility
      }
    }));
  };

  const handleClick = (e, release, data) => {
    if (data?.id) {
      const prefix = data?.id;
      navigate({
        event: e,
        to: `/dealflow/table?custom=dealflow:${prefix}_-1`,
        keepSearchParams: true
      });
    }
  };

  return <StyledDealflowBreakdownGraphCard ref={ref} {...innerProps}
                                           title="Dealflow breakdown">
    {(dealflow?.length > 0 || isLoading) ?
      <DealflowPieChart dealflow={dealflow}
                        showLegend={true}
                        showNoDealflow={false}
                        isLoading={isLoading}
                        visibility={graphState.graphSettings['breakdown']?.visibility}
                        onClick={handleClick}
                        onVisibilityChange={handleVisibilityChange} /> : null}
    {(dealflow?.length === 0 && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No dealflow data found</P>
      </Box> : null}
  </StyledDealflowBreakdownGraphCard>
});

DealflowBreakdownGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowBreakdownGraphCard.defaultProps = {};

export default DealflowBreakdownGraphCard;
