import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import {useClientTeamMemberOptions} from 'services/client/team/team.utils';
import utils from 'helpers/utils';
import {useAuthTeamId} from 'services/auth/auth.utils';
import StyledEntitiesAnalyseActionsWizardContent
  from 'components/organisms/WizardContent/EntitiesAnalyseActionsWizardContent/EntitiesAnalyseActionsWizardContent.styles';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import BadgeOption from 'components/molecules/Options/BadgeOption/BadgeOption';
import {useCollectionGet} from 'services/collection/collection.hooks';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';

const EntitiesAnalyseActionsWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    collection,
    tableProvider,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseActionsWizardContent');

  const data = wizard.data?.[wizard.dataKey];
  const setDataEvent = useEffectEvent(wizard.setData);

  const isSuggestion = tableProvider?.view?.name === 'suggestions';
  const isRejection = tableProvider?.view?.name === 'rejections';

  const client = useAuthClient();
  const teamId = useAuthTeamId();
  const dealLeaderOptions = useClientTeamMemberOptions(teamId, Boolean(client?.props?.proxyCanBeDealLeader), false);
  const selectedCollection = useCollectionGet({collectionId: data?.collection?.value},
    {enabled: Boolean(data?.collection?.value)})?.data;

  const authorize = useAuthorize();
  const dialogControl = useDialogControl();

  const fields = useMemo(() => {
    const fields = [];

    const actionOptions = constants.data.entityAnalyserActions
      .filter((eea) => {
        return ![constants.analyse.entityAnalyserActions.createTagGroups].includes(eea.value) &&
          ((utils.isDefined(collection) && !isRejection) || ![constants.analyse.entityAnalyserActions.applyToExisting].includes(eea.value)) &&
          ((!utils.isDefined(collection) || isSuggestion || isRejection) || ![constants.analyse.entityAnalyserActions.addToCollection].includes(eea.value)) &&
          ((utils.isDefined(collection) && !(isSuggestion || isRejection)) || ![constants.analyse.entityAnalyserActions.removeFromCollection].includes(eea.value)) &&
          (utils.isDefined(collection) || ![constants.analyse.entityAnalyserActions.addToOtherCollection].includes(eea.value)) &&
          (isSuggestion || ![constants.analyse.entityAnalyserActions.rejectFromCollection].includes(eea.value));
      })
      .filter((eea) => authorize({attribute: `entity.analyse.action.${eea.value}`}));

    const collectionActionOptions = actionOptions.filter((eea) => {
      return [
        constants.analyse.entityAnalyserActions.applyToExisting,
        constants.analyse.entityAnalyserActions.addToCollection,
        constants.analyse.entityAnalyserActions.addToOtherCollection,
        constants.analyse.entityAnalyserActions.removeFromCollection,
        constants.analyse.entityAnalyserActions.rejectFromCollection
      ].includes(eea.value);
    });

    const otherActionOptions = actionOptions.filter((eea) => {
      return !collectionActionOptions.find((ca) => ca.value === eea.value) &&
        (![constants.analyse.entityAnalyserActions.removeFromCollection,
            constants.analyse.entityAnalyserActions.rejectFromCollection].includes(data?.collectionAction?.value));
    });

    fields.push({
      name: 'collectionAction',
      label: 'What would you like to do with the analysed companies?',
      inlineLabel: 'Actions',
      entity: 'analyser',
      formGroup: 'actions',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.text,
      options: collectionActionOptions,
      required: true,
      FormFieldProps: {
        multiple: false
      }
    });

    const otherRelations = [constants.analyse.entityAnalyserActions.removeFromCollection,
      constants.analyse.entityAnalyserActions.rejectFromCollection];

    fields.push({
      name: 'otherActions',
      label: 'Actions',
      inlineLabel: 'Actions',
      entity: 'analyser',
      formGroup: 'actions',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.list,
      relation: `collectionAction(${otherRelations.map((r) => `-${r}`).join(',')})`,
      options: otherActionOptions,
      required: [constants.analyse.entityAnalyserActions.applyToExisting].includes(data?.collectionAction?.value),
      FormFieldProps: {
        multiple: true
      }
    });

    const collectionRelations = utils.isDefined(collection) ? [constants.analyse.entityAnalyserActions.addToOtherCollection] :
      [constants.analyse.entityAnalyserActions.addToCollection, constants.analyse.entityAnalyserActions.addToOtherCollection];

    fields.push({
      name: 'collection',
      label: 'Collection',
      inlineLabel: 'collection',
      entity: 'analyser',
      formGroup: 'settings',
      filter: collection ? [`-${collection.collectionId}`] : null,
      relation: `collectionAction(${collectionRelations.join(',')})`,
      type: constants.formFieldTypes.autocomplete,
      conversion: constants.formFieldValidationTypes.none,
      validation: constants.formFieldValidationTypes.collection,
      options: 'collections',
      required: true,
      FormFieldProps: {
        createOption: true,
        multiple: false,
        fullWidth: true
      }
    });

    const tagGroupRelations = [constants.analyse.entityAnalyserActions.createTagGroups,
      constants.analyse.entityAnalyserActions.createTagGroup];

    fields.push({
      name: 'tagGroup',
      label: 'Category name',
      entity: 'analyser',
      formGroup: 'settings',
      relation: `otherActions(${tagGroupRelations.join(',')})`,
      type: constants.formFieldTypes.text,
      validate: (value, testContext) => {
        const testCollection = collectionRelations.includes(data?.collectionAction?.value ?? data?.collectionAction) ? selectedCollection : collection;
        const existing = testCollection?.tagGroups?.find((tg) => tg.name.toLowerCase() === value?.toLowerCase());

        if (existing) {
          return testContext.createError({message: `Category '${value}' already exists`});
        } else {
          return true;
        }
      },
      required: true,
      FormFieldProps: {
        fullWidth: true
      }
    });

    const dealLeaderRelations = [constants.analyse.entityAnalyserActions.setDealLeader];
    fields.push({
      name: 'dealLeader',
      label: 'Deal leader',
      entity: 'analyser',
      formGroup: 'settings',
      relation: `otherActions(${dealLeaderRelations.join(',')})`,
      type: constants.formFieldTypes.user,
      validation: constants.formFieldValidationTypes.dealLeader,
      options: dealLeaderOptions,
      required: true,
      FormFieldProps: {
        fullWidth: true
      }
    });

    const dealflowStatusRelations = [constants.analyse.entityAnalyserActions.setDealflowStatus];
    fields.push({
      name: 'dealflowStatus',
      label: 'Deal flow status',
      entity: 'analyser',
      formGroup: 'settings',
      relation: `otherActions(${dealflowStatusRelations.join(',')})`,
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.dealflowStatus,
      options: 'dealflowStatuses',
      required: true,
      FormFieldProps: {
        fullWidth: true
      }
    });

    const tagGroup = data?.fieldData?.tagGroups?.find((tg) => +tg.groupId === +data?.question.analyserParams.tagGroup);
    const entities = (data?.entityIds ?? [])
      .slice(0, ((data?.[data?.questionHash]?.batch + 1) * constants.analyse.batchSizes.match))
      .filter((entityId) => data?.entities?.[entityId]?.questionHash === data?.questionHash)
      .filter((entityId) => utils.isDefined(data?.entities?.[entityId]?.evidenceTime))
      .map((entityId) => data?.entities?.[entityId]);

    const alwaysShowTags = [
      constants.analyse.entityAnalyserActions.addToCollection,
      constants.analyse.entityAnalyserActions.addToOtherCollection,
      constants.analyse.entityAnalyserActions.removeFromCollection,
      constants.analyse.entityAnalyserActions.rejectFromCollection].includes(data?.collectionAction?.value);

    const empty = (data?.entityIds ?? []).filter((entityId) => {
      return !utils.isDefined(entities.find((e) => +e.entityId === +entityId)?.tagGroups?.[0]);
    }).length;

    const tagOptions = tagGroup.tags.map((t) => {
      const count = entities.reduce((c, entity) => {
        return c + (entity.tagGroups?.[0]?.tags?.[0]?.tagId === t.tagId ? 1 : 0);
      }, 0);

      return {
        value: t.tagId,
        label: t.value,
        count: count,
        disabled: count === 0
      }
    }).concat([{
      value: 0,
      label: 'empty',
      count: empty,
      disabled: empty === 0
    }]);

    fields.push({
      name: 'selectedTags',
      label: 'Select which companies to include',
      inlineLabel: 'Companies',
      entity: 'analyser',
      formGroup: 'matches',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.list,
      relation: alwaysShowTags ? null : 'otherActions',
      options: tagOptions,
      initial: data?.selectedTags ?? tagOptions.filter((t) => t.count > 0).map((t) => t.value),
      required: true,
      FormFieldProps: {
        multiple: true,
        renderOptionLabel: (option) => {
          return <BadgeOption badge={`${option.count}`}
                              label={option.label}
                              reverse={true}
                              BadgeProps={{color: 'badgeLight', max: 999}} />
        }
      }
    });

    return fields;
  }, [authorize, dealLeaderOptions, collection, isRejection, isSuggestion, data, selectedCollection]);

  const handleSubmit = (values) => {
    return new Promise((resolve, reject) => {
      const doSubmit = (autoTagsService) => {
        const data = fields.reduce((o, f) => {
          if (f.target !== 'state') {
            if (values.hasOwnProperty(f.name)) {
              o[f.name] = values[f.name];
            } else {
              o[f.name] = null;
            }
          }
          return o;
        }, {});

        if (autoTagsService) {
          const service = constants.data.lookup('services', constants.services.types.autotags);

          data.autotagPeriod = service?.period;
        }

        setDataEvent?.((current) => ({
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            ...data
          }
        }));

        resolve();
      }

      const tagGroup = data?.fieldData?.tagGroups?.find((tg) => +tg.groupId === +data?.question.analyserParams.tagGroup);
      const collectionRelations = utils.isDefined(collection) ? [constants.analyse.entityAnalyserActions.addToOtherCollection] :
        [constants.analyse.entityAnalyserActions.addToCollection, constants.analyse.entityAnalyserActions.addToOtherCollection];
      const testCollection = collectionRelations.includes(values['collectionAction']?.value ?? values['collectionAction']) ?
        selectedCollection : collection;

      const createAutoTags = tagGroup.autoTagType !== constants.collection.autoTagTypes.noAutoAssign &&
        utils.toArray(values['otherActions']).find((oa) => [constants.analyse.entityAnalyserActions.createTagGroups,
          constants.analyse.entityAnalyserActions.createTagGroup].includes(oa?.value ?? oa));

      const hasAutoTags = Boolean(client?.props?.autotagPeriod !== constants.services.periods.never) ||
        Boolean((testCollection?.autotagPeriod ?? constants.services.periods.never) !== constants.services.periods.never);

      if (!hasAutoTags && createAutoTags) {
        const handleConfirm = () => {
          doSubmit(true);
        }

        const handleClose = (e, reason) => {
          if (reason === 'cancelButtonClick') {
            doSubmit(false);
          } else if (reason !== 'confirmButtonClick') {
            reject(reason);
          }
          dialogControl.hide();
        }

        dialogControl.show(<ConfirmDialog question="You have selected to auto-assign tags. Do you wish to activate the auto tags service?"
                                          onConfirm={handleConfirm}
                                          CancelButtonProps={{
                                            children: 'No'
                                          }}
                                          ConfirmButtonProps={{
                                            children: 'Yes'
                                          }}/>, true, handleClose);
      } else {
        doSubmit(false);
      }
    })
  }

  return <StyledEntitiesAnalyseActionsWizardContent ref={ref} {...innerProps}
                                                    wizard={wizard}
                                                    step={step}
                                                    fieldData={tableProvider.fieldData}
                                                    fields={fields}
                                                    onSubmit={handleSubmit}/>
});

EntitiesAnalyseActionsWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  collection: PropTypes.object,
  tableProvider: PropTypes.object
};

EntitiesAnalyseActionsWizardContent.defaultProps = {};

export default EntitiesAnalyseActionsWizardContent;
