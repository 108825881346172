import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import MappingForm from 'components/organisms/Forms/MappingForm/MappingForm';
import StyledMappingsForm from 'components/organisms/Forms/MappingsForm/MappingsForm.styles';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';

const MappingsForm = React.forwardRef((props, ref) => {
  const {
    mappings,
    mappedFields,
    connection,
    fieldData,
    required,
    readOnly,
    disabled,
    error,
    multiple,
    onChange,
    onError,
    ...innerProps
  } = useComponentProps(props, 'MappingsForm');

  const innerRef = useRef(null);
  const mappingRefs = useRef({});

  const [internalState, setInternalState] = useState({
    mappings: []
  });

  const mappingsForm = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    submit: (touch = true) => {
      return Promise.all(Object.keys(mappingRefs.current).map((mappingId) => {
        return mappingRefs.current[mappingId]?.ref?.submit?.(touch);
      }));
    },
    validate: (touch = true) => {
      return Promise.all(Object.keys(mappingRefs.current).map((mappingId) => {
        return mappingRefs.current[mappingId]?.ref?.validate?.(touch);
      }))
        .then((errors) => {
          return errors.reduce((o, f) => ({...o, ...utils.cleanObject(f)}), {});
        });
    },
    reset: (state) => {
      return Promise.all(Object.keys(mappingRefs.current).map((mappingId) => {
        return mappingRefs.current[mappingId]?.ref?.reset?.(state);
      }));
    }
  }), []);

  useImperativeHandle(ref, () => mappingsForm);

  useLayoutEffect(() => {
    let minId = 0;

    const mps = (mappings ?? [])
      .map((s) => {
        if (!utils.isDefined(s.id)) {
          minId = minId - 1;
        } else if (+s.id < 0) {
          minId = Math.min(minId, +s.id);
        }

        return {
          ...s,
          id: !utils.isDefined(s.id) ? minId : +s.id
        }
      });

    if (!readOnly && mps.length === 0) {
      mps.push({id: minId - 1, synchroniseDeletedItems: true});
    }

    setInternalState(utils.updater({
      mappings: mps
    }, true));
  }, [mappings, readOnly, connection?.type]);

  const handleAddMapping = () => {
    setInternalState((current) => {
      const minId = current.mappings.reduce((i, m) => +m.id < 0 ? Math.min(i, +m.id) : i, 0);

      return {
        ...current,
        mappings: current.mappings.concat([{id: minId - 1, synchroniseDeletedItems: true}])
      }
    });
  }

  const doErrors = () => {
    const errorIds = Object.keys(mappingRefs.current).reduce((o, k) => {
      return mappingRefs.current[k].ref && mappingRefs.current[k].error ? {...o, [k]: true} : o;
    }, {});

    const error = !utils.isEmpty(errorIds);

    onError?.(error);
    setInternalState(utils.updater({ error, errorIds }, true));
  }

  const handleChange = (mapping) => (values) => {
    const idx = internalState.mappings.findIndex((m) => +m.id === +mapping.id);
    
    if (idx !== -1) {
      const newValue = [
        ...internalState.mappings.slice(0, idx),
        {...mapping, ...values},
        ...internalState.mappings.slice(idx + 1),
      ];

      setInternalState(utils.updater({mappings: newValue}, true));
      onChange?.(newValue);
    }
  }

  const handleDelete = (mapping) => () => {
    const reset = mappingRefs.current[mapping.id]?.ref?.reset;
    delete mappingRefs.current[mapping.id];
    utils.retry(() => {
      reset?.({touched: {}});
    }, 3);

    const newValue = internalState.mappings
      .filter((m) => +m.id !== +mapping.id);

    setInternalState(utils.updater({mappings: newValue}, true));
    onChange?.(newValue);
    doErrors();
  }

  const handleRef = (mapping) => (ref) => {
    mappingRefs.current[mapping.id] = {
      ...mappingRefs.current[mapping.id],
      ref
    };
  }

  const handleError = (mapping) => (error) => {
    mappingRefs.current[mapping.id] = {
      ...mappingRefs.current[mapping.id],
      error
    };

    doErrors();
  }

  const renderMapping = (mapping, only) => {
    const hasErrorFrom = internalState.mappings
      .filter((m) => !internalState.errorIds?.[m.id])
      .filter((ml) => !internalState.errorIds?.[mapping.id] && ml.teams?.[0]?.from?.id === mapping.teams?.[0]?.from?.id).length > 1;
    const hasErrorTo = internalState.mappings
      .filter((m) => !internalState.errorIds?.[m.id])
      .filter((ml) => !internalState.errorIds?.[mapping.id] && ml.teams?.[0]?.to?.id === mapping.teams?.[0]?.to?.id).length > 1;

    return <MappingForm ref={handleRef(mapping)}
                        key={mapping.id}
                        className="MappingsForm-mapping"
                        mapping={mapping}
                        only={only}
                        mappedFields={mappedFields}
                        fieldData={fieldData}
                        connection={connection}
                        error={error}
                        errorFrom={Boolean(hasErrorFrom)}
                        errorTo={Boolean(hasErrorTo)}
                        required={required || !only}
                        readOnly={readOnly}
                        disabled={disabled}
                        removable={true}
                        hideActions={!multiple}
                        onChange={handleChange(mapping)}
                        onDelete={handleDelete(mapping)}
                        onError={handleError(mapping)}/>
  }

  return <StyledMappingsForm ref={innerRef} {...innerProps}>
    <List gap={8} catchFocus={false}>
      {internalState.mappings?.map((mapping) => {
        return <ListItem key={mapping.id} data-key={mapping.id} density="densest">
          {renderMapping(mapping, internalState.mappings.length === 1)}
        </ListItem>
      })}
    </List>
    {(multiple && !(readOnly || disabled)) ?
      <Button variant="text"
              disabled={Boolean(error || internalState.error || internalState.mappings.find((m) => utils.isEmpty(m.teams)))}
              startIcon={<Icon icon={Add} />}
              onClick={handleAddMapping}>
        Add mapping
      </Button> : null}
  </StyledMappingsForm>
});

MappingsForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  mappings: PropTypes.array,
  mappedFields: PropTypes.array,
  connection: PropTypes.object,
  fieldData: PropTypes.object,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func
};

MappingsForm.defaultProps = {
  multiple: true
};

export default MappingsForm;
