import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntitiesAnalyseGraphDialogContent
  from 'components/organisms/Dialogs/EntitiesAnalyseGraphDialogContent/EntitiesAnalyseGraphDialogContent.styles';
import EntitiesAnalyseGraphWizard
  from 'components/organisms/Wizards/EntitiesAnalyseGraphWizard/EntitiesAnalyseGraphWizard';

const EntitiesAnalyseGraphDialogContent = React.forwardRef((props, ref) => {
  const {
    collection,
    tableProvider,
    dirty,
    onDirty,
    onClose,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseGraphDialogContent', {
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const wizardRef = useRef(null);

  const [internalState, ] = useState({});

  const entitiesAnalyseGraphDialogContent = useMemo(() => {
    const state = {
      ...internalState
    };

    return {
      refs: {
        ref: innerRef,
        wizardRef
      },
      state: state,
      cleanup: () => {
        /* no cleanup */
      }
    }
  }, [internalState]);

  const handleSubmit = (entityIds, actions) => {
    onClose?.(null, 'closeButtonClick');
    //
    // return utils.asPromise(onSubmit)(entityIds, actions)
    //   .then(() => {
    //     onClose?.(null, 'saveButtonClick');
    //   });
  }

  useImperativeHandle(ref, () => entitiesAnalyseGraphDialogContent);

  return <StyledEntitiesAnalyseGraphDialogContent ref={innerRef} {...innerProps}>
    <EntitiesAnalyseGraphWizard ref={wizardRef}
                                className="EntitiesAnalyseGraphDialogContent-wizard"
                                collection={collection}
                                tableProvider={tableProvider}
                                showButtons={true}
                                showProgressBar={true}
                                onDirty={onDirty}
                                onSubmit={handleSubmit}/>
  </StyledEntitiesAnalyseGraphDialogContent>
});

EntitiesAnalyseGraphDialogContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  dirty: PropTypes.bool,
  onDirty: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

EntitiesAnalyseGraphDialogContent.defaultProps = {
};

export default EntitiesAnalyseGraphDialogContent;
