import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DialogContent from '@mui/material/DialogContent';

const StyledEntitiesAnalyseGraphDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  
  ${ComponentStyles}
`;

StyledEntitiesAnalyseGraphDialogContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseGraphDialogContent.defaultProps = {}

export default StyledEntitiesAnalyseGraphDialogContent;
