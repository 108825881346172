import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Wizard from 'components/organisms/Wizards/Wizard/Wizard';

const StyledEntitiesAnalyseTagWizard = styled(Wizard)`
  .Wizard {
    &-content {
      padding: 0 !important;
    }
    &-step {
      &-content {
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
        
        &-categories {
          .TagGroupCard {
            min-height: ${props => props.theme.layout('15sp')};
            max-height: ${props => props.theme.layout('15sp')};
          }
        }
      }
    }
    .ToolbarWrapper-toolbar {
      padding-right: 0;
    }
  }
  
  &.EntitiesAnalyseTagWizard {
    .ToolbarWrapper-toolbar {
      padding-right: 0 !important;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitiesAnalyseTagWizard.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseTagWizard.defaultProps = {}

export default StyledEntitiesAnalyseTagWizard;
