import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledSplitBar from 'components/organisms/Bars/SplitBar/SplitBar.styles';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const SplitBar = React.forwardRef((props, ref) => {
  const {
    actions,
    context,
    reverse,
    ...innerProps
  } = useComponentProps(props, 'SplitBar', {
    static: ['reverse']
  });

  const renderActions = () => {
    if (actions?.length > 0) {
      return <Box className="SplitBar-actions">
        {actions?.map((action, idx) => {
          return <ActionButton key={idx}
                               action={action}/>
        })}
      </Box>
    }
  }

  const renderContext = () => {
    if (context) {
      return <Box className="SplitBar-context">
        {context}
      </Box>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSplitBar ref={ref} {...innerProps}>
    {!reverse ? renderActions() : renderContext()}
    {!reverse ? renderContext() : renderActions()}
  </StyledSplitBar>
});

SplitBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  actions: PropTypes.array,
  context: PropTypes.any,
  reverse: PropTypes.boolean
};

SplitBar.defaultProps = {
  elevation: 2
};

export default SplitBar;
