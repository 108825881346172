import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntitiesAnalyseTagDialogContent
  from 'components/organisms/Dialogs/EntitiesAnalyseTagDialogContent/EntitiesAnalyseTagDialogContent.styles';
import utils from 'helpers/utils';
import EntitiesAnalyseTagWizard from 'components/organisms/Wizards/EntitiesAnalyseTagWizard/EntitiesAnalyseTagWizard';

const EntitiesAnalyseTagDialogContent = React.forwardRef((props, ref) => {
  const {
    collection,
    tableProvider,
    dirty,
    onDirty,
    onClose,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseTagDialogContent', {
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const wizardRef = useRef(null);

  const [internalState, ] = useState({});

  const entitiesAnalyseTagDialogContent = useMemo(() => {
    const state = {
      ...internalState
    };

    return {
      refs: {
        ref: innerRef,
        wizardRef
      },
      state: state,
      cleanup: () => {
        /* no cleanup */
      }
    }
  }, [internalState]);

  const handleSubmit = (entityIds, actions) => {
    return utils.asPromise(onSubmit)(entityIds, actions)
      .then(() => {
        onClose?.(null, 'saveButtonClick');
      });
  }

  useImperativeHandle(ref, () => entitiesAnalyseTagDialogContent);

  return <StyledEntitiesAnalyseTagDialogContent ref={innerRef} {...innerProps}>
    <EntitiesAnalyseTagWizard ref={wizardRef}
                              className="EntitiesAnalyseTagDialogContent-wizard"
                              collection={collection}
                              tableProvider={tableProvider}
                              showButtons={true}
                              showProgressBar={true}
                              onDirty={onDirty}
                              onSubmit={handleSubmit}/>
  </StyledEntitiesAnalyseTagDialogContent>
});

EntitiesAnalyseTagDialogContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  dirty: PropTypes.bool,
  onDirty: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

EntitiesAnalyseTagDialogContent.defaultProps = {
};

export default EntitiesAnalyseTagDialogContent;
