import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledLinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress.styles';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const LinearProgress = React.forwardRef((props, ref) => {
  const {
    isLoading,
    fakeLoading,
    ...innerProps
  } = useComponentProps(props, 'LinearProgress');

  const [value, setValue] = useState(innerProps.value);

  useEffect(() => {
    if (fakeLoading) {
      return utils.observeInterval(() => {
        setValue((current) => Math.min(100, (current ?? 0) + 1));
      }, utils.isInt(fakeLoading) ? fakeLoading : constants.debounce.minimal)
    }
  }, [fakeLoading]);

  innerProps.as = isLoading ? Skeleton : innerProps.as;

  return <StyledLinearProgress ref={ref} {...innerProps}
                               value={(fakeLoading ? value : innerProps.value) ?? 0}>
    {innerProps.children}
  </StyledLinearProgress>
});

LinearProgress.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  isLoading: PropTypes.bool,
  fakeLoading: PropTypes.any
};

LinearProgress.defaultProps = {
};

export default LinearProgress;
