import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Card from 'components/atoms/Cards/Card/Card';
import Button from 'components/atoms/Buttons/Button/Button';
import Add from '@mui/icons-material/Add';
import StyledCollectionFieldProfileCardContent
  from 'components/organisms/Cards/CollectionFieldProfileCardContent/CollectionFieldProfileCardContent.styles';
import {useCollectionCustomFieldGroups} from 'services/collection/customField/customField.utils';
import FieldCard from 'components/organisms/Cards/FieldCard/FieldCard';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';

const CollectionFieldProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    field,
    collection,
    isDialog,
    fieldData,
    onValidating,
    onSubmit,
    onPatch,
    renderedLoader,
    ...innerProps
  } = useComponentProps(props, 'CollectionFieldProfileCardContent', {
    static: ['isAddNew', 'isEditing', 'isDeleted']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);

  // ProfileCardContent handles form state
  useImperativeHandle(ref, () => formRef.current);

  const client = useAuthClient();

  const isAddNew = !utils.isDefined(field?.fieldId);
  const isEditing = content.state.isEditing;
  const isLoading = !utils.isDefined(field?.name) || !utils.isDefined(field?.type);

  const hasAutoLookup = Boolean(client?.props?.autoLookupPeriod !== constants.services.periods.never) || Boolean(collection?.autoLookupPeriod !== constants.services.periods.never);

  const dialogControl = useDialogControl();

  const groups = useCollectionCustomFieldGroups(collection?.collectionId);

  const fields = useMemo(() => {
    return [
      {
        name: 'label',
        label: 'Label',
        inlineLabel: 'label',
        type: constants.formFieldTypes.text,
        validation: constants.formFieldValidationTypes.text,
        initial: field?.label ?? '',
        required: true,
        FormFieldProps: {
          variant: 'inlineLabel'
        },
      },
      {
        name: 'groupName',
        label: 'Group',
        inlineLabel: 'group',
        type: constants.formFieldTypes.autocomplete,
        validation: constants.formFieldValidationTypes.text,
        conversion: constants.formFieldConversionTypes.label,
        initial: field?.groupName ?? '',
        required: true,
        options: groups,
        FormFieldProps: {
          variant: 'inlineLabel',
          createOption: true
        }
      },
      {
        name: 'renderer',
        label: 'Type',
        inlineLabel: 'type',
        type: constants.formFieldTypes.autocomplete,
        validation: constants.formFieldValidationTypes.text,
        conversion: constants.formFieldConversionTypes.value,
        initial: field?.renderer ?? '',
        required: true,
        readOnly: !isAddNew,
        options: 'customFieldRendererTypes',
        FormFieldProps: {
          variant: 'inlineLabel'
        }
      },
      {
        name: 'tooltip',
        label: 'Description',
        inlineLabel: 'description',
        placeholder: 'Describe the field',
        type: constants.formFieldTypes.markdown,
        validation: constants.formFieldValidationTypes.text,
        initial: field?.tooltip ?? '',
        FormFieldProps: {
          variant: 'inlineLabel'
        }
      },
      {
        name: 'autoLookup',
        label: 'Auto lookup',
        inlineLabel: 'auto lookup',
        type: constants.formFieldTypes.list,
        validation: constants.formFieldValidationTypes.boolean,
        conversion: constants.formFieldConversionTypes.value,
        options: 'toggleYesNoReverse',
        initial: field?.autoLookup ?? false,
        FormFieldProps: {
          variant: 'inlineLabel',
          ListProps: {
            catchFocus: false,
            orientation: 'horizontal',
            gap: 16
          }
        }
      },
      {
        name: 'autoLookupDescription',
        label: 'Lookup description',
        inlineLabel: 'lookup description',
        placeholder: 'Describe the field value',
        type: constants.formFieldTypes.markdown,
        validation: constants.formFieldValidationTypes.text,
        initial: field?.autoLookupDescription ?? '',
        relation: 'autoLookup(true)',
        FormFieldProps: {
          variant: 'inlineLabel'
        }
      },
    ].filter((f) => isAddNew || f.name !== 'renderer')
  }, [groups, field, isAddNew]);

  const handleSubmit = (values, actions) => {
    const doSubmit = () => {
      const name = `customField-${field?.fieldId ?? 0}`;
      const fields = [{
        name
      }];

      const changes = isAddNew ? {
        [name]: {...values}
      } : {
        [name]: {original: field, ...field, fieldId: field?.fieldId, ...values},
      };

      return onSubmit(changes, actions, fields, changes[name], true);
    }

    if (!hasAutoLookup && values['autoLookup'] === true) {
      const handleConfirm = () => {
        const service = constants.data.lookup('services', constants.services.types.autoLookup);

        return utils.asPromiseCallback(onPatch)({
          name: 'autoLookupPeriod'
        }, service?.period)
          .then(() => {
            doSubmit();
          });
      }

      const handleClose = (e, reason) => {
        if (reason === 'cancelButtonClick') {
          doSubmit();
        } else if (reason !== 'confirmButtonClick') {
          actions.setSubmitting(false);
        }
        dialogControl.hide();
      }

      dialogControl.show(<ConfirmDialog question="You have selected to auto lookup. Do you wish to activate the auto lookup service?"
                                        onConfirm={handleConfirm}
                                        CancelButtonProps={{
                                          children: 'No'
                                        }}
                                        ConfirmButtonProps={{
                                          children: 'Yes'
                                        }}/>, true, handleClose);
    } else {
      doSubmit();
    }
  }

  const handleValidating = (validating, dirty, errors) => {
    onValidating?.(validating, isAddNew || dirty, errors);
  }

  const onValidatingEvent = useEffectEvent(onValidating);
  useEffect(() => {
    if (isAddNew && isEditing) {
      onValidatingEvent?.(false, true, false);
    }
  }, [isAddNew, isEditing, onValidatingEvent]);

  const renderReadOnly = () => {
    return <FieldCard field={field}
                      groups={groups}
                      hasAutoLookup={hasAutoLookup}
                      isLoading={isLoading} />
  };

  const renderForm = () => {
    return <InlineForm ref={formRef}
                       className="CollectionFieldProfileCardContent-form"
                       fields={fields}
                       fieldData={fieldData}
                       onSubmit={handleSubmit}
                       onValidating={handleValidating}/>
  };

  const renderNew = () => {
    return <Card className="CollectionFieldProfileCardContent-new" fullWidth={true} fullHeight={true}>
      <Button variant="contained"
              startIcon={<Icon icon={Add} />}
              onClick={() => {
                card.edit()
              }}>
        Add field
      </Button>
    </Card>
  };

  delete innerProps.source;
  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isEditing: isEditing
  });

  return <StyledCollectionFieldProfileCardContent ref={innerRef} {...innerProps}>
    <Box className="CollectionFieldProfileCardContent-content">
      {!isDialog ? (isAddNew ? renderNew() : renderReadOnly()) : renderForm()}
    </Box>
  </StyledCollectionFieldProfileCardContent>
});

CollectionFieldProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  collection: PropTypes.object,
  isDialog: PropTypes.bool,
  fieldData: PropTypes.object,
  onValidating: PropTypes.func,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func,
};

CollectionFieldProfileCardContent.defaultProps = {
};

export default CollectionFieldProfileCardContent;


