import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUpdatedState} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Delete from '@mui/icons-material/Delete';
import IconField from 'components/molecules/Fields/IconField/IconField';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Icon from 'components/atoms/Icons/Icon/Icon';
import StyledTeamMappingForm from 'components/organisms/Forms/TeamMappingForm/TeamMappingForm.styles';

const TeamMappingForm = React.forwardRef((props, ref) => {
  const {
    team,
    only,
    connection,
    fieldData,
    required,
    readOnly,
    disabled,
    removable,
    hideActions,
    error,
    errorFrom,
    errorTo,
    onChange,
    onError,
    onDelete,
    ...innerProps
  } = useComponentProps(props, 'TeamMappingForm');

  const formRef = useRef(null);
  const innerRef = useRef(null);

  const teamMappingForm = useMemo(() => ({
    refs: {
      ref: innerRef,
      formRef
    },
    submit: (touch = true) => {
      return formRef.current?.submit(touch);
    },
    validate: (touch = true) => {
      return formRef.current?.validate(touch);
    },
    reset: (state) => {
      setIsDirty(false);
      setInternalState({});
      return formRef.current?.reset(state);
    }
  }), []);

  useImperativeHandle(ref, () => teamMappingForm);

  const [isDirty, setIsDirty] = useState(false);
  const [internalState, setInternalState] = useState(team);
  const [internalValue] = useUpdatedState(team);

  const isNew = !utils.isDefined(internalValue.from?.id) || !utils.isDefined(internalValue.to?.id);

  useEffect(() => {
    if (required && error) {
      return utils.observeTimeout(() => {
        try {
          formRef.current?.validate(true);
        } catch (e) {
          /* SQUASH */
        }
      }, constants.debounce.minimal)
    }
  }, [required, error]);

  const fields = useMemo(() => {
    const fields = [];

    const connectionDef = constants.data.lookup('connections', connection?.type);

    fields.push({
      name: 'from',
      label: 'Catalist team',
      placeholder: 'Type a Catalist team name',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.team,
      formGroup: 'header',
      context: {},
      options: 'internalTeams',
      initial: internalValue.from,
      readOnly: readOnly,
      required: required || utils.isDefined(internalState.to?.id) || isDirty,
      disabled: disabled,
      FormFieldProps: {
        error: errorFrom ? 'Duplicate team' : null,
        variant: 'inlineLabel',
        size: 'smaller',
        hiddenLabel: true
      }
    });

    if (connection.hasTeams) {
      fields.push({
        name: 'direction',
        label: 'Direction',
        formGroup: 'header',
        type: constants.formFieldTypes.popper,
        validation: constants.formFieldValidationTypes.text,
        conversion: constants.formFieldConversionTypes.value,
        initial: internalValue?.direction ? internalValue?.direction :
          constants.data.mappingDirections.filter((md) => md.connections.find((c) => c === connection?.type))[0]?.value,
        readOnly: readOnly,
        required: true,
        disabled: disabled || true,
        FormFieldProps: {
          hiddenLabel: true,
          variant: 'inlineLabel',
          size: 'smallest',
          fullWidth: true,
          FormFieldComponent: IconField,
          FormFieldProps: {
            iconSet: constants.data.mappingDirections.filter((md) => md.connections.find((c) => c === connection?.type)),
            orientation: 'vertical',
            columns: 1,
            rows: 3,
            minWidth: 0,
            IconButtonProps: {
              variant: 'outlined',
              size: 'smaller',
              density: 'normal',
              color: 'text.primary'
            },
            IconProps: {
              size: 'smaller',
              color: 'text.primary'
            }
          },
          PopperProps: {
            ContextPopperProps: {
              density: 'dense',
              size: 'medium',
              placement: 'bottom',
              width: 50
            }
          },
          renderButton: (value, {ref, className, readOnly, disabled, onClick}) => {
            const directionDef = constants.data.lookup('mappingDirections', (value?.value ?? value));
            if (readOnly) {
              return <Icon size="smaller"
                           icon={directionDef?.icon}
                           color="secondary"
                           showTooltip={false}/>
            } else {
              return <IconButton ref={ref}
                                 className={className}
                                 variant="outlined"
                                 size="smaller"
                                 density="sparse"
                                 color="secondary"
                                 disabled={Boolean(disabled || readOnly)}
                                 onClick={onClick}>
                <Icon size="smaller"
                      icon={directionDef?.icon}
                      showTooltip={false}/>
              </IconButton>
            }
          }
        }
      });

      fields.push({
        name: 'to',
        label: `${connectionDef.label} team`,
        placeholder: `Type ${connectionDef.vowelLabel ? 'an' : 'a'} ${connectionDef.label} team name`,
        type: constants.formFieldTypes.autocomplete,
        validation: constants.formFieldValidationTypes.team,
        formGroup: 'header',
        context: {
          type: connection?.type,
          key: connection?.key
        },
        options: 'externalTeams',
        initial: internalValue.to,
        readOnly: readOnly,
        required: required || utils.isDefined(internalState.to?.id),
        disabled: disabled,
        FormFieldProps: {
          error: errorTo ? 'Duplicate team' : null,
          variant: 'inlineLabel',
          size: 'smaller',
          hiddenLabel: true
        }
      });
    }

    return fields;
  }, [internalValue, internalState, connection, errorFrom, errorTo, readOnly, disabled, required, isDirty]);

  const handleSubmit = (values, actions) => {
    utils.asPromise(onChange)({...values})
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  const handleChange = () => {
    formRef.current?.submit(false);
  }

  const handleError = (field, value) => {
    utils.asPromise(onChange)({...internalValue, [field.name]: value});
  }

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    if (field) {
      if (field.name === 'from') {
        setInternalState((current) => {
          if (current.from?.id !== value?.id) {
            return {
              ...current,
              from: value
            }
          } else {
            return current;
          }
        });
      } else if (field.name === 'to') {
        setInternalState((current) => {
          if (current.to?.id !== value?.id) {
            return {
              ...current,
              to: value
            }
          } else {
            return current;
          }
        });
      }
    }
  }

  const handleValidating = (validating, dirty, errors) => {
    setIsDirty((current) => current || dirty || errors);
    onError?.(errors);
  }

  const onDeleteEvent = useEffectEvent(onDelete);
  const deleteAction = useMemo(() => ({
    label: 'Remove team',
    tooltip: 'Remove team',
    icon: Delete,
    ActionIconButtonProps: {
      IconProps: {
        size: 'smaller'
      }
    },
    IconButtonProps: {
      disabled: (disabled || readOnly || !removable || (only && isNew && !isDirty))
    },
    onClick: (e) => {
      onDeleteEvent?.(e);
    }
  }), [disabled, readOnly, removable, only, isDirty, isNew, onDeleteEvent]);

  const renderActions = () => {
    return <Box className="TeamMappingForm-actions">
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="contained"
                        color="error"
                        action={deleteAction}/>
    </Box>
  }

  const renderFormContent = (groups) => {
    const header = groups.find((g) => g.name === 'header');
    const body = groups.find((g) => g.name === 'body');

    return <React.Fragment>
      <Box className="TeamMappingForm-header">
        <Box className="Form-fields">
          {header.rendered}
        </Box>
        {!hideActions ? renderActions() : null}
      </Box>
      {body ? <Box className="TeamMappingForm-body">
        <Box className="Form-fields">
          {body.rendered}
        </Box>
      </Box> : null}
    </React.Fragment>
  }

  return <StyledTeamMappingForm ref={innerRef} {...innerProps}>
    <InlineForm ref={formRef}
                className="TeamMappingForm-form"
                fieldData={fieldData}
                autoFocus={isNew && !only}
                onSubmit={handleSubmit}
                onChange={handleChange}
                onError={handleError}
                onChangeDirect={handleChangeDirect}
                onValidating={handleValidating}
                renderContent={renderFormContent}
                fields={fields}/>
  </StyledTeamMappingForm>
});

TeamMappingForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  team: PropTypes.object,
  only: PropTypes.bool,
  connection: PropTypes.object,
  fieldData: PropTypes.object,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  removable: PropTypes.bool,
  hideActions: PropTypes.bool,
  error: PropTypes.bool,
  errorFrom: PropTypes.bool,
  errorTo: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onDelete: PropTypes.func
};

TeamMappingForm.defaultProps = {
  elevation: 1,
  radius: 'round'
};

export default TeamMappingForm;
