import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import StyledEntitiesAnalyseGraphWizard
  from 'components/organisms/Wizards/EntitiesAnalyseGraphWizard/EntitiesAnalyseGraphWizard.styles';
import EntitiesAnalyseGraphWizardContent
  from 'components/organisms/WizardContent/EntitiesAnalyseGraphWizardContent/EntitiesAnalyseGraphWizardContent';
import Close from '@mui/icons-material/Close';

const EntitiesAnalyseGraphWizard = React.forwardRef((props, ref) => {
  const {
    collection,
    tableProvider,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseWizard');

  const snackbar = useSnackbar();

  const steps = useMemo(() => {
    return [
      {
        name: 'description',
        label: 'Description',
        resetNextSteps: true,
        enableStep: () => {
          return true;
        },
        fields: [
          {
            name: 'description',
            label: 'Graph description',
            placeholder: 'Type a description of a graph',
            entity: 'analyser',
            type: constants.formFieldTypes.textarea,
            validation: constants.formFieldValidationTypes.text,
            required: true,
            FormFieldProps: {
              variant: 'staticLabel'
            },
          },
          {
            name: 'graphType',
            label: 'How do you want to analyse the companies?',
            entity: 'analyser',
            type: constants.formFieldTypes.list,
            validation: constants.formFieldValidationTypes.text,
            conversion: constants.formFieldConversionTypes.value,
            options: 'entityAnalyserGraphTypes',
            required: true,
            FormFieldProps: {
              multiple: false,
              ListProps: {
                catchFocus: false
              }
            }
          }
        ]
      },
      {
        name: 'graph',
        shortLabel: 'Graph',
        enableStep: () => {
          return true;
        },
        Content: (props) => <EntitiesAnalyseGraphWizardContent ref={props.ref}
                                                               className={props.className}
                                                               wizard={props.wizard}
                                                               step={props.step}
                                                               tableProvider={tableProvider}
                                                               collection={collection}
                                                               onComplete={props.onComplete}
                                                               onError={props.onError}
                                                               onDirty={props.onDirty}/>
      }
    ];
  }, [collection, tableProvider]);

  const handleSubmit = (data, actions, onSuccess, onError) => {
    data = data.entitiesAnalyse;

    return utils.asPromise(onSubmit)(data)
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.();
        snackbar.show(`Saving the graph failed`, null, {color: 'error', autoHideDuration: constants.delay.error});
      });
  }

  const initialData = useMemo(() => ({
    'entitiesGraph': {
      graphType: constants.analyse.entityAnalyserGraphTypes.entityAnalyserMiniGraph
    }
  }), []);

  const saveActionMemo = useMemo(() => {
    return {
      label: 'Close',
      icon: Close,
      iconPosition: 'start',
      ButtonProps: {
        color: 'primary'
      }
    }
  }, []);

  return <StyledEntitiesAnalyseGraphWizard ref={ref} {...innerProps}
                                           steps={steps}
                                           dataKey="entitiesGraph"
                                           initialData={initialData}
                                           onSubmit={handleSubmit}
                                           saveAction={saveActionMemo} />
});

EntitiesAnalyseGraphWizard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  onSubmit: PropTypes.func
};

EntitiesAnalyseGraphWizard.defaultProps = {};

export default EntitiesAnalyseGraphWizard;
