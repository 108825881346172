import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUpdatedState} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import {DragHandleContext, useDragHandle} from 'components/organisms/Utils/DragDrop/DndContext';
import Box from 'components/atoms/Layout/Box/Box';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import {DragIndicator} from '@mui/icons-material';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Delete from '@mui/icons-material/Delete';
import StyledStatusForm from 'components/organisms/Forms/StatusForm/StatusForm.styles';

const StatusForm = React.forwardRef((props, ref) => {
  const {
    status,
    only,
    required,
    readOnly,
    disabled,
    removable,
    hideActions,
    error,
    errorDup,
    onChange,
    onError,
    onDelete,
    ...innerProps
  } = useComponentProps(props, 'StatusForm');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const statusForm = useMemo(() => ({
    refs: {
      ref: innerRef,
      formRef
    },
    reset: (state) => {
      formRef.current?.reset(state);
      setIsDirty(false);
    }
  }), []);

  useImperativeHandle(ref, () => statusForm);

  const [isDirty, setIsDirty] = useState(false);
  const [internalValue] = useUpdatedState(status);

  const isNew = +internalValue?.statusId < 0;

  const dragHandleContext = useDragHandle(DragHandleContext);

  useEffect(() => {
    if (required && error) {
      return utils.observeTimeout(() => {
        try {
          formRef.current?.validate(true);
        } catch (e) {
          /* SQUASH */
        }
      }, constants.debounce.minimal)
    }
  }, [required, error]);

  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'name',
      label: 'Name',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.status,
      entity: 'statusGroup',
      formGroup: 'header',
      initial: internalValue.name,
      readOnly: readOnly,
      required: required || isDirty,
      disabled: disabled,
      FormFieldProps: {
        error: errorDup ? 'Duplicate name' : null,
        variant: 'inlineLabel',
        size: 'smaller',
        hiddenLabel: true
      }
    });

    return fields;
  }, [internalValue, errorDup, readOnly, disabled, required, isDirty]);

  const handleSubmit = (values, actions) => {
    utils.asPromise(onChange)({...values})
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  const handleChange = () => {
    formRef.current?.submit(false);
  }

  const handleError = (field, value) => {
    utils.asPromise(onChange)({...internalValue, [field.name]: value});
  }

  const handleValidating = (validating, dirty, errors) => {
    setIsDirty((current) => current || dirty || errors);
    onError?.(errors);
  }

  const dragAction = useMemo(() => ({
    icon: <Icon icon={DragIndicator} size="smaller" />,
    tooltip: 'Move status',
    TooltipProps: dragHandleContext?.isDragging ? {
      open: false
    } : null,
    IconButtonProps: {
      ...utils.filterObject(dragHandleContext, 'isDragging'),
      disabled: (disabled || readOnly || !removable),
      onPointerDown: (e) => {
        if (disabled || readOnly || !removable) {
          e.preventDefault();
        } else {
          dragHandleContext?.onPointerDown?.(e);
        }
      }
    },
    onClick: () => null
  }), [disabled, readOnly, removable, dragHandleContext]);

  const onDeleteEvent = useEffectEvent(onDelete);
  const deleteAction = useMemo(() => ({
    label: 'Remove status',
    tooltip: 'Remove status',
    icon: Delete,
    ActionIconButtonProps: {
      IconProps: {
        size: 'smaller'
      }
    },
    IconButtonProps: {
      disabled: (disabled || readOnly || !removable || (only && isNew && !isDirty))
    },
    onClick: (e) => {
      onDeleteEvent?.(e);
    }
  }), [disabled, readOnly, removable, only, isDirty, isNew, onDeleteEvent]);

  const renderActions = () => {
    return <Box className="StatusForm-actions">
      {dragHandleContext ? <ActionIconButton action={dragAction}
                                             disableRipple={true}
                                             size="smaller"
                                             density="sparse"
                                             variant="outlined"/> : null}
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="contained"
                        color="error"
                        action={deleteAction}/>
    </Box>
  }

  const renderFormContent = (groups) => {
    const header = groups.find((g) => g.name === 'header');
    const body = groups.find((g) => g.name === 'body');

    return <React.Fragment>
      <Box className="StatusForm-header">
        <Box className="Form-fields">
          {header.rendered}
        </Box>
        {!hideActions ? renderActions() : null}
      </Box>
      {body ? <Box className="StatusForm-body">
        <Box className="Form-fields">
          {body.rendered}
        </Box>
      </Box> : null}
    </React.Fragment>
  }

  return <StyledStatusForm ref={innerRef} {...innerProps}>
    <InlineForm ref={formRef}
                className="StatusForm-form"
                autoFocus={internalValue.statusId < 0 && !only}
                onSubmit={handleSubmit}
                onChange={handleChange}
                onError={handleError}
                onValidating={handleValidating}
                renderContent={renderFormContent}
                fields={fields}/>
  </StyledStatusForm>
});

StatusForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  status: PropTypes.object,
  only: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  removable: PropTypes.bool,
  hideActions: PropTypes.bool,
  error: PropTypes.bool,
  errorDup: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onDelete: PropTypes.func
};

StatusForm.defaultProps = {
  elevation: 1,
  radius: 'round'
};

export default StatusForm;
