import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSimpleQuery = styled(Box)`
  display: flex;
  align-items: center;
  
  .SimpleQuery {
    &-form {
      .FormControl {
        flex-grow: 1;

        .InputContainer {
          .MuiInputBase-root {
            background-color: ${props => props.theme.property('palette.background.paper')};
          }
        }
        
        &.TextField {
          .SimpleQuery-adornment {
            display: flex;
            align-items: center;
            gap: ${props => props.theme.layout('1sp')};
            
            .Button {
              margin-top: -${props => props.theme.layout(0.5)};
              margin-right: -${props => props.theme.layout(5.5)};
              
              padding-left: ${props => props.theme.layout(7)} !important;
              padding-right: ${props => props.theme.layout(7)} !important;
              padding-top: ${props => props.theme.layout(2)} !important;
              padding-bottom: ${props => props.theme.layout(2)} !important;
            }
          }
          
          &:not(.TextField-multiline) {
            .SimpleQuery-adornment {
              .Button {
                color: ${props => props.theme.property('palette.action.active')};
                
                .Icon {
                  color: ${props => props.theme.property('palette.action.active')};
                }
              }
            }
          }
          
          &-multiline {
            .SimpleQuery-adornment {
              .Button {
                margin-top: -${props => props.theme.layout(2.5)};
                margin-bottom: -${props => props.theme.layout(2.5)};
                padding-left: ${props => props.theme.layout(8)} !important;
                padding-right: ${props => props.theme.layout(8)} !important;

                box-shadow: unset !important;
              }
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSimpleQuery.propTypes = {
  theme: PropTypes.object
};

StyledSimpleQuery.defaultProps = {}

export default StyledSimpleQuery;
