import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DialogContent from '@mui/material/DialogContent';

const StyledEntitiesAnalyseTagDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  
  ${ComponentStyles}
`;

StyledEntitiesAnalyseTagDialogContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseTagDialogContent.defaultProps = {}

export default StyledEntitiesAnalyseTagDialogContent;
