import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import StyledEntitiesAnalyseTagWizard
  from 'components/organisms/Wizards/EntitiesAnalyseTagWizard/EntitiesAnalyseTagWizard.styles';
import ChooseCollectionCategoriesWizardContent
  from 'components/organisms/WizardContent/ChooseCollectionCategoriesWizardContent/ChooseCollectionCategoriesWizardContent';
import EntitiesAnalyseTagWizardContent
  from 'components/organisms/WizardContent/EntitiesAnalyseTagWizardContent/EntitiesAnalyseTagWizardContent';
import Add from '@mui/icons-material/Add';
import ResolveCollectionCategoriesWizardContent
  from 'components/organisms/WizardContent/ResolveCollectionCategoriesWizardContent/ResolveCollectionCategoriesWizardContent';

const EntitiesAnalyseTagWizard = React.forwardRef((props, ref) => {
  const {
    collection,
    tableProvider,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseTagWizard');

  const snackbar = useSnackbar();

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const steps = useMemo(() => {
    return [
      {
        name: 'taxonomy',
        label: 'Taxonomy',
        Content: (props) => {
          return <ChooseCollectionCategoriesWizardContent ref={props.ref}
                                                          className={props.className}
                                                          wizard={props.wizard}
                                                          step={props.step}
                                                          collection={collection}
                                                          maxTagGroups={3}
                                                          showAutoTagType={true}
                                                          onComplete={props.onComplete}
                                                          onError={props.onError}
                                                          onDirty={props.onDirty}/>
        }
      },
      {
        name: 'tags',
        shortLabel: 'Tagging',
        enableStep: () => {
          return true;
        },
        Content: (props) => <EntitiesAnalyseTagWizardContent ref={props.ref}
                                                             className={props.className}
                                                             wizard={props.wizard}
                                                             step={props.step}
                                                             tableProvider={tableProvider}
                                                             collection={collection}
                                                             onComplete={props.onComplete}
                                                             onError={props.onError}
                                                             onDirty={props.onDirty}/>
      },
      {
        name: 'categories',
        shortLabel: 'Categories',
        enableStep: () => {
          return true;
        },
        Content: (props) => {
          return <ResolveCollectionCategoriesWizardContent ref={props.ref}
                                                           className={props.className}
                                                           wizard={props.wizard}
                                                           step={props.step}
                                                           collection={collection}
                                                           onComplete={props.onComplete}
                                                           onError={props.onError}
                                                           onDirty={props.onDirty}/>
        }
      }
    ];
  }, [collection, tableProvider]);

  const handleSubmit = (data, actions, onSuccess, onError) => {
    data = data.entitiesTag;

    const entities = (data?.entityIds ?? [])
      .slice(0, ((data?.[data?.questionsHash]?.batch + 1) * constants.analyse.batchSizes.match))
      .filter((entityId) => data?.entities?.[entityId]?.questionsHash === data?.questionsHash)
      .filter((entityId) => utils.isDefined(data?.entities?.[entityId]?.evidenceTime))
      .map((entityId) => data?.entities?.[entityId]);

    const tagGroups = data?.fieldData?.tagGroups;

    const entityActions = [constants.analyse.entityAnalyserActions.applyToExisting,
      constants.analyse.entityAnalyserActions.createTagGroups]
      .map((ea) => {
        let actionCollection = null;

        if (collection) {
          actionCollection = {
            name: collection.name,
            collectionId: collection.collectionId,
            autotagPeriod: data.autotagPeriod
          }
        }

        let actionTagGroups, actionEvidences;
        if ([constants.analyse.entityAnalyserActions.createTagGroups,
          constants.analyse.entityAnalyserActions.createTagGroup].includes(ea?.value ?? ea)) {
          actionTagGroups = tagGroups.map((tagGroup) => ({
            ...tagGroup,
            tags: tagGroup.tags.map((tag) => {
              return {
                ...tag,
                entityIds: entities
                  .filter((entity) => {
                    return (entity.tagGroups ?? []).find((tg) => {
                      return +tg.groupId === +tagGroup.groupId &&
                        (tg.tags ?? []).find((t) => +t.tagId === +tag.tagId)
                    });
                  })
                  .map((entity) => entity.entityId)
              };
            })
          }));

          actionEvidences = entities
            .reduce((o, entity) => {
              const evidences = data?.[data?.questionsHash]?.evidences?.[entity.entityId];
              if (evidences?.evidences?.length > 0) {
                o[entity.entityId] = evidences?.evidences;
              }
              return o;
            }, {});
        }

        return {
          action: (ea?.value ?? ea),
          collection: actionCollection,
          evidences: actionEvidences,
          tagGroups: actionTagGroups
        }
      });

    const entityIds = entities.map((entity) => entity.entityId);

    return utils.asPromise(onSubmit)(entityIds, entityActions, 'Creating the categories', 'Then categories have been created')
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.()
        snackbar.show('Creating the categories failed', null, {color: 'error', autoHideDuration: constants.delay.error});
      });
  }

  const initialData = useMemo(() => ({
    'entitiesTag': {
      autoTagType: constants.collection.autoTagTypes.byAI
    }
  }), []);

  const saveActionMemo = useMemo(() => {
    return {
      label: mdDown ? 'Save' : 'Create categories',
      icon: Add,
      iconPosition: 'start',
      ButtonProps: {
        color: 'success'
      }
    }
  }, [mdDown]);

  return <StyledEntitiesAnalyseTagWizard ref={ref} {...innerProps}
                                         steps={steps}
                                         dataKey="entitiesTag"
                                         initialData={initialData}
                                         onSubmit={handleSubmit}
                                         saveAction={saveActionMemo} />
});

EntitiesAnalyseTagWizard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  onSubmit: PropTypes.func
};

EntitiesAnalyseTagWizard.defaultProps = {};

export default EntitiesAnalyseTagWizard;
