import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledQuestionnaireContextCard
  from 'components/organisms/Cards/QuestionnaireContextCard/QuestionnaireContextCard.styles';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Button from 'components/atoms/Buttons/Button/Button';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import Typography from 'components/atoms/Text/Typography/Typography';
import dom from 'helpers/dom';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';

const QuestionnaireContextCard = React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    question,
    questionnaires,
    openQuestionnaireIds,
    questionnaireStats,
    canUpdate,
    variant,
    onDirty,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'QuestionnaireContextCard', {
    static: ['canUpdate']
  });

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const skipRef = useRef(false);

  const [internalState, setInternalState] = useState({
    disabled: false,
    explain: false,
    values: {}
  });

  const profileProvider = useProfile();

  const statistics = variant === 'statistics';

  const snackbar = useSnackbar();

  const [fields, info] = useMemo(() => {
    const fields = [], info = { options: [] };

    if (question && collection) {
      if (question.analyserType === constants.collection.analyserTypes.collectionTagAnalyser) {
        let tagGroup = collection.tagGroups.find((tg) => +tg.groupId === +question.analyserParams.tagGroup);

        if (tagGroup) {
          info.multiselect = tagGroup.multiselect;
          info.options = (tagGroup.tags || []).map((t) => {
            return {
              label: t.value,
              value: t.value,
              tagId: t.tagId
            }
          });
        }
      } else {
        info.yesNo = true;
        info.options = constants.data.toggleYesNo
      }

      info.numericOptions = info.options
        .reduce((isNum, o) => isNum && utils.isNumber(o.label), true);
      info.longestWord = info.options
        .map((o) => o.label.toString().split(/\s+/).reduce((len, s) => len < s.length ? s.length : len, 0))
        .reduce((len, l) => (len < l) ? l : len, 0);
      info.longestLabel = info.options
        .reduce((len, o) => len < o.label.toString().length ? o.label.toString().length : len, 0);

      if (!statistics) {
        if (!internalState.explain) {
          if (info.numericOptions) {
            fields.push({
              name: 'value',
              label: 'vote',
              type: constants.formFieldTypes.slider,
              options: info.options
                .sort((a, b) => +a.value - +b.value)
                .map((v) => ({...v, value: +v.value})),
              required: true,
              disabled: internalState.disabled,
              FormFieldProps: {
                autoFocus: false,
                hiddenLabel: true,
                defaultThumbs: false,
                size: 'medium',
                SliderProps: {
                  step: null
                }
              }
            });
          } else {
            fields.push({
              name: 'value',
              label: 'vote',
              type: constants.formFieldTypes.list,
              validation: info.multiselect ? constants.formFieldValidationTypes.list : constants.formFieldValidationTypes.text,
              options: info.options,
              required: true,
              disabled: internalState.disabled,
              FormFieldProps: {
                multiple: info.multiselect,
                autoFocus: false,
                hiddenLabel: true,
                size: 'smaller',
                ListProps: {
                  catchFocus: false
                }
              }
            });
          }
        } else {
          fields.push({
            name: 'comment',
            label: 'Would you like to explain your vote?',
            placeholder: 'Explanation',
            inlineLabel: 'explanation',
            type: constants.formFieldTypes.textarea,
            disabled: internalState.disabled,
            FormFieldProps: {
              size: 'smaller',
              autoFocus: true,
              minRows: 3
            }
          })
        }
      }
    }

    return [fields, info];
  }, [statistics, collection, question, internalState.explain, internalState.disabled]);

  const statsMemo = useMemo(() => {
    const stats = [];
    if (questionnaireStats && questionnaires) {
      let userIds = [];
      questionnaireStats
        .filter((qns) => questionnaires.find((qn) => +qn.questionnaireId === +qns.questionnaireId))
        .forEach((qns) => {
          userIds = userIds.concat(qns.userIds);
          info.options.forEach((opt) => {
            let stat = stats.find((s) => s.option === opt);
            if (!stat) {
              stat = {
                option: opt,
                userIds: []
              }
              stats.push(stat);
            }

            const answers = qns.answers.filter((answer) => {
              return +answer.entityId === +entity?.entityId &&
                +answer.parentQuestionId === +question?.questionId && (
                  answer.value.toString() === opt.value.toString() ||
                  answer.value.toString() === opt.tagId?.toString()
                );
            });

            answers.forEach((answer) => {
              stat.userIds = stat.userIds.concat(answer.userIds);
            })
          });
        });

      userIds = utils.uniqueArray(userIds);
      stats.forEach((stat) => {
        stat.label = stat.option.label;
        stat.votes = utils.uniqueArray(stat.userIds).length;
        stat.progress = (userIds.length > 0) ? ((stat.votes / userIds.length) * 100) : 0;
      });
    }

    return stats;
  }, [questionnaires, questionnaireStats, info.options, entity?.entityId, question?.questionId]);

  const renderStatistics = () => {
    const votes = statsMemo.reduce((t, stat) => t + +stat.votes, 0);
    const numeric = Boolean(entity?.questionnaireAnswers?.[question?.questionId]?.numeric);
    const result = entity?.questionnaireAnswers?.[question?.questionId]?.value;

    return <Box className="QuestionnaireContextCard-stats">
      {statsMemo.map((stat) => {
        return <Box className="QuestionnaireContextCard-stat">
          <Box className="QuestionnaireContextCard-stat-title">
            <Typography variant="subtitle2">{stat.label}</Typography>
            {stat.votes > 0 ? <Typography variant="body2" color="primary">
              {`${stat.votes} vote${stat.votes > 1 ? 's' : ''}`}
            </Typography> : null}
          </Box>
          <LinearProgress variant="determinate" value={stat.progress} />
        </Box>
      })}
      {utils.isDefined(result) ?
        <Box className="QuestionnaireContextCard-stat-result">
          <Typography variant="subtitle2">{(numeric && utils.isNumber(result)) ? utils.toNumber(result).toFixed(2) : result}</Typography>
          <Typography variant="body2" color="primary">
            {`${votes} vote${votes > 1 ? 's' : ''}`}
          </Typography>
        </Box> : null}
    </Box>
  }

  const handleSubmit = (values, actions) => {
    if (!internalState.explain) {
      actions.setSubmitting(false);

      const options = utils.toArray(values['value']).map((v) => {
        const option = info.options
          .find((opt) => {
            return +opt.tagId === +v?.tagId || opt.value === (v.value ?? v) ||
              opt.value.toString() === (v.value ?? v).toString();
          });

        return option?.tagId ?? option.value;
      });

      setInternalState(utils.updater({
        explain: true,
        values: {
          value: utils.isArray(values['value']) ? options : options[0]
        }
      }, true));
    } else {
      setInternalState(utils.updater({submitting: true}, true));
      Promise.all(openQuestionnaireIds.map((questionnaireId) => {
        return profileProvider.updaters.createAnswer({
          entityId: entity.entityId,
          questionId: question.questionId,
          questionnaireId: questionnaireId,
          ...(skipRef.current ? internalState.values : {
            ...internalState.values,
            ...values
          })
        })
      }))
        .then(() => {
          setInternalState(utils.updater({disabled: true}, true));
        })
        .catch(() => {
          setInternalState(utils.updater({error: true}, true));
          snackbar.show('Saving vote failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        })
        .finally(() => {
          actions.setSubmitting(false);
          setInternalState(utils.updater({submitting: false}, true));
        });
    }
  };

  const handleChange = () => {
    setInternalState(utils.updater({error: null}, true));
  }

  const handleSkipClick = () => {
    skipRef.current = true;
    formRef.current?.submit();
  };

  const handleSubmitClick = () => {
    skipRef.current = false;
    formRef.current?.submit();
  };

  const renderQuestion = () => {
    return <Box className={`QuestionnaireContextCard-question ${internalState.error ? 'error' : ''}`}>
      <InlineForm ref={formRef} className="QuestionnaireContextCard-form"
                  fields={fields}
                  onSubmit={handleSubmit}
                  onChange={handleChange}/>
      {isLoading ? <Box className="QuestionnaireContextCard-loaders">
        {(new Array(Math.ceil(Math.random() * 3) + 1)).fill(null).map((i, idx) => {
          return <Typography key={idx} min={8} max={24} isLoading={true}/>
        })}
      </Box> : null}
      <Box className="QuestionnaireContextCard-buttons">
        <Button type="submit"
                variant="contained"
                size="small"
                isLoading={isLoading}
                disabled={internalState.submitting || internalState.disabled}
                children={internalState.explain ? 'Submit' : 'Vote'}
                onClick={handleSubmitClick}/>
        {internalState.explain ? <Button children={'Skip'}
                                         variant="text"
                                         size="small"
                                         disabled={internalState.submitting || internalState.disabled}
                                         isLoading={isLoading}
                                         onClick={handleSkipClick}/> : null}
      </Box>
    </Box>
  }

  const handleFocus = () => {
    setTimeout(() => {
      if (innerRef.current) {
        dom.scrollIntoView(innerRef.current);
        return true;
      }
    }, constants.debounce.shortest);
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledQuestionnaireContextCard ref={innerRef} {...innerProps}
                                         title={question?.question}
                                         onFocus={handleFocus}
                                         isLoading={isLoading}>
    {statistics ? renderStatistics() : renderQuestion()}
    {(!statistics && question?.explanation) ? <FormHelperText component="div">
      <Markdown>{question.explanation}</Markdown>
    </FormHelperText> : null}
  </StyledQuestionnaireContextCard>
});

QuestionnaireContextCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  question: PropTypes.object,
  questionnaires: PropTypes.array,
  openQuestionnaireIds: PropTypes.array,
  questionnaireStats: PropTypes.array,
  canUpdate: PropTypes.bool,
  onDirty: PropTypes.func,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['question', 'statistics']), PropTypes.string])
};

QuestionnaireContextCard.defaultProps = {
  variant: 'question'
};

export default QuestionnaireContextCard;
