import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import StyledTeamAddDialog from 'components/organisms/Dialogs/TeamAddDialog/TeamAddDialog.styles';
import utils from 'helpers/utils';
import {useAuthTeams} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';

const TeamAddDialog = React.forwardRef((props, ref) => {
  const {
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'TeamAddDialog', {
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const teams = useAuthTeams();

  useImperativeHandle(ref, () => innerRef.current);

  const fields = useMemo(() => {
    return [
      {
        name: 'name',
        label: 'Name',
        inlineLabel: 'name',
        type: constants.formFieldTypes.text,
        validation: constants.formFieldValidationTypes.text,
        entity: 'team',
        initial: '',
        required: true,
        FormFieldProps: {
         variant: 'staticLabel'
        },
      },
      {
        name: 'isPrivate',
        label: 'Private',
        inlineLabel: 'private',
        type: constants.formFieldTypes.list,
        validation: constants.formFieldValidationTypes.boolean,
        conversion: constants.formFieldConversionTypes.value,
        options: 'toggleYesNoReverse',
        entity: 'team',
        initial: false,
        FormFieldProps: {
          variant: 'staticLabel',
          ListProps: {
            catchFocus: false,
            orientation: 'horizontal',
            gap: 16
          }
        }
      }
    ]
  }, []);

  const handleChange = () => {
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)(values)
      .then(() => {
        innerProps.onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        setError('Creating team failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors, formik) => {
    setDirty(isDirty);
    const duplicate = (teams ?? []).find((t) => t.name === formik?.values.name);
    if (duplicate) {
      setValidation(`${formik?.values.name} already exists`);
    } else if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e, 'cancelButtonClick');
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              color="success"
              variant="contained"
              children={'Create team'}
              startIcon={<Icon icon={Add}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledTeamAddDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader className="TeamAddDialog-header"
                  title="Add team" />
    <DialogContent className="TeamAddDialog-content" >
      <InlineForm ref={formRef} fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="TeamAddDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledTeamAddDialog>
});

TeamAddDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onSubmit: PropTypes.func
};

TeamAddDialog.defaultProps = {};

export default TeamAddDialog;
