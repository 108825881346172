import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import StyledSimpleQuery from 'components/organisms/Queries/SimpleQuery/SimpleQuery.styles';
import {default as searchUtils} from 'helpers/search';
import Button from 'components/atoms/Buttons/Button/Button';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import dom from 'helpers/dom';

const SimpleQuery = React.forwardRef((props, ref) => {
  const {
    query,
    search,
    wizard,
    initialised,
    onChange,
    ...innerProps
  } = useComponentProps(props, 'SimpleQuery', {
    static: ['wizard']
  });

  const searchFieldRef = useRef(null);
  const [wizardMode, setWizardMode] = useState(null);

  useEffect(() => {
    if (initialised || Boolean(wizard)) {
      if (!utils.isDefined(wizardMode)) {
        return utils.observeTimeout(() => {
          setWizardMode(!Boolean(search) && Boolean(wizard));
        }, (Boolean(wizard) || wizardMode) ? 0 : constants.debounce.short);
      }
    }
  }, [search, wizard, initialised, wizardMode]);

  const onChangeEvent = useEffectEvent(onChange);
  useEffect(() => {
    if (utils.isDefined(wizardMode)) {
      const newQuery = searchUtils.simpleQuery(query, wizardMode ? '' : (search || wizard), wizardMode ? (wizard || search) : '');
      onChangeEvent?.(newQuery, wizardMode ? '' : (search || wizard), wizardMode ? (wizard || search) : '');
    }
  }, [query, search, wizard, wizardMode, onChangeEvent]);

  useEffect(() => {
    dom.focusElement(searchFieldRef.current?.ref?.current);
  }, [wizardMode]);

  const handleChange = (field, value) => {
    const newQuery = searchUtils.simpleQuery(query, field.name === 'wizard' ? '' : value, field.name === 'wizard' ? value : '');
    onChangeEvent?.(newQuery, field.name === 'wizard' ? '' : value, field.name === 'wizard' ? value : '');
  };

  const fields = useMemo(() => {
    return [{
      name: wizardMode ? 'wizard' : 'search',
      inlineLabel: wizardMode ? 'Wizard text' : 'Search text',
      placeholder: wizardMode ? 'Type a description of a company' : 'Search for a company',
      type: wizardMode ? constants.formFieldTypes.textarea : constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      initial: wizardMode ? wizard : search?.replace(/(\n)+/g, ' '),
      immediate: !wizardMode,
      prefix: <Icon icon={Search}/>,
      postfix: <Box className="SimpleQuery-adornment">
        {((wizardMode ? wizard : search)?.length > 0) ? <ActionIconButton action={{
          icon: <Icon icon={Close}/>,
          onClick: () => {
            searchFieldRef.current?.clear();
            searchFieldRef.current?.focus();
          }
        }}/> : null}
        <Button size="smaller"
                plainBorder={!wizardMode}
                color={!wizardMode ? 'secondary' : 'primary'}
                variant={!wizardMode ? 'outlined' : 'contained'}
                startIcon={<Icon icon={AutoAwesome}/>}
                onClick={() => {
                  setWizardMode((current) => !current);
                }}>
          Wizard
        </Button>
      </Box>,
      FormFieldProps: {
        ref: searchFieldRef,
        minRows: 1,
        debounce: constants.debounce.search,
        hiddenLabel: true,
        variant: 'outlined'
      }
    }];
  }, [search, wizard, wizardMode]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    wizard: wizard
  });

  return <StyledSimpleQuery ref={ref} {...innerProps}>
    <InlineForm className="SimpleQuery-form"
                onChange={handleChange}
                fields={fields}/>
  </StyledSimpleQuery>
});

SimpleQuery.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  search: PropTypes.string,
  wizard: PropTypes.string,
  query: PropTypes.object,
  initialised: PropTypes.bool,
  onChange: PropTypes.func
};

SimpleQuery.defaultProps = {};

export default SimpleQuery;
