import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUpdatedState} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Delete from '@mui/icons-material/Delete';
import ListMappingsForm from 'components/organisms/Forms/ListMappingsForm/ListMappingsForm';
import StyledMappingForm from 'components/organisms/Forms/MappingForm/MappingForm.styles';
import TeamMappingsForm from 'components/organisms/Forms/TeamMappingsForm/TeamMappingsForm';
import FieldMappingsForm from 'components/organisms/Forms/FieldMappingsForm/FieldMappingsForm';

const MappingForm = React.forwardRef((props, ref) => {
  const {
    mapping,
    only,
    mappedFields,
    connection,
    fieldData,
    required,
    readOnly,
    disabled,
    removable,
    hideActions,
    error,
    errorFrom,
    errorTo,
    onChange,
    onError,
    onDelete,
    ...innerProps
  } = useComponentProps(props, 'MappingForm');

  const formRef = useRef(null);
  const teamsRef = useRef(null);
  const listsRef = useRef(null);
  const fieldsRef = useRef(null);
  const innerRef = useRef(null);

  const mappingForm = useMemo(() => ({
    refs: {
      ref: innerRef,
      formRef
    },
    submit: (touch = true) => {
      return formRef.current?.submit(touch);
    },
    validate: (touch = true) => {
      return formRef.current?.validate(touch);
    },
    reset: (state) => {
      listsRef.current?.reset(state);
      fieldsRef.current?.reset(state);
      setIsDirty(false);
      setInternalState({});
      return formRef.current?.reset(state);
    }
  }), []);

  useImperativeHandle(ref, () => mappingForm);

  const [isDirty, setIsDirty] = useState(false);
  const [internalState, setInternalState] = useState(mapping);
  const [internalValue] = useUpdatedState(mapping);

  const isNew = utils.isEmpty(internalValue.teams);

  useEffect(() => {
    if (required && error) {
      return utils.observeTimeout(() => {
        try {
          formRef.current?.validate(true);
        } catch (e) {
          /* SQUASH */
        }
      }, constants.debounce.minimal)
    }
  }, [required, error]);

  const fields = useMemo(() => {
    const flds = [];

    const teams = internalState.teams ?? internalValue.teams;

    flds.push({
      name: 'teams',
      label: 'Teams',
      type: constants.formFieldTypes.component,
      validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.teamMapping}))`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'mapping',
      valueProp: 'teams',
      formGroup: 'header',
      Component: <TeamMappingsForm connection={connection}
                                   errorFrom={errorFrom}
                                   errorTo={errorTo}
                                   fieldData={fieldData}
                                   multiple={false} />,
      initial: {
        value: internalValue.teams ?? [],
        errors: false
      },
      required: required,
      readOnly: readOnly,
      disabled: disabled,
      FormFieldProps: {
        ref: teamsRef,
        variant: 'staticLabel',
        hiddenLabel: true,
        debounce: false
      }
    });

    flds.push({
      name: 'synchroniseDeletedItems',
      label: 'Filter',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      options: [
        {
          label: 'Synchronise removed companies',
          value: true
        },
        {
          label: 'Ignore removed companies',
          value: false
        }
      ],
      entity: 'mapping',
      formGroup: 'body',
      initial: internalValue.synchroniseDeletedItems ?? false,
      readOnly: readOnly,
      disabled: disabled || !utils.isDefined(teams?.[0]),
      FormFieldProps: {
        variant: 'inlineLabel',
        hiddenLabel: true,
        deselect: false,
        debounce: false,
        ListProps: {
          catchFocus: false,
          orientation: 'horizontal',
          gap: 16
        }
      }
    });

    const mappedTeamFields = mappedFields
      .filter((mf) => {
        return utils.isDefined(mf.listIdx) || ((mf.team?.from?.id || 0) !== teams?.[0]?.from?.id && (mf.team?.to?.id || 0) !== teams?.[0]?.to?.id);
      });

    flds.push({
      name: 'fields',
      label: 'Fields',
      type: constants.formFieldTypes.component,
      validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.fieldMapping}))`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'mapping',
      valueProp: 'fields',
      formGroup: 'body',
      Component: <FieldMappingsForm team={teams?.[0]}
                                    mappedFields={mappedTeamFields}
                                    connection={connection}
                                    fieldData={fieldData} />,
      initial: {
        value: internalValue.fields ?? [],
        errors: false
      },
      required: false,
      readOnly: readOnly,
      disabled: disabled || !utils.isDefined(teams?.[0]),
      FormFieldProps: {
        ref: fieldsRef,
        variant: 'staticLabel',
        hiddenLabel: false,
        debounce: false,
        autoFocus: false
      }
    });

    flds.push({
      name: 'lists',
      label: 'Lists',
      type: constants.formFieldTypes.component,
      validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.listMapping}))`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'mapping',
      valueProp: 'lists',
      formGroup: 'body',
      Component: <ListMappingsForm team={teams?.[0]}
                                   connection={connection}
                                   mappedFields={mappedFields}
                                   fieldData={fieldData} />,
      initial: {
        value: internalValue.lists ?? [],
        errors: false
      },
      required: false,
      readOnly: readOnly,
      disabled: disabled || !utils.isDefined(teams?.[0]),
      FormFieldProps: {
        ref: listsRef,
        variant: 'staticLabel',
        hiddenLabel: false,
        debounce: false,
        autoFocus: false
      }
    });

    return flds;
  }, [internalValue, internalState, connection, mappedFields, readOnly, required, disabled, errorFrom, errorTo, fieldData]);

  const handleSubmit = (values, actions) => {
    utils.asPromise(onChange)({...values})
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  const handleChange = () => {
    formRef.current?.submit(false);
  }

  const handleError = (field, value) => {
    utils.asPromise(onChange)({...internalValue, [field.name]: value});
  }

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    if (field) {
      if (field.name === 'teams') {
        setInternalState((current) => {
          if (!utils.compare((current.teams ?? []).map((t) => ({...t, id: null, direction: null})),
            (value.value ?? []).map((t) => ({...t, id: null, direction: null})))) {
            fieldsRef.current?.setValue?.({value: [], errors: false});
            fieldsRef.current?.reset?.({touched: {}});
            formRef.current?.touch?.({fields: false});

            listsRef.current?.setValue?.({value: [], errors: false});
            listsRef.current?.reset?.({touched: {}});
            formRef.current?.touch?.({lists: false});

            return {
              ...current,
              teams: value.value
            }
          } else {
            return current;
          }
        });
      }
    }
  }

  const handleValidating = (validating, dirty, errors) => {
    setIsDirty((current) => current || dirty || errors);
    onError?.(errors);
  }

  const onDeleteEvent = useEffectEvent(onDelete);
  const deleteAction = useMemo(() => ({
    label: 'Remove mapping',
    tooltip: 'Remove mapping',
    icon: Delete,
    ActionIconButtonProps: {
      IconProps: {
        size: 'smaller'
      }
    },
    IconButtonProps: {
      disabled: (disabled || readOnly || !removable || (only && isNew && !isDirty))
    },
    onClick: (e) => {
      onDeleteEvent?.(e);
    }
  }), [disabled, readOnly, removable, only, isDirty, isNew, onDeleteEvent]);

  const renderActions = () => {
    return <Box className="MappingForm-actions">
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="contained"
                        color="error"
                        action={deleteAction}/>
    </Box>
  }

  const renderFormContent = (groups) => {
    const header = groups.find((g) => g.name === 'header');
    const body = groups.find((g) => g.name === 'body');

    return <React.Fragment>
      <Box className="MappingForm-header">
        <Box className="Form-fields">
          {header.rendered}
        </Box>
        {!hideActions ? renderActions() : null}
      </Box>
      {body ? <Box className="MappingForm-body">
        <Box className="Form-fields">
          {body.rendered}
        </Box>
      </Box> : null}
    </React.Fragment>
  }

  return <StyledMappingForm ref={innerRef} {...innerProps}>
    <InlineForm ref={formRef}
                className="MappingForm-form"
                fieldData={fieldData}
                autoFocus={isNew && !only}
                onSubmit={handleSubmit}
                onChange={handleChange}
                onError={handleError}
                onChangeDirect={handleChangeDirect}
                onValidating={handleValidating}
                renderContent={renderFormContent}
                fields={fields}/>
  </StyledMappingForm>
});

MappingForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  mapping: PropTypes.object,
  only: PropTypes.bool,
  mappedFields: PropTypes.array,
  connection: PropTypes.object,
  fieldData: PropTypes.object,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  removable: PropTypes.bool,
  hideActions: PropTypes.bool,
  error: PropTypes.bool,
  errorFrom: PropTypes.bool,
  errorTo: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onDelete: PropTypes.func
};

MappingForm.defaultProps = {
  elevation: 1,
  radius: 'round'
};

export default MappingForm;
