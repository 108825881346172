import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledLink from 'components/atoms/Links/Link/Link.styles';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import {withMemo} from 'helpers/wrapper';

const Link = withMemo(React.forwardRef((props, ref) => {
  const {
    showTooltip,
    isLoading,
    TooltipProps,
    ...innerProps
  } = useComponentProps(props, 'Link', {
    static: ['disabled', 'clickable']
  });

  const innerRef = useRef(null);

  const clickable = Boolean(innerProps.onClick || innerProps.href);

  useImperativeHandle(ref, () => innerRef.current);

  const randomString = useMemo(() => {
    if (isLoading) {
      return utils.randomString(12, 24)
    } else {
      return null;
    }
  }, [isLoading]);

  const handleKeyDown = (e) => {
    if (!e.defaultPrevented && e.target === innerRef.current && clickable) {
      if (e.code === 'Space' || e.code === 'Enter') {
        e.target?.click();
        e.preventDefault();
      }
    }
  }

  innerProps.as = isLoading ? Skeleton : innerProps.as;
  innerProps.tabIndex = innerProps.tabIndex ?? (clickable ? 0 : -1);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: clickable
  });

  return <Tooltip title={(!isLoading && showTooltip) ? innerProps.children : null}
                  placement="bottom"
                  {...TooltipProps}>
    <StyledLink ref={innerRef} {...innerProps}
                onKeyDown={handleKeyDown}>
      {randomString ?? innerProps.children}
    </StyledLink>
  </Tooltip>
}));

Link.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  showTooltip: PropTypes.bool,
  isLoading: PropTypes.bool,
  TooltipProps: PropTypes.object
};

Link.defaultProps = {
  children: 'Link text'
};

export default Link;
