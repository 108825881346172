import React, {useEffect} from 'react'
import {QueryCache, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import system from 'helpers/system';
import logger from 'helpers/logger';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      suspense: false, // buggy
      staleTime: system.queryStaleTime(), // after 4 min it will start in background on new mounts, refreshes, reconnect
      cacheTime: system.queryCacheTime(), // after 8 min it's not served anymore
      networkMode: system.queryNetworkMode(),
    },
    mutations: {
      //retry: false,
      suspense: false, // buggy
      networkMode: system.queryNetworkMode(),
    }
  },
  queryCache: new QueryCache({}),
})

const QueryProvider = (props) => {
  const snackbar = useSnackbar();

  //init
  useEffect(() => {
    queryClient.defaultOptions.queries.onError = (error) => {
      // handle general api errors here
      if (error.response?.status >= constants.http.status.serverError) {
        snackbar.show(`Error happened while trying to communicate with the server.`, null,
          {color: 'warning', autoHideDuration: constants.delay.warning});
      } else if (!error.response && error.code) {
        snackbar.show(`Please check the network connection.`, null,
          {color: 'warning', autoHideDuration: constants.delay.warning});
      }
      logger.trace('Query error', error);
    };

    queryClient.defaultOptions.mutations.onError = (error, variables, context) => {
      // handle general api errors here
      if (error.response?.status >= constants.http.status.serverError) {
        snackbar.show(`Error happened while trying to communicate with the server.`, null,
          {color: 'warning', autoHideDuration: constants.delay.warning});
      } else if (!error.response && error.code) {
        snackbar.show(`Please check the network connection.`, null,
          {color: 'warning', autoHideDuration: constants.delay.warning});
      }
      logger.trace('Mutation error', error, variables, context);
    };

    queryClient.getQueryCache().config.onError = (error, query) => {
      // only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (error.response?.status >= constants.http.status.serverError && utils.isDefined(query.state.data)) {
        snackbar.show(`Something went wrong, try refreshing the page.`, null,
          {color: 'warning', autoHideDuration: constants.delay.warning});
      } else if (!error.response && error.code) {
        snackbar.show(`Please check the network connection.`, null,
          {color: 'warning', autoHideDuration: constants.delay.warning});
      }
      logger.trace('Cache error', error);
    };
  }, [snackbar]); // run once

  //inner
  return <QueryClientProvider client={queryClient}>
    {props.children}
  </QueryClientProvider>
};

QueryProvider.propTypes = {
}

export default QueryProvider;
