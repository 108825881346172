import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';
import {rgba} from 'polished';

const StyledGraphCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(2)};
  border: 1px solid ${props => props.theme.property('palette.divider')};

  *,
  *::before,
  *::after {
    scroll-margin: 0;
  }
  
  .GraphCard {
    &-header {
      padding: 0 0 ${props => props.theme.spacing(2)} 0;

      &:has(.MuiCardHeader-action) {
        margin-top: ${props => props.theme.layout('-0.5sp')};
      }

      .MuiCardHeader-content {
        margin-right: ${props => props.theme.spacing(2)};
        width: 1px;
        flex-grow: 1;
        * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .MuiCardHeader-action {
        margin: 0 ${props => props.theme.layout('-0.5sp')} 0 0;
        min-width: ${props => props.theme.layout(180)};

        display: flex;
        justify-content: flex-end;
      }
    }

    &-content {
      height: 100%;
      overflow: hidden;

      padding: 0;
    }
    
    &-actions {
      padding: 0 ${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(2)};
    }

    &-empty {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: -${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(1)} 0;
      }
    }
  }

  &.GraphCard-density-densest {
    padding: 0;
  }
  
  ${ComponentStyles}
`;

StyledGraphCard.propTypes = {
  theme: PropTypes.object
};

StyledGraphCard.defaultProps = {}

export default StyledGraphCard;
