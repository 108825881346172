import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDatabaseEntitiesTableBar
  from 'components/organisms/Bars/DatabaseEntitiesTableBar/DatabaseEntitiesTableBar.styles';
import {useSplitPath} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';

const DatabaseEntitiesTableBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesTableBar');

  const tableProvider = useTable();

  const [, pathPostfix] = useSplitPath('database');

  const columnAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'database.entity.columns' }),
  }), []);

  const analyseAction = useMemo(() => {
    const limited = (tableProvider.list?.meta?.resultsCount > constants.selection.max.database);
    return {
      ...(limited ? {
          tooltip: `Catalist can analyse a maximum of a ${constants.selection.max.database} companies from the database`
        } : {}),
      ButtonProps: {
        disabled: !(tableProvider.list?.meta?.resultsCount > 0) || limited
      }
    }
  }, [tableProvider.list?.meta?.resultsCount]);

  const saveSearchAction = null;
  // todo: for advanced
  // useMemo(() => ({
  //   label: 'Save search',
  //   auth: utils.createAuth({ attribute: 'database.entity.search.create' }),
  // }), []);

  const entitiesCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.title = innerProps.title ?? `${entitiesCount} compan${(tableProvider.list?.meta?.resultsCount ?? 0) === 1 ? 'y' : 'ies'}`;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDatabaseEntitiesTableBar ref={ref} {...innerProps}
                                         columnAction={columnAction}
                                         analyseAction={analyseAction}
                                         saveAction={saveSearchAction}
                                         hideColumn={Boolean(pathPostfix)}
                                         hideSearch={true}
                                         hideFilter={true}
                                         hideSave={true} />
});

DatabaseEntitiesTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesTableBar.defaultProps = {
};

export default DatabaseEntitiesTableBar;
