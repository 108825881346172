import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledEntitiesAnalyseMatchDialogContent
  from 'components/organisms/Dialogs/EntitiesAnalyseMatchDialogContent/EntitiesAnalyseMatchDialogContent.styles';
import EntitiesAnalyseWizard from 'components/organisms/Wizards/EntitiesAnalyseWizard/EntitiesAnalyseWizard';
import utils from 'helpers/utils';

const EntitiesAnalyseMatchDialogContent = React.forwardRef((props, ref) => {
  const {
    collection,
    tableProvider,
    dirty,
    onDirty,
    onClose,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseMatchDialogContent', {
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const wizardRef = useRef(null);

  const [internalState, ] = useState({});

  const entitiesAnalyseMatchDialogContent = useMemo(() => {
    const state = {
      ...internalState
    };

    return {
      refs: {
        ref: innerRef,
        wizardRef
      },
      state: state,
      cleanup: () => {
        /* no cleanup */
      }
    }
  }, [internalState]);

  const handleSubmit = (entityIds, actions) => {
    return utils.asPromise(onSubmit)(entityIds, actions)
      .then(() => {
        onClose?.(null, 'saveButtonClick');
      });
  }

  useImperativeHandle(ref, () => entitiesAnalyseMatchDialogContent);

  return <StyledEntitiesAnalyseMatchDialogContent ref={innerRef} {...innerProps}>
    <EntitiesAnalyseWizard ref={wizardRef}
                           className="EntitiesAnalyseMatchDialogContent-wizard"
                           collection={collection}
                           tableProvider={tableProvider}
                           showButtons={true}
                           showProgressBar={true}
                           onDirty={onDirty}
                           onSubmit={handleSubmit}/>
  </StyledEntitiesAnalyseMatchDialogContent>
});

EntitiesAnalyseMatchDialogContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  dirty: PropTypes.bool,
  onDirty: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

EntitiesAnalyseMatchDialogContent.defaultProps = {
};

export default EntitiesAnalyseMatchDialogContent;
