import React, {useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import StyledEntitiesAnalyseGraphWizardContent
  from 'components/organisms/WizardContent/EntitiesAnalyseGraphWizardContent/EntitiesAnalyseGraphWizardContent.styles';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import GraphFrame from 'components/molecules/Frames/GraphFrame/GraphFrame';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import Typography, {P} from 'components/atoms/Text/Typography/Typography';
import {useAnalyseGraph} from 'services/analyse/analyse.hooks';
import Icon from 'components/atoms/Icons/Icon/Icon';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Refresh from '@mui/icons-material/Refresh';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Download from '@mui/icons-material/Download';
import Button from 'components/atoms/Buttons/Button/Button';

const EntitiesAnalyseGraphWizardContent = React.forwardRef((props, ref) => {
  const {
    step,
    wizard,
    tableProvider,
    collection,
    onComplete,
    onDirty,
    onError,
    ...innerProps
  } = useComponentProps(props, 'EntitiesAnalyseGraphWizardContent');

  const innerRef = useRef(null);
  const graphRef = useRef(null);

  const snackbar = useSnackbar();
  const listState = useEffectItem(tableProvider.appliedListState());
  const entitySelectedEvent = useEffectEvent(tableProvider.updaters?.selectedItems);

  const data = wizard.data?.[wizard.dataKey];
  const state = wizard.getStepState();
  const setStateEvent = useEffectEvent(wizard.setStepState);
  const setDataEvent = useEffectEvent(wizard.setData);

  const descriptionData = data?.[data?.descriptionHash];

  const analyseGraph = useAnalyseGraph();

  const onCompleteEvent = useEffectEvent(onComplete);
  const entitiesAnalyseGraphWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
    },
    submit: () => {
      onCompleteEvent?.(Boolean(step?.resetNextSteps));
    }
  }), [step?.resetNextSteps, onCompleteEvent]);

  useImperativeHandle(ref, () => entitiesAnalyseGraphWizardContent);

  useEffect(() => {
    if (!utils.isDefined(data?.entityIds)) {
      entitySelectedEvent?.(listState, 0, tableProvider.list?.meta?.resultsCount, null, true,
        (entities) => {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              entityIds: (current?.[wizard.dataKey]?.entityIds || [])
                .concat(entities.map((e) => e.entityId))
            }
          }));
        }, () => {
          snackbar.show('Retrieving company data failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        });
    }
  }, [snackbar, entitySelectedEvent, listState, data?.entityIds,
    tableProvider.list?.meta?.resultsCount, wizard.dataKey, setDataEvent]);

  useLayoutEffect(() => {
    const descriptionHash = utils.sha1(`${data?.description}_${data?.graphType}`);

    setDataEvent?.((current) => {
      if (current?.[wizard.dataKey]?.descriptionHash !== descriptionHash) {
        return {
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            descriptionHash: descriptionHash,
            [descriptionHash]: {
              ...current[wizard.dataKey]?.[descriptionHash]
            }
          }
        }
      } else {
        return current;
      }
    })
  }, [data?.description, data?.graphType, wizard.dataKey, setDataEvent]);

  useEffect(() => {
    if (!descriptionData?.graph && data?.entityIds?.length > 0 && (state?.retry ?? 1) > 0) {
      analyseGraph.mutation.mutateAsync({
        collectionId: collection?.collectionId,
        entityIds: data?.entityIds,
        description: data?.description,
        assistantType: data?.graphType
      })
        .then(({response}) => {
          const result = utils.camelcase(response.data?.data);
          const credits = result.credits;
          const graph = result.graph;

          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              [current[wizard.dataKey]?.descriptionHash]: {
                ...current[wizard.dataKey]?.[current[wizard.dataKey]?.descriptionHash],
                credits: (current[wizard.dataKey]?.[current[wizard.dataKey]?.questionHash]?.credits ?? 0) + credits,
                graph: graph
              }
            }
          }));
        })
        .catch((error) => {
          setStateEvent?.({failed: true});
          if (error?.response?.status === constants.http.status.conflict) {
            snackbar.show('0 credits left in your wallet', null,
              {color: 'error', autoHideDuration: constants.delay.warning});
          } else {
            snackbar.show('Failed to create the company graph', null,
              {color: 'error', autoHideDuration: constants.delay.warning});
          }
        });
    }
  }, [collection?.collectionId, descriptionData?.graph, data?.entityIds, data?.description, data?.graphType,
    state?.retry, wizard.dataKey, snackbar, setDataEvent, setStateEvent, analyseGraph.mutation]);

  const handleOpen = () => {
    const source = graphRef.current?.source;

    utils.textDownload(source, 'graph.html', 'html/text');
  }

  return <StyledEntitiesAnalyseGraphWizardContent ref={ref} {...innerProps}>
    {descriptionData?.graph ? <GraphFrame ref={graphRef} srcDoc={descriptionData?.graph.html} /> : (
      !state?.failed ? <Box className="EntitiesAnalyseGraphWizardContent-loader">
        <CircularProgress />
        <P>Please wait</P>
      </Box> : <Box className="EntitiesAnalyseGraphWizardContent-failed">
        <IconButton density="densest" onClick={() => {
          setStateEvent?.((current) => ({...current, failed: false, retry: (current?.retry ?? 0) + 1}));
        }}>
          <Tooltip title="Retry" showDisabled={true}>
            <Icon icon={Refresh}/>
          </Tooltip>
        </IconButton>
        <P>Oops!</P>
      </Box>
    )}
    <Box className="EntitiesAnalyseGraphWizardContent-footer">
      <Box className="EntitiesAnalyseGraphWizardContent-info">
        <Typography className="EntitiesAnalyseGraphWizardContent-credits" variant="subtitle2">
          {utils.formatNumber(Math.round(descriptionData?.credits ?? 0))} credits
        </Typography>
        <Button size="smallest"
                variant="text"
                disabled={!descriptionData?.graph}
                startIcon={<Icon icon={Download} />}
                onClick={handleOpen}>Download</Button>
      </Box>
    </Box>
  </StyledEntitiesAnalyseGraphWizardContent>
});

EntitiesAnalyseGraphWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  step: PropTypes.object,
  wizard: PropTypes.object,
  collection: PropTypes.object,
  tableProvider: PropTypes.object,
  onComplete: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func
};

EntitiesAnalyseGraphWizardContent.defaultProps = {
};

export default EntitiesAnalyseGraphWizardContent;
