import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Typography from 'components/atoms/Text/Typography/Typography';
import Badge from 'components/atoms/Badges/Badge/Badge';
import StyledBadgeOption from 'components/molecules/Options/BadgeOption/BadgeOption.styles';

const BadgeOption = React.forwardRef((props, ref) => {
  const {
    badge,
    label,
    reverse,
    size,
    BadgeProps,
    ...innerProps
  } = useComponentProps(props, 'BadgeOption', {
    static: ['reverse']
  });

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledBadgeOption ref={ref} {...innerProps}>
    {badge ? <Badge badgeContent={badge} size="small" variant="inline" {...BadgeProps}/> : null}
    {label ? utils.isReactElement(label) ? label : <Typography variant={size === 'smaller' ? 'body2' : 'body1'}>{label}</Typography> : null}
  </StyledBadgeOption>
});

BadgeOption.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  badge: PropTypes.string,
  label: PropTypes.any,
  reverse: PropTypes.bool,
  size: PropTypes.string,
  BadgeProps: PropTypes.object
};

BadgeOption.defaultProps = {};

export default BadgeOption;
