import {useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useConnectionInternalTeams ({search, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, ...rest}, {
    overridePath: 'connections/internalTeams',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useConnectionInternalLists ({search, teamId, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, teamId, ...rest}, {
    overridePath: 'connections/internalLists',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useConnectionInternalFields ({search, teamId, collectionId, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {search, teamId, collectionId, ...rest}, {
    overridePath: 'connections/internalFields',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useConnectionExternalTeams ({type, key, search, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {type, key, search, ...rest}, {
    overridePath: 'connections/externalTeams',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useConnectionExternalLists ({type, key, teamId, search, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {type, key, teamId, search, ...rest}, {
    overridePath: 'connections/externalLists',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useConnectionExternalFields ({type, key, teamId, listId, search, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {type, key, teamId, listId, search, ...rest}, {
    overridePath: 'connections/externalFields',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
