import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTeamManagerDialog from 'components/organisms/Dialogs/TeamManagerDialog/TeamManagerDialog.styles';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {
  useAuthClientId, useAuthIsProxy,
} from 'services/auth/auth.utils';
import utils from 'helpers/utils';
import {useClientTeamMemberAdd} from 'services/client/team/member/member.hooks';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import AvatarOption from 'components/molecules/Options/AvatarOption/AvatarOption';
import TeamMembersTable from 'components/organisms/Tables/TeamMembersTable/TeamMembersTable';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useClientTeamMemberOptions} from 'services/client/team/team.utils';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import {useClientCallbacks} from 'services/client/client.utils';
import Box from 'components/atoms/Layout/Box/Box';

const TeamManagerDialog = React.forwardRef((props, ref) => {
  const {
    team,
    title,
    variant,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'TeamManagerDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);
  const fieldRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const snackbar = useSnackbar();

  const clientId = useAuthClientId();
  const isProxy = useAuthIsProxy();
  const clientCallbacks = useClientCallbacks(isProxy);
  const teamMemberOptions = useClientTeamMemberOptions(team?.teamId, true, false)

  const addTeamMember = useClientTeamMemberAdd();

  const fields = useMemo(() => {
    return  [{
      name: 'users',
      placeholder: 'Type a username',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.list,
      conversion: constants.formFieldConversionTypes.user,
      initial: [],
      options: clientCallbacks.users,
      filter: teamMemberOptions?.map?.((tm) => `-${tm.userId}`).filter((_) => (_)),
      FormFieldProps: {
        ref: fieldRef,
        autoFocus: variant !== 'extended',
        hiddenLabel: true,
        variant: 'outlined',
        size: 'smaller',
        multiple: true,
        fullWidth: true,
        getTagProps: (option) => {
          const avatar = `${utils.personName(option.firstName, option.lastName)} (${option.username})`;
          const color = utils.string2Color(avatar);
          return {
            icon: <Avatar color={color} size="tiny">{utils.avatarLabel(avatar)}</Avatar>
          };
        },
        renderOption: (option) => {
          const avatar = `${utils.personName(option.firstName, option.lastName)} (${option.username})`;
          const name = utils.personName(option.firstName, option.lastName);

          return <AvatarOption avatar={avatar}
                               label={name}
                               size="smaller" />
        }
      }
    }];
  }, [clientCallbacks, teamMemberOptions, variant]);

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    if (utils.isArray(values.users) && values.users.length > 0) {
      setSubmitting(true);
      if (onSubmit) {
        utils.asPromise(onSubmit)(values.users)
          .then(() => {
            innerRef.current?.close?.();
          })
          .catch(() => {
            /* SQUASH */
          })
          .finally(() => {
            actions.setSubmitting(false);
            setSubmitting(false);
          });
      } else {
        Promise.all(values.users?.map((userId) => {
          return addTeamMember.mutation.mutateAsync({
            clientId,
            teamId: team?.teamId,
            userId: userId
          });
        }))
          .then((submissions) => {
            if (submissions.some((submission) => submission?.error)) {
              snackbar.show('Adding user to team failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            } else {
              fieldRef.current?.clear();
              if (variant === 'standard') {
                innerRef.current?.close?.();
              }
            }
          })
          .catch(() => {
            snackbar.show('Adding user to team failed', null,
              {color: 'error', autoHideDuration: constants.delay.error});
          })
          .finally(() => {
            actions.setSubmitting(false);
            setSubmitting(false);
          });
      }
    } else {
      actions.setSubmitting(false);
    }
  };

  const action = useMemo(() => ({
    label: 'Add',
    auth: team ? utils.createAuth({attribute: 'team.member.create', meta: {team}}) : null,
    ButtonProps: {
      disabled: disabled || submitting
    },
    onClick: (e) => {
      formRef.current?.submit();
      e.preventDefault();
    }
  }), [team, disabled, submitting]);

  const handleChangeDirect = (e) => {
    if (e?.target?.name === 'users') {
      setDisabled(utils.isEmpty(e.target.value));
    }
  }

  const handleClose = (e, reason) => {
    if (disabled || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderAddButton = () => {
    return <ActionButton className="TeamManagerDialog-usersFormContent-button"
                         action={action}
                         showInactive={true} />
  };

  return <StyledTeamManagerDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title={title ? title : 'Add users to team ' + team?.name} />
    <DialogContent className="TeamManagerDialog-content">
      <Box className="TeamManagerDialog-usersForm">
        <InlineForm ref={formRef}
                    className="TeamManagerDialog-usersForm-form"
                    onSubmit={handleSubmit}
                    onChangeDirect={handleChangeDirect}
                    autoFocus={true}
                    fields={fields}/>
        {renderAddButton()}
      </Box>
      {variant === 'extended' && team?.members?.length > 0 ?
        <Box className="TeamManagerDialog-teamMembers">
          <TeamMembersTable team={team}
                            canUpdate={true} />
        </Box> : null}
    </DialogContent>
  </StyledTeamManagerDialog>
});

TeamManagerDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  team: PropTypes.object,
  onSubmit: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'extended']), PropTypes.string])
};

TeamManagerDialog.defaultProps = {};

export default TeamManagerDialog;
