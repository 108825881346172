import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledWizardContent from 'components/organisms/WizardContent/WizardContent/WizardContent.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import utils from 'helpers/utils';
import {H3, H6} from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';

const WizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    subSteps,
    fields,
    fieldData,
    onComplete,
    onError,
    onDirty,
    onSubmit,
    onChange,
    onFieldError,
    onChangeDirect,
    InlineFormProps,
    ...innerProps
} = useComponentProps({...props, ...utils.filterObject(props.step, [
    'subSteps', 'fields', 'fieldData',
  ], false)}, 'WizardContent');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const state = wizard.getStepState();
  const setStateEvent = useEffectEvent(wizard.setStepState);
  const setDataEvent = useEffectEvent(wizard.setData);

  const onErrorEvent = useEffectEvent(onError);
  const wizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
      formRef: formRef
    },
    submit: () => {
      return formRef.current?.submit()
        .catch(() => {
          onErrorEvent?.('Please check if all fields have the correct values', false);
        });
    }
  }), [onErrorEvent]);

  useImperativeHandle(ref, () => wizardContent);

  const fieldsMemo = useMemo(() => {
    if (fields) {
      return utils.initializeFormFields(fields, {...wizard.data?.[wizard.dataKey], ...state});
    }
  }, [fields, wizard.data, wizard.dataKey, state]);

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    if (hasErrors) {
      onError?.('Please check if all fields have the correct values', true);
    } else {
      onError?.(null);
    }
  }

  const handleChange = (field, value) => {
    if (onChange) {
      onChange(field, value);
    } else {
      if (field.target === 'state') {
        setStateEvent?.({[field.name]: value});
      } else {
        setDataEvent?.((current) => ({
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            [field.name]: value
          }
        }));
      }
    }
  }

  const handleFieldError = (field, value) => {
    if (onFieldError) {
      onFieldError(field, value);
    } else {
      if (field.target === 'state') {
        setStateEvent?.({[field.name]: null});
      } else {
        setDataEvent?.((current) => ({
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            [field.name]: value
          }
        }));
      }
    }
  }

  const handleChangeDirect = (e) => {
    onDirty?.(true);
    onChangeDirect?.(e);
  }

  const handleSubmit = (values, actions) => {
    onDirty?.(true, true); // set submitting, on complete or error resets

    if (onSubmit) {
      utils.asPromise(onSubmit)(values, actions)
        .then((resetNextSteps) => {
          onComplete?.(Boolean(resetNextSteps ?? step?.resetNextSteps));
        })
        .catch((err) => {
          if (!['closeButtonClick', 'cancelButtonClick'].includes(err)) {
            onError?.((utils.isString(err) || (!utils.isDefined(err.status) && err.message)) ?
              (err?.message ?? err) : 'Saving progress failed');
          } else {
            onDirty?.(true, false);
          }
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    } else {
      const state = fieldsMemo.reduce((o, f) => {
        if (f.target === 'state') {
          if (values.hasOwnProperty(f.name)) {
            o[f.name] = values[f.name];
          } else {
            o[f.name] = null;
          }
        }
        return o;
      }, {});

      const data = fieldsMemo.reduce((o, f) => {
        if (f.target !== 'state') {
          if (values.hasOwnProperty(f.name)) {
            o[f.name] = values[f.name];
          } else {
            o[f.name] = null;
          }
        }
        return o;
      }, {});

      if (!utils.isEmpty(state)) {
        setStateEvent?.(state);
      }

      setDataEvent?.((current) => ({
        ...current,
        [wizard.dataKey]: {
          ...current[wizard.dataKey],
          ...data
        }
      }));

      actions.setSubmitting(false);
      onComplete?.(Boolean(step?.resetNextSteps));
    }
  };

  useEffect(() => {
    formRef.current?.formik?.setSubmitting?.(wizard.state.submitting);
  }, [wizard.state.submitting]);

  const renderForm = () => {
    return <InlineForm ref={formRef}
                       fields={fieldsMemo}
                       fieldData={fieldData}
                       autoFocus={true}
                       onValidating={handleValidating}
                       onChange={handleChange}
                       onError={handleFieldError}
                       onChangeDirect={handleChangeDirect}
                       onSubmit={handleSubmit}
                       {...InlineFormProps}/>
  };

  const renderTitle = () => {
    const step = wizard.steps[wizard.state.stepIndex];
    const title = step.title;
    const subtitle = step.subSteps?.[wizard.state.subStepIndex || 0].subtitle ?? step.subtitle;

    if (title || subtitle) {
      return <Box className="WizardContent-title">
        {title ? <H3>{title}</H3> : null}
        {subtitle ? <H6>{subtitle}</H6> : null}
      </Box>
    }
  }

  return <StyledWizardContent ref={innerRef} key={step.name} {...innerProps}>
    {renderTitle()}
    {fieldsMemo.length ? renderForm() : null}
    {innerProps.children}
  </StyledWizardContent>
});

WizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  subSteps: PropTypes.array,
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
  onDirty: PropTypes.func,
  onChange: PropTypes.func,
  onChangeDirect: PropTypes.func,
  onSubmit: PropTypes.func,
  InlineFormProps: PropTypes.object
};

WizardContent.defaultProps = {};

export default WizardContent;
