import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledEntitySystemInfoProfileCardContent = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.layout('1sp')};
  
  .EntitySystemInfoProfileCardContent {
    &-title {
      flex-grow: 1;
      width: 1px;
      overflow: hidden;
      > * {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    &-services {
      display: flex;
      align-items: center;
      gap: ${props => props.theme.layout('1sp')};
    }
    
    &-merge {
      padding: ${props => props.theme.layout('1sp')};
    }
    
    &-image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props => props.theme.property('palette.primary.states.selected')};
      border-radius: ${props => props.theme.radius(1.5)};

      width: ${props => props.theme.layout(40)};
      max-width: ${props => props.theme.layout(40)};
      height: ${props => props.theme.layout(40)};
      max-height: ${props => props.theme.layout(40)};
      padding: ${props => props.theme.spacing(1)};

      img, .Icon {
        filter: grayscale(1);
        opacity: 0.2;
      }
      
      img {
        width: ${props => props.theme.layout(21)};
        height: ${props => props.theme.layout(21)};
      }

      .Skeleton {
        min-width: ${props => props.theme.layout(24)};
        min-height: ${props => props.theme.layout(24)};
      }
      
      &.active {
        background-color: ${props => props.theme.property('palette.background.paper')};
        box-shadow: ${props => props.theme.property('shadows.1')};

        img, .Icon {
          opacity: 1;
          filter: unset;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitySystemInfoProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitySystemInfoProfileCardContent.defaultProps = {}

export default StyledEntitySystemInfoProfileCardContent;
