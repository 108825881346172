import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import DialogContent from '@mui/material/DialogContent';

const StyledEntitiesAnalyseMatchDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  
  ${ComponentStyles}
`;

StyledEntitiesAnalyseMatchDialogContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseMatchDialogContent.defaultProps = {}

export default StyledEntitiesAnalyseMatchDialogContent;
