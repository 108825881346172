import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Box from 'components/atoms/Layout/Box/Box';
import StyledCollectionEntitiesTagDistributionGraphCard
  from 'components/organisms/Cards/CollectionEntitiesTagDistributionGraphCard/CollectionEntitiesTagDistributionGraphCard.styles';
import {useCollectionStatCollectionTags} from 'services/collection/stat/stat.utils';
import TagGroupPieChart from 'components/organisms/Charts/TagGroupPieChart/TagGroupPieChart';
import {P} from 'components/atoms/Text/Typography/Typography';
import {useLinkNavigate} from 'helpers/hooks/links';

const CollectionEntitiesTagDistributionGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesTagDistributionGraphCard');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const tagGroups = collection?.tagGroups;
  const graphState = tableProvider.graphState;

  const [breakdown, setBreakdown] = useState(null);

  const navigate = useLinkNavigate();

  const listState = useEffectItem(tableProvider.appliedListState());
  const tagGroupId = graphState.graphSettings['tagDistribution']?.filter?.tagGroupId ?? +tagGroups?.[0]?.groupId;

  const [tagsBreakdown, isLoading] = useCollectionStatCollectionTags(
    collection?.collectionId, listState.search, listState.filter);

  useEffect(() => {
    if (tagsBreakdown) {
      const bd = tagsBreakdown.find((bd) => +bd.groupId === +tagGroupId);
      const tagGroup = (tagGroups || []).find((tg) => +tg.groupId === +tagGroupId);
      if (bd && tagGroup) {
        setBreakdown({...bd, ...tagGroup});
      }
    }
  }, [tagsBreakdown, tagGroups, tagGroupId]);

  const fields = useMemo(() => {
    const options = (tagGroups || []).map((tg) => ({
      label: tg.name,
      value: +tg.groupId
    }));

    const initialOption = options.find((opt) => +opt.value === +tagGroupId) ?? options?.[0];

    return [{
      name: 'tagGroupId',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a category',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: options
    }];
  }, [tagGroups, tagGroupId]);

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'tagDistribution': {
        ...current?.['tagDistribution'],
        filter: {
          ...current?.['tagDistribution']?.filter,
          tagGroupId: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'tagDistribution': {
        ...current?.['tagDistribution'],
        visibility
      }
    }));
  };

  const handleClick = (e, release, data) => {
    if (data?.id && data?.name && breakdown?.groupId) {
      const prefix = breakdown.groupId;
      const postfix = data?.id < 0 ? 0 : data?.name;
      navigate({
        event: e,
        to: `/collections/${collection.collectionId}/entities?custom=tag:${prefix}_${postfix}`,
        keepSearchParams: true
      });
    }
  };

  return <StyledCollectionEntitiesTagDistributionGraphCard ref={ref} {...innerProps}
                                                           title="Category distribution"
                                                           context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                                          fields={fields} /> : null)}>
    {(breakdown?.breakdown || isLoading) ?
      <Box className="CollectionEntitiesTagDistributionGraphCard-content">
        <TagGroupPieChart breakdown={breakdown}
                          showName={true}
                          showLegend={true}
                          showActiveShape={true}
                          isLoading={isLoading}
                          visibility={graphState.graphSettings['tagDistribution']?.visibility}
                          onClick={handleClick}
                          onVisibilityChange={handleVisibilityChange}/>
      </Box> : null}

    {(!breakdown?.breakdown && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No tag data found</P>
      </Box> : null}
  </StyledCollectionEntitiesTagDistributionGraphCard>
});

CollectionEntitiesTagDistributionGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesTagDistributionGraphCard.defaultProps = {};

export default CollectionEntitiesTagDistributionGraphCard;
