import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const StyledEntitiesAnalyseTagWizardContent = styled(Box)`
  &.EntitiesAnalyseTagWizardContent {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.layout('2sp')};
    padding-bottom: 0 !important;
  }

  .EntitiesAnalyseTagWizardContent {
    &-subtitle {
      display: flex;
      align-items: center;
      gap: ${props => props.theme.layout('2sp')};
      
      .H6 {
        flex-grow: 1;
        color: ${props => props.theme.property('palette.text.secondary')};
      }
      .ScoreLinearProgress {
        min-width: min(${props => props.theme.layout(240)}, 40%);
        max-width: ${props => props.theme.layout(240)};
      }
    }
    &-table {
      flex-grow: 1;
      
      .MuiTableRow-head {
        background-color: ${props => utils.rgba2Rgb(props.theme.property('palette.primary.states.hover'))};
        .TableHeader {
          padding: ${props => props.theme.layout('0.75sp')} ${props => props.theme.layout('1sp')};
          .TableHeaderText {
            line-height: unset;
          }
        }
      }

      .MuiTableRow-head, .MuiTableBody-root {
        th:first-child, td:first-child {
          padding-left: ${props => props.theme.layout('2sp')};
        }

        th:first-child, td:last-child {
          padding-right: ${props => props.theme.layout('2sp')};
        }
      }

      .MuiTableContainer-root {
        padding-bottom: ${props => props.theme.spacing(13)};
      }

      .TableCell-dropdown_-1, .TableCell-dropdown_-2, .TableCell-dropdown_-3 {
        .ScoreLinearProgress {
          width: min(100%, ${props => props.theme.layout(92)});
          .Skeleton {
            height: ${props => props.theme.layout('1.5sp')};
            transform: scale(1, 1);
          }
        }
      }
    }
    &-controls {
      z-index: 2;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: ${props => props.theme.layout('2sp')};

      background-color: ${props => utils.rgba2Rgb(props.theme.property('palette.primary.states.hover'))};
      
      .SplitBar-context {
        display: flex;
        gap: ${props => props.theme.layout('3sp')};
        
        .EntitiesAnalyseTagWizardContent-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          
          min-width: ${props => props.theme.layout('10sp')}; 
          * {
            white-space: nowrap;
          }
        }
        
        .ListItem {
          overflow: visible !important;
          
          .Legend-item-content {
            min-width: fit-content;
            overflow: visible !important;

            * {
              max-width: unset !important;
              overflow: visible !important;
            }
          }

          .Legend-item-title {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
    
  ${ComponentStyles}
`;

StyledEntitiesAnalyseTagWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseTagWizardContent.defaultProps = {}

export default StyledEntitiesAnalyseTagWizardContent;
