import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import SectionPanel from 'components/organisms/SectionPanels/SectionPanel/SectionPanel';

const StyledEntitySectionPanel = styled(SectionPanel)`
  
  ${props => props.theme.breakpoints.down('lg')} {
    .SectionPanel-panel {
      .EntityProfile {
        .ProfileCard-content-systemInfo {
          .ProfileCardContent-loader {
            .systemInfo {
              > .Box:first-child {
                min-width: unset;
                .Skeleton {
                  height: ${props => props.theme.layout(32)};
                  &:last-child {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  ${props => props.theme.breakpoints.down('sm')} {
    .SectionPanel-panel {
      .EntityProfile {
        .ProfileCard-content-systemInfo {
          .EntitySystemInfoProfileCardContent {
            justify-content: space-between;

            &-title {
              display: none;
            }

            &-merge {
              padding: ${props => props.theme.layout('0.75sp')};
            }

            &-image {
              width: ${props => props.theme.layout(36)};
              max-width: ${props => props.theme.layout(36)};
              height: ${props => props.theme.layout(36)};
              max-height: ${props => props.theme.layout(36)};
              padding: ${props => props.theme.spacing(0.75)};
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitySectionPanel.propTypes = {
  theme: PropTypes.object
};

StyledEntitySectionPanel.defaultProps = {}

export default StyledEntitySectionPanel;
