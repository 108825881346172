import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import ActionSwitch from 'components/molecules/Switches/ActionSwitch/ActionSwitch';
import TasksList from 'components/organisms/Lists/TasksList/TasksList';
import {useCommentToggleTaskDone} from 'services/comment/comment.utils';
import {useCommentList} from 'services/comment/comment.hooks';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import StyledDealflowTasksGraphCard
  from 'components/organisms/Cards/DealflowTasksGraphCard/DealflowTasksGraphCard.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';

const DealflowTasksGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowTasksGraphCard', {
    children: ['tasks', 'stepper']
  });

  const authorize = useAuthorize();
  const tableProvider = useTable();
  const profileProvider = useProfile();
  const graphState = tableProvider.graphState;

  const tasksSwitched = Boolean(graphState.graphSettings['openTasks']?.filter?.switched);
  const entityListState = useEffectItem(tableProvider.appliedListState());

  const tasks = useCommentList({
    page: 0,
    pageSize: 10,
    filter: tasksSwitched ? [{id: 'open', value: true}, {id: 'responsible', value: true}] : [{id: 'open', value: true}],
    sort: [{id: 'dueDate', desc: false}, {id: 'createdAt', desc: true}],
    entityFilter: {
      ...utils.filter2Object(entityListState.filter),
      search: entityListState.search
    },
    task: true,
  }, {
    ...constants.queryOptions.infinite
  });

  const setGraphSettingsEvent = useEffectEvent(graphState.setGraphSettings);
  const switchAction = useMemo(() => ({
    label: 'My tasks',
    active: tasksSwitched,
    onClick: (e) => {
      setGraphSettingsEvent?.((current) => ({
        ...current,
        'openTasks': {
          ...current?.['openTasks'],
          filter: { switched: !tasksSwitched }
        }
      }));
      e.preventDefault();
    },
  }), [setGraphSettingsEvent, tasksSwitched])

  const renderActionSwitch = () => {
    return <ActionSwitch action={switchAction}
                         SwitchProps={{
                           size: 'medium',
                           color: 'primary'
                         }}/>
  }

  const toggleDone = useCommentToggleTaskDone();
  const taskCardProps = useMemo(() => ({
    onToggle: (task, done) => {
      return toggleDone?.(task, done);
    },
    onCanUpdate: (task, markDone = false) => {
      return authorize({
        attribute: markDone ? 'task.markDone' : 'task.update',
        meta: {task}
      });
    },
    orientation: 'horizontal',
    showRelation: true
  }), [authorize, toggleDone]);

  const handleClick = (e, task) => {
    if (!e.defaultPrevented) {
      profileProvider.openTask(task.commentId);
    }
  }

  const taskCount = utils.formatNumber(tasks.meta?.resultsCount ?? 0);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowTasksGraphCard ref={ref} {...innerProps}
                                       title={(tasks.status.isLoading ? 'Tasks' : `${taskCount ?? 0} task${(tasks.meta?.resultsCount ?? 0) === 1 ? '' : 's'}`)}
                                       context={renderActionSwitch()}
                                       isLoading={tableProvider.isLoading() || tasks.status.isLoading}>
    {(tasks.data?.length > 0 || tasks.status.isLoading) ?
      <TasksList className="DealflowTasksGraphCard-tasks"
                 tasks={tasks}
                 onClick={handleClick}
                 isLoading={tasks.status.isLoading}
                 TaskCardProps={taskCardProps} /> : null}
    {(tasks.data?.length === 0 && !tasks.status.isLoading) ?
      <Box className="GraphCard-empty">
        <P>No task(s) found</P>
      </Box> : null}
  </StyledDealflowTasksGraphCard>
});

DealflowTasksGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

DealflowTasksGraphCard.defaultProps = {};

export default DealflowTasksGraphCard;
