import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledBadgeOption = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.layout('1sp')};
  
  .Badge {
    margin-top: ${props => props.theme.layout(1)};
  }
  
  &.BadgeOption-reverse {
    flex-direction: row-reverse;
  }
  
  ${ComponentStyles}
`;

StyledBadgeOption.propTypes = {
  theme: PropTypes.object
};

StyledBadgeOption.defaultProps = {}

export default StyledBadgeOption;
