import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import AutocompleteField from 'components/molecules/Fields/AutocompleteField/AutocompleteField';

const StyledUserField = styled(AutocompleteField)`
  .UserField-readOnlyOption {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.layout('1sp')};

    .Typography {
      width: 1px;
      flex-grow: 1;
      max-width: fit-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  ${ComponentStyles}
`;

StyledUserField.propTypes = {
  theme: PropTypes.object
};

StyledUserField.defaultProps = {
}

export default StyledUserField;
