import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTagsForm from 'components/organisms/Forms/TagsForm/TagsForm.styles';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import utils from 'helpers/utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import TagForm from 'components/organisms/Forms/TagForm/TagForm';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import {arrayMove} from '@dnd-kit/sortable';

const TagsForm = React.forwardRef((props, ref) => {
  const {
    tags,
    hasPoints,
    autoTagType,
    required,
    readOnly,
    disabled,
    error,
    multiple,
    onChange,
    onError,
    ...innerProps
  } = useComponentProps(props, 'TagsForm');

  const innerRef = useRef(null);
  const tagRefs = useRef({});

  const [internalState, setInternalState] = useState({
    tags: []
  });

  const tagsForm = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    submit: (touch = true) => {
      return Promise.all(Object.keys(tagRefs.current).map((tagId) => {
        return tagRefs.current[tagId]?.ref?.submit?.(touch);
      }));
    },
    validate: (touch = true) => {
      return Promise.all(Object.keys(tagRefs.current).map((tagId) => {
        return tagRefs.current[tagId]?.ref?.validate?.(touch);
      }))
        .then((errors) => {
          return errors.reduce((o, f) => ({...o, ...utils.cleanObject(f)}), {});
        });
    },
    reset: (state) => {
      return Promise.all(Object.keys(tagRefs.current).map((tagId) => {
        return  tagRefs.current[tagId]?.ref?.reset?.(state);
      }));
    }
  }), []);

  useImperativeHandle(ref, () => tagsForm);

  useLayoutEffect(() => {
    let maxPos = 0, minId = 0;

    const tgs = (tags ?? [])
      .map((t, idx) => {
        maxPos = Math.max(maxPos, idx);
        if (!utils.isDefined(t.tagId)) {
          minId = minId - 1;
        } else if (+t.tagId < 0) {
          minId = Math.min(minId, +t.tagId);
        }

        return {
          ...t,
          pos: idx,
          tagId: !utils.isDefined(t.tagId) ? minId : +t.tagId
        }
      });

    if (!readOnly && tgs.length === 0) {
      tgs.push({tagId: minId - 1, pos: maxPos + 1});
    }

    setInternalState(utils.updater({
      tags: tgs.sort((a, b) => +a.pos - +b.pos)
    }, true));
  }, [tags, readOnly]);

  const handleAddTag = () => {
    setInternalState((current) => {
      const maxPos = current.tags.reduce((p, t) => Math.max(p, +t.pos), 0);
      const minId = current.tags.reduce((i, t) => +t.tagId < 0 ? Math.min(i, +t.tagId) : i, 0);

      return {
        ...current,
        tags: current.tags.concat([{tagId: minId - 1, pos: maxPos + 1}])
      }
    });
  }

  const doErrors = () => {
    const errorIds = Object.keys(tagRefs.current).reduce((o, k) => {
      return tagRefs.current[k].ref && tagRefs.current[k].error ? {...o, [k]: true} : o;
    }, {});

    const error = !utils.isEmpty(errorIds);

    onError?.(error);
    setInternalState(utils.updater({ error, errorIds }, true));
  }

  const handleDragDrop = (id, containerId, position) => {
    let newValue = utils.clone(internalState.tags, true);
    const idx = internalState.tags.findIndex((t) => +t.tagId === +id);

    if (idx !== position) {
      newValue = arrayMove(internalState.tags, idx, position);
      newValue.forEach((t, idx) => {
        t.pos = idx;
      });

      setInternalState(utils.updater({tags: newValue}, true));
      onChange?.(newValue);
    }
  }

  const handleChange = (tag) => (values) => {
    const idx = internalState.tags.findIndex((t) => +t.tagId === +tag.tagId);
    
    if (idx !== -1) {
      const newValue = [
        ...internalState.tags.slice(0, idx),
        {...tag, ...values},
        ...internalState.tags.slice(idx + 1),
      ];

      setInternalState(utils.updater({tags: newValue}, true));
      onChange?.(newValue);
    }
  }

  const handleDelete = (tag) => () => {
    const reset = tagRefs.current[tag.tagId]?.ref?.reset;
    delete tagRefs.current[tag.tagId];
    utils.retry(() => {
      reset?.({touched: {}});
    }, 3);

    const newValue = internalState.tags
      .filter((t) => +t.tagId !== +tag.tagId);

    setInternalState(utils.updater({tags: newValue}, true));
    onChange?.(newValue);
    doErrors();
  }

  const handleRef = (tag) => (ref) => {
    tagRefs.current[tag.tagId] = {
      ...tagRefs.current[tag.tagId],
      ref
    };
  }

  const handleError = (tag) => (error) => {
    tagRefs.current[tag.tagId] = {
      ...tagRefs.current[tag.tagId],
      error
    };

    doErrors();
  }

  const renderTag = (tag, only) => {
    const hasError = internalState.tags.filter((t) => !internalState.errorIds?.[t.tagId])
      .find((t) => !internalState.errorIds?.[tag.tagId] &&
        +t.tagId !== +tag.tagId && t.value?.toLowerCase() === tag.value?.toLowerCase());

    return <TagForm ref={handleRef(tag)}
                    key={tag.tagId}
                    className="TagsForm-tag"
                    tag={tag}
                    only={only}
                    error={error}
                    errorDup={Boolean(hasError)}
                    required={required || !only}
                    readOnly={readOnly}
                    disabled={disabled}
                    removable={internalState.tags.length > 1}
                    hideActions={!multiple}
                    hasPoints={hasPoints}
                    autoTagType={autoTagType}
                    onChange={handleChange(tag)}
                    onDelete={handleDelete(tag)}
                    onError={handleError(tag)}/>
  }

  return <StyledTagsForm ref={innerRef} {...innerProps}>
    <DndContext>
      <List sortable={!(readOnly || disabled)}
            dragHandle={true}
            gap={8} catchFocus={false}
            onDragDrop={handleDragDrop}>
        {internalState.tags?.map((tag) => {
          return <ListItem key={tag.tagId} data-key={tag.tagId} density="densest">
            {renderTag(tag, internalState.tags.length === 1)}
          </ListItem>
        })}
      </List>
      {(multiple && !(readOnly || disabled)) ?
        <Button variant="text"
                disabled={Boolean(error || internalState.error || internalState.tags.find((tg) => utils.isEmpty(tg.value)))}
                startIcon={<Icon icon={Add} />}
                onClick={handleAddTag}>
          Add tag
        </Button> : null}
    </DndContext>
  </StyledTagsForm>
});

TagsForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tags: PropTypes.array,
  hasPoints: PropTypes.bool,
  autoTagType: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func
};

TagsForm.defaultProps = {
  multiple: true
};

export default TagsForm;
