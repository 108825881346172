import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledContextCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0;
  row-gap: ${props => props.theme.layout('1.25sp')};
  box-shadow: unset;

  *,
  *::before,
  *::after {
    scroll-margin: 0;
  }

  .ContextCard {
    &-header {
      padding: 0;

      .MuiCardHeader-content {
        padding-right: ${props => props.theme.spacing(1)};
      }

      .MuiCardHeader-action {
        margin: 0;
      }
    }
    
    &-content {
      flex-grow: 1;
    }

    &-actions {
      padding: 0;
    }
  }
  
  &.ContextCard-inline {
    flex-direction: row;
    
    .ContextCard {
      &-header {
        &-title {
          white-space: nowrap;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledContextCard.propTypes = {
  theme: PropTypes.object
};

StyledContextCard.defaultProps = {}

export default StyledContextCard;
