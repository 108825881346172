import React, {useEffect, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import {useCommentMarkAllRead} from 'services/comment/comment.hooks';
import TimelineList from 'components/organisms/Lists/TimelineList/TimelineList';
import {useClientTimelineList, useTimelineMarkAllRead} from 'services/client/timeline/timeline.hooks';
import {useAuthUserId} from 'services/auth/auth.utils';
import {usePopper} from 'components/molecules/Poppers/ContextPopper/ContextPopper';
import StyledDealflowNotificationList
  from 'components/organisms/Lists/DealflowNotificationList/DealflowNotificationList.styles';

const DealflowNotificationList = React.forwardRef((props, ref) => {
  const {
    client,
    ...innerProps
  } = useComponentProps(props, 'DealflowNotificationList');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const popper = usePopper();

  const userId = useAuthUserId();

  const clientCommentAllRead = useCommentMarkAllRead();
  const clientTimelineAllRead = useTimelineMarkAllRead();

  const timeline = useClientTimelineList({
    clientId: client?.clientId,
    collectionId: client?.universeCollectionId,
    page: 0,
    pageSize: 15,
    filter: [
      {id: 'notification', value: false},
      {id: 'actionlist', value: true},
      {id: 'excludedTypes', value: ['additions', 'status', 'csi', 'deal_leader']},
    ]
  }, {
    ...constants.queryOptions.infinite,
    enabled: utils.isDefined(client?.clientId) && client?.universeCollectionId > 0
  });

  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.refs?.ref?.current?.querySelector('.DataList-list')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [client?.universeCollectionId]);


  useEffect(() => {
    const unreadComments = timeline.data?.filter?.((ti) => ti.recordType === 'comment' && ti.isRead === false)
    if (unreadComments?.length > 0) {
      clientCommentAllRead.mutation.mutate({
        clientId: client?.clientId,
        group: true,
        commentIds: unreadComments.map((d) => d.commentId)
      });
    }
    const unreadTasks = timeline.data?.filter?.((ti) => ti.relationType === 'task' && +ti.userId === +userId && ti.isRead === false)
    if (unreadTasks?.length > 0) {
      clientTimelineAllRead.mutation.mutate({
        clientId: client?.clientId,
        taskIds: unreadTasks.map((d) => d.relationId)
      });
    }
  }, [client?.clientId, userId, clientCommentAllRead.mutation, clientTimelineAllRead.mutation, timeline.data]);

  const isItemEqual = (update, selected) => {
    return +update.timelineId === +selected.timelineId;
  }

  const handleEdit = (e, item, editing) => {
    if (item?.timelineType === 'task') {
      popper?.close?.();
    }  else {
      popper?.clickAway(!editing);
      e?.stopPropagation();
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowNotificationList ref={innerRef} {...innerProps}>
    <TimelineList count={timeline?.meta?.resultsCount}
                  timeline={timeline.data}
                  loaderCount={4}
                  dataKey="timelineId"
                  emptyText="No notifications found"
                  isItemEqual={isItemEqual}
                  onFetchMore={timeline.query?.fetchNextPage}
                  onEdit={handleEdit}
                  isLoading={timeline.status?.isLoading}
                  showProgressBar={timeline.status?.isLoadingNext}/>
  </StyledDealflowNotificationList>
});

DealflowNotificationList.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  client: PropTypes.object
};

DealflowNotificationList.defaultProps = {};

export default DealflowNotificationList;
