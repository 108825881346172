import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import StyledChooseCollectionCategoriesWizardContent
  from 'components/organisms/WizardContent/ChooseCollectionCategoriesWizardContent/ChooseCollectionCategoriesWizardContent.styles';
import {useCollectionGenerateTagGroups} from 'services/collection/collection.hooks';

const ChooseCollectionCategoriesWizardContent = React.forwardRef((props, ref) => {
  const {
    collection,
    wizard,
    step,
    maxTagGroups,
    showAutoTagType,
    ...innerProps
  } = useComponentProps(props, 'ChooseCollectionCategoriesWizardContent');

  const state = wizard.getStepState();
  const setDataEvent = useEffectEvent(wizard.setData);
  const setStateEvent = useEffectEvent(wizard.setStepState);

  const generateTagGroups = useCollectionGenerateTagGroups();

  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'description',
      label: 'Taxonomy description',
      placeholder: 'Type a taxonomy description',
      type: constants.formFieldTypes.textarea,
      validation: constants.formFieldValidationTypes.text,
      required: true,
      FormFieldProps: {
        variant: 'staticLabel'
      }
    });

    if (showAutoTagType) {
      fields.push({
        name: 'autoTagType',
        label: 'How do you want to analyse the companies?',
        entity: 'analyser',
        type: constants.formFieldTypes.list,
        validation: constants.formFieldValidationTypes.text,
        conversion: constants.formFieldConversionTypes.value,
        filter: [`-${constants.collection.autoTagTypes.noAutoAssign}`],
        options: 'autoTagTypes',
        required: true,
        FormFieldProps: {
          multiple: false,
          ListProps: {
            catchFocus: false
          }
        }
      });
    }

    if (state?.noTagGroups) {
      fields.push({
        section: true,
        type: constants.formSectionTypes.info,
        info: 'No new categories where found based on the current information. Please try altering your taxonomy description or add more companies to the collection.'
      });
    }

    return fields;
  }, [showAutoTagType, state?.noTagGroups]);

  const handleSubmit = (values) => {
    return generateTagGroups.mutation.mutateAsync({
      collectionId: collection.collectionId,
      description: values.description
    })
      .then((res) => {
        const outputTagGroups = res.response.data.data?.map((tg) => ({
          ...tg,
          autoTagType: values?.autoTagType ?? constants.collection.autoTagTypes.noAutoAssign,
          color: tg.color ?? innerProps.theme.pickAColor('tagGroup')
        })).slice(0, maxTagGroups ?? constants.numbers.maxInt);

        if (outputTagGroups?.length > 0) {
          setDataEvent?.((current) => ({
            ...current,
            [wizard.dataKey]: {
              ...current[wizard.dataKey],
              outputTagGroups
            }
          }));
        } else {
          setStateEvent?.({noTagGroups: true});
          throw new Error('Retrieving category information failed');
        }
      })
      .catch(() => {
        throw new Error('Retrieving category information failed');
      });
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledChooseCollectionCategoriesWizardContent ref={ref} {...innerProps}
                                                        wizard={wizard}
                                                        step={step}
                                                        fields={fields}
                                                        onSubmit={handleSubmit}/>
});

ChooseCollectionCategoriesWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  collection: PropTypes.object,
  maxTagGroups: PropTypes.number,
  showAutoTagType: PropTypes.bool
};

ChooseCollectionCategoriesWizardContent.defaultProps = {};

export default ChooseCollectionCategoriesWizardContent;
