import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import TasksBarChart from 'components/organisms/Charts/TasksBarChart/TasksBarChart';
import Box from 'components/atoms/Layout/Box/Box';
import StyledCollectionEntitiesTaskBreakdownGraphCard
  from 'components/organisms/Cards/CollectionEntitiesTaskBreakdownGraphCard/CollectionEntitiesTaskBreakdownGraphCard.styles';
import {useClientStatisticTaskBreakdown} from 'services/client/statistic/statistic.utils';
import {P} from 'components/atoms/Text/Typography/Typography';

const CollectionEntitiesTaskBreakdownGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesTaskBreakdownGraphCard');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const graphState = tableProvider.graphState;

  const [breakdown, setBreakdown] = useState(null);

  const listState = useEffectItem(tableProvider.appliedListState());
  const year = graphState.graphSettings['tasks']?.filter?.year;
  const months = graphState.graphSettings['tasks']?.filter?.months ?? 1;

  const [tasksBreakdown, isLoading] = useClientStatisticTaskBreakdown(
    year, months, collection?.collectionId,
    listState.search, listState.filter,
    {
      enabled: collection?.collectionId > 0
    });

  useEffect(() => {
    if (tasksBreakdown) {
      setBreakdown(tasksBreakdown);
    }
  }, [tasksBreakdown]);

  const fields = useMemo(() => {
    const initialOption = constants.data.periodMonths.find((opt) => opt.value === months);

    return [{
      name: 'months',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a period',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: constants.data.periodMonths
    }];
  }, [months]);

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'tasks': {
        ...current?.['tasks'],
        filter: {
          ...current?.['tasks']?.filter,
          months: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'tasks': {
        ...current?.['tasks'],
        visibility
      }
    }));
  }

  return <StyledCollectionEntitiesTaskBreakdownGraphCard ref={ref} {...innerProps}
                                                         title="Tasks"
                                                         context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                                        fields={fields} /> : null)}>
    {(breakdown?.length > 0 || isLoading) ?
      <Box className="CollectionEntitiesTaskBreakdownGraphCard-content">
        <TasksBarChart breakdown={breakdown}
                       showLegend={false}
                       isLoading={isLoading}
                       visibility={graphState.graphSettings['tasks']?.visibility}
                       onVisibilityChange={handleVisibilityChange} />
      </Box> : null}

    {(breakdown?.length === 0 && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No task(s) found</P>
      </Box> : null}
  </StyledCollectionEntitiesTaskBreakdownGraphCard>
});

CollectionEntitiesTaskBreakdownGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesTaskBreakdownGraphCard.defaultProps = {};

export default CollectionEntitiesTaskBreakdownGraphCard;
