import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledEntitySystemInfoProfileCardContent
  from 'components/organisms/Cards/EntitySystemInfoProfileCardContent/EntitySystemInfoProfileCardContent.styles';
import Typography from 'components/atoms/Text/Typography/Typography';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Img from 'components/atoms/Images/Img/Img';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import CallMerge from '@mui/icons-material/CallMerge';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import EntityMergeDialog from 'components/organisms/Dialogs/EntityMergeDialog/EntityMergeDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useEntityMerge} from 'services/entity/entity.hooks';

const EntitySystemInfoProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    entity,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'EntitySystemInfoProfileCardContent');

  const merge = useEntityMerge();
  const dialogControl = useDialogControl();

  const mergeAction = useMemo(() => ({
    label: 'Merge',
    tooltip: 'Merge company',
    auth: utils.createAuth({attribute: 'entity.merge', meta: {entity}}),
    icon: CallMerge,
    onClick: () => {
      const handleSubmit = (data) => {
        return merge.mutation.mutateAsync({
          entityId: entity?.entityId,
          mergedId: data.mergedId,
          changes: data.changes,
          force: data.force
        });
      };

      dialogControl.show(<EntityMergeDialog entity={entity} onSubmit={handleSubmit} />, true);
    }
  }), [dialogControl, entity, merge.mutation]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitySystemInfoProfileCardContent ref={ref} {...innerProps}>
    <Box className="EntitySystemInfoProfileCardContent-title">
      <Typography variant="subtitle2">This company profile is enriched with the following services</Typography>
      <Typography variant="caption" color="text.secondary">Enhance your Catalist experience by enabling more services!</Typography>
    </Box>
    <ActionIconButton className="EntitySystemInfoProfileCardContent-merge"
                      variant="transparent"
                      density="denser"
                      color="primaryBackground"
                      radius="round"
                      isLoading={isLoading}
                      action={mergeAction}/>
    <Box className="EntitySystemInfoProfileCardContent-services">
      {constants.data.services.filter((s) => Boolean(s.source)).map((service, idx) => {
        const image = service.images[0];
        const active = entity?.availableSources?.disks?.find((s) => s === service.source);
        return <Tooltip key={idx} title={service.label}>
          <Box className={`EntitySystemInfoProfileCardContent-image ${active ? 'active' : ''}`}>
            {image.icon ? (utils.isReactElement(image.icon) ? image.icon : <Icon icon={image.icon}
                                                                                 isLoading={isLoading}
                                                                                 color={image.color}/>) : (
              image.img ? (utils.isReactElement(image.img) ? image.img : <Img src={image.img}
                                                                              isLoading={isLoading}
                                                                              alt={image.alt}/>) : null
            )}
          </Box>
        </Tooltip>
      })}
    </Box>
  </StyledEntitySystemInfoProfileCardContent>
});

EntitySystemInfoProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  entity: PropTypes.object,
  isLoading: PropTypes.bool
};

EntitySystemInfoProfileCardContent.defaultProps = {
};

export default EntitySystemInfoProfileCardContent;


