import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledEntitiesTableBar from 'components/organisms/Bars/EntitiesTableBar/EntitiesTableBar.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import EntitiesAnalyseDialog from 'components/organisms/Dialogs/EntitiesAnalyseDialog/EntitiesAnalyseDialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useCollectionGet} from 'services/collection/collection.hooks';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const EntitiesTableBar = React.forwardRef((props, ref) => {
  const {
    analyseAction,
    filterAction,
    columnAction,
    ...innerProps
  } = useComponentProps(props, 'EntitiesTableBar');

  const tableProvider = useTable();
  const context = tableProvider.context?.data;

  const client = useAuthClient();
  const universeCollection = useCollectionGet({collectionId: client?.universeCollectionId},
    {enabled: Boolean(client?.universeCollectionId)})?.data;
  const isUniverse = Boolean(context?.universeCollectionId === universeCollection?.collectionId);
  const collection = isUniverse ? universeCollection : context;

  const applyActionsEvent = useEffectEvent(tableProvider.updaters?.applyActions);

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const searchField = useMemo(() => ({
    placeholder: 'Search for a company'
  }), []);

  const analyseActionMemo = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'entity.analyse'}),
    onClick: (e) => {
      const handleSubmit = (entityIds, actions, saving, saved) => {
        const message = saving ?? 'Saving the selected actions';
        return utils.observePromise(applyActionsEvent?.({entityIds, actions}), () => {
          return snackbar.show(`${message}...`, null, {
            color: 'info',
            autoHideDuration: null
          });
        }, () => {
          return snackbar.show((saved ?? 'The selected actions have been saved'), null,
            {color: 'success', autoHideDuration: constants.delay.success});
        }, () => {
          return snackbar.show(`${message} failed`, null,
            {color: 'error', autoHideDuration: constants.delay.error});
        }, (id) => {
          snackbar.hide(id);
        });
      }

      dialogControl.show(<EntitiesAnalyseDialog tableProvider={tableProvider}
                                                collection={!isUniverse ? collection : null}
                                                onSubmit={handleSubmit}/>, true);

      e.preventDefault();
    },
    ...analyseAction
  }), [analyseAction, tableProvider, collection, isUniverse, snackbar, dialogControl, applyActionsEvent]);

  const filterActionMemo = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'entity.filters'}),
    ...filterAction
  }), [filterAction]);

  const columnActionMemo = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'entity.columns'}),
    ...columnAction
  }), [columnAction]);

  const entitiesCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.title = innerProps.title ?? (xsDown ? entitiesCount :
    `${entitiesCount} compan${(tableProvider.list?.meta?.resultsCount ?? 0) === 1 ? 'y' : 'ies'}`);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesTableBar ref={ref} {...innerProps}
                                 hideColumn={smDown}
                                 searchField={searchField}
                                 analyseAction={analyseActionMemo}
                                 filterAction={filterActionMemo}
                                 columnAction={columnActionMemo}/>
});

EntitiesTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  analyseAction: PropTypes.object,
  filterAction: PropTypes.object,
  columnAction: PropTypes.object
};

EntitiesTableBar.defaultProps = {
  hideWizard: false
};

export default EntitiesTableBar;
