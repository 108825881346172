import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import SourceCard from 'components/organisms/Cards/SourceCard/SourceCard';
import {useSourceCreate} from 'services/source/source.utils';
import DataList from 'components/organisms/Lists/DataList/DataList';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import StyledSourceWizardSectionPanelContent
  from 'components/organisms/SectionPanels/SourceWizardSectionPanelContent/SourceWizardSectionPanelContent.styles';
import {useCollectionGenerateSources} from 'services/collection/collection.hooks';

const SourceWizardSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    onSubmit,
    onError,
    onCanUpdate,
    collection,
    ...innerProps
  } = useComponentProps(props, 'SourceCreateSectionPanelContent');

  const authorize = useAuthorize();

  const createSource = useSourceCreate();
  const generateSources = useCollectionGenerateSources({
    collectionId: collection?.collectionId
  }, {
    enabled: collection?.collectionId > 0
  });

  const handleClick = (source) => (e) => {
    if (!e.defaultPrevented) {
      if (source?.type === constants.sources.types.database) {
        onSubmit?.({...source, sourceId: -1});
      } else {
        createSource(source)
          .then((res) => {
            const newSource = res.response?.data?.data?.[0];
            onSubmit?.({...source, sourceId: newSource?.params?.publicCollectionId});
          })
          .catch(() => {
            onError?.('Creating source failed');
          });
      }
    }
  };

  const renderCard = (source, state) => {
    return <SourceCard source={source}
                       variant="wizard"
                       title={source.label}
                       description={source.description}
                       icon={source.icon}
                       img={source.img}
                       fullWidth={true}
                       selected={state.selected}
                       onClick={!state.isLoading ? handleClick(source) : null}
                       isLoading={state.isLoading}
                       IconProps={{color: source.color}}/>
  }

  const isItemEqual = (source, selected) => {
    return +source.value === +selected.value;
  }

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const data = useMemo(() => {
    return (generateSources.data?.data || [])
      .filter((s) => (onCanUpdateEvent ? onCanUpdateEvent?.(collection) : true) &&
        authorize({attribute: 'collection.source.create', meta: {collection, source: s}}))
      .map((s, idx) => {
        const sourceDef = constants.data.lookup('sourceTypes', s?.type);

        return {
          ...sourceDef,
          ...s,
          label: s.name,
          value: `${s.name}_${idx}`
        }
      });
  }, [generateSources.data, collection, authorize, onCanUpdateEvent]);

  return <StyledSourceWizardSectionPanelContent ref={ref} {...innerProps}>
    <DataList data={data}
              count={data.length}
              dataKey="value"
              renderItem={renderCard}
              isItemEqual={isItemEqual}
              isLoading={generateSources.status.isLoading}
              emptyText={'No sources where found based on the current information.\nPlease try altering your category name or add more companies to the collection.'}
              ListProps={{
                track: true,
                virtualize: false,
                gap: 8
              }} />
  </StyledSourceWizardSectionPanelContent>
});

SourceWizardSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onCanUpdate: PropTypes.func,
  onDialog: PropTypes.func,
  onSubmit: PropTypes.func,
  collection: PropTypes.object
};

SourceWizardSectionPanelContent.defaultProps = {};

export default SourceWizardSectionPanelContent;
