import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {darken} from 'polished';

const StyledEntitiesAnalyseGraphWizardContent = styled(Box)`
  &.EntitiesAnalyseGraphWizardContent {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    position: relative;
    padding: 0;
  }
  
  .EntitiesAnalyseGraphWizardContent {
    &-loader, &-failed {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: ${props => props.theme.layout('0.5sp')};

      .CircularProgress {
        .MuiCircularProgress-root {
          color: ${props => props.theme.property('palette.divider')};
        }
      }
    }

    &-failed {
      .Icon {
        font-size: ${props => props.theme.fontSize(56)};
      }
      .IconButton {
        color: ${props => darken(0.2, props.theme.property('palette.divider'))};
      }
      .P {
        margin-top: ${props => props.theme.layout(-3)};
      }
    }
    
    &-footer {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      width: 100%;
      bottom: 0;
      right: ${props => props.theme.layout('3sp')};
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: right;
      gap: ${props => props.theme.layout('1sp')};
      padding-bottom: ${props => props.theme.spacing(0.25)};
      padding-right: 0;
    }
  }
  
  ${ComponentStyles}
`;

StyledEntitiesAnalyseGraphWizardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesAnalyseGraphWizardContent.defaultProps = {}

export default StyledEntitiesAnalyseGraphWizardContent;
