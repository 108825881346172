import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ComponentStyles from 'styles/mixins/Component.styles';
import constants from 'helpers/constants';

const StyledButton = styled(Button)`
  text-transform: none;
  border-radius: ${props => props.theme.radius(2)};

  &.Button {
    svg {
      font-size: ${props => props.theme.fontSize(16)};
    }
  }
  
  &.Button-disabled {
    svg {
      color: inherit;
    }
  }
  
  // variants
  ${props => props.theme.state2Selector('Button-contained', null, 'hover')} {
    box-shadow: ${props => props.theme.property('shadows.2')};
  }
  
  &.Button-clickable {
    ${props => props.theme.state2Selector('Button-contained', null, ['active', 'focus', 'focusVisible', 'selected'])} {
      box-shadow: ${props => props.theme.property('shadows.3')};
    }
  }

  &.Button-contained {
    box-shadow: unset;
    ${props => props.theme.color2Css('Button', null, props.color, {
      interactive: props.$clickable,
      type: constants.colorTypes.button
    })}
  }
  
  &.Button-outlined, &.Button-text {
    ${props => props.theme.color2Css('Button', null, props.color, {
      interactive: props.$clickable,
      type: constants.colorTypes.outlined
    })}

    ${props => props.theme.state2Selector('Button', null, 'default')} {
      background-color: transparent;
    }
  }
  
  &.Button-outlined {
    &.Button-plainBorder {
      border: 1px solid ${props => props.theme.property('palette.divider')} !important;
    }
    &.Button-flatBackground {
      ${props => props.theme.state2Selector('Button', null, 'default')} {
        background-color: ${props => props.theme.property(`palette.${props.color}.contrastText`, props.theme.property(`palette.secondary.contrastText`))};
      }
    }
    
    &.Button-grouped:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -${props => props.theme.layout(1)};
    }

    &.Button-grouped:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-color: transparent;
    }
  }
  
  &.Button-flat {
    box-shadow: unset;
  }

  &.Button-flat {
    ${props => props.theme.color2Css('Button', null, props.color, {
      interactive: props.$clickable,
      type: constants.colorTypes.base, // colors all states
    })}
  }
  
  .Button {
    &-badge {
      .MuiBadge-inline {
        &.MuiBadge-badge {
          position: relative;
          align-self: center;
          padding: ${props => props.theme.layout(5)};
          margin-left: ${props => props.theme.layout('1sp')};
        }
      }
    }
  }
  
  &.Button-size-smallest {
    height: ${props => props.theme.layout(18)};
    min-height: ${props => props.theme.layout(18)};
    font-size: ${props => props.theme.fontSize(12)};
    line-height: ${props => props.theme.lineHeight('1f')};
    border-radius: ${props => props.theme.radius(1)};

    padding: ${props => props.theme.layout(0)} ${props => props.theme.layout(4)};

    svg {
      font-size: ${props => props.theme.fontSize(12)};
    }
    
    .MuiButton-startIcon {
      margin-left: -${props => props.theme.layout(2)};
      margin-right: ${props => props.theme.layout(4)};
    }
    .MuiButton-endIcon {
      margin-left: ${props => props.theme.layout(4)};
      margin-right: -${props => props.theme.layout(2)};
    }
  }
  
  &.Button-size-smaller {
    height: ${props => props.theme.layout(28)};
    min-height: ${props => props.theme.layout(28)};
    padding: ${props => props.theme.layout(4)} ${props => props.theme.layout(6)};
    border-radius: ${props => props.theme.radius(1)};
    
    .MuiButton-startIcon {
      margin-left: -${props => props.theme.layout(2)};
      margin-right: ${props => props.theme.layout(4)};
    }
    .MuiButton-endIcon {
      margin-left: ${props => props.theme.layout(4)};
      margin-right: -${props => props.theme.layout(2)};
    }
  }
  
  &.Button-size-small {
    height: ${props => props.theme.layout(32)};
    min-height: ${props => props.theme.layout(32)};
    padding: ${props => props.theme.layout(4)} ${props => props.theme.layout(8)};
    border-radius: ${props => props.theme.radius(1)};
  }
  
  &.Button-size-medium {
    height: ${props => props.theme.layout(36)};
    min-height: ${props => props.theme.layout(36)};
    padding: ${props => props.theme.layout(5)} ${props => props.theme.layout(12)};
  }
  
  &:not(.Button-clickable) {
    cursor: default;

    .MuiTouchRipple-root {
      display: none;
    }
  }
  
  ${ComponentStyles}
`;

StyledButton.propTypes = {
  theme: PropTypes.object
};

StyledButton.defaultProps = {
}

export default StyledButton;
