import React, {useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import CollectionSectionPanelHeader
  from 'components/organisms/SectionPanels/CollectionSectionPanelHeader/CollectionSectionPanelHeader';
import CollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile';
import useMediaQuery from '@mui/material/useMediaQuery';
import StyledCollectionFieldsSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionFieldsSectionPanelContent/CollectionFieldsSectionPanelContent.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const info = 'Add and manage fields for your collection company profiles';

const CollectionFieldsSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    section,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'CollectionFieldsSectionPanelContent');

  const innerRef = useRef(null);
  const profileProvider = useProfile();
  const onboarding = profileProvider?.state?.settings?.onboarding;

  useImperativeHandle(ref, () => innerRef.current);

  const setSettingsEvent = useEffectEvent(profileProvider.setSettings);

  const handleShowCard = (card) => {
    return card.name.startsWith('customField');
  }

  const handleCardClose = () => {
    if (onboarding) {
      setSettingsEvent?.({onboarding: false});
    }
  }

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return <StyledCollectionFieldsSectionPanelContent ref={innerRef} {...innerProps}>
    <CollectionSectionPanelHeader title={section.title} info={!smDown ? info : null}/>
    <CollectionProfile columns={smDown ? 1 : 2}
                       variant="grid"
                       forceEditNew={onboarding}
                       onShowCard={handleShowCard}
                       onCloseCard={handleCardClose}
                       onCanUpdate={onCanUpdate} />
  </StyledCollectionFieldsSectionPanelContent>

});

CollectionFieldsSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  section: PropTypes.object,
  onCanUpdate: PropTypes.func
};

CollectionFieldsSectionPanelContent.defaultProps = {};

export default CollectionFieldsSectionPanelContent;
