import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import DealflowDevelopmentLineChart
  from 'components/organisms/Charts/DealflowDevelopmentLineChart/DealflowDevelopmentLineChart';
import StyledDealflowDevelopmentGraphCard
  from 'components/organisms/Cards/DealflowDevelopmentGraphCard/DealflowDevelopmentGraphCard.styles';
import constants from 'helpers/constants';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useClientStatisticDealflowDevelopment} from 'services/client/statistic/statistic.utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import {useLinkNavigate} from 'helpers/hooks/links';

const DealflowDevelopmentGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowDevelopmentGraphCard');

  const tableProvider = useTable();
  const graphState = tableProvider.graphState;

  const navigate = useLinkNavigate();

  const [dealflow, setDealflow] = useState(null);

  const listState = useEffectItem(tableProvider.appliedListState());
  const years = graphState.graphSettings['development']?.filter?.years ?? 3;

  const [dealflowDevelopment, isLoading] = useClientStatisticDealflowDevelopment(
    years,
    listState.search,
    listState.filter
  );

  useEffect(() => {
    if (dealflowDevelopment) {
      setDealflow(dealflowDevelopment);
    }
  }, [dealflowDevelopment]);

  const fields = useMemo(() => {
    const initialOption = constants.data.periodYears.find((opt) => opt.value === years);

    return [{
      name: 'years',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a period',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: constants.data.periodYears
    }];
  }, [years]);

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'development': {
        ...current?.['development'],
        filter: {
          ...current?.['development']?.filter,
          years: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'development': {
        ...current?.['development'],
        visibility
      }
    }));
  }

  const handleClick = (e, release, data, dataKey) => {
    const year = +dataKey;
    let month = data[`${dataKey}-meta`].month;
    if (year && month) {
      month = new Date(month);
      navigate({
        event: e,
        to: `/dealflow/table?custom=dealflowDevelopment:${year}${(month.getMonth() + 1) < 10 ? '0' : ''}${month.getMonth() + 1}`,
        keepSearchParams: true
      });
    }
  };

  const total = (dealflow ?? []).reduce((t, d) => t + d?.count, 0);

  return <StyledDealflowDevelopmentGraphCard ref={ref} {...innerProps}
                                             title="Dealflow development"
                                             context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                            fields={fields} /> : null)}>
    {(total > 0 || isLoading) ?
      <DealflowDevelopmentLineChart dealflow={dealflow}
                                    showLegend={true}
                                    isLoading={isLoading}
                                    onClick={handleClick}
                                    visibility={graphState.graphSettings['development']?.visibility}
                                    onVisibilityChange={handleVisibilityChange} /> : null}

    {(total === 0 && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No dealflow data found</P>
      </Box> : null}
  </StyledDealflowDevelopmentGraphCard>
});

DealflowDevelopmentGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowDevelopmentGraphCard.defaultProps = {};

export default DealflowDevelopmentGraphCard;
