import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';

const StyledEntityArticlesProfileCardContent = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .EntityArticlesProfileCardContent {
    &-search {
      position: absolute;
      right: -${props => props.theme.layout('0.5sp')};
      width: ${props => props.theme.layout(204)};
      top: -${props => props.theme.layout('6sp')};

      background-color: ${props => props.theme.property('palette.background.paper')};
    }
    
    &-empty {
      height: 100%;
      min-height: ${props => props.theme.layout('6sp')};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
      font-style: italic;

      P {
        white-space: pre-line;
        margin: ${props => props.theme.spacing(1)} 0;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityArticlesProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledEntityArticlesProfileCardContent.defaultProps = {}

export default StyledEntityArticlesProfileCardContent;
