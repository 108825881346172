import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import StyledWizardDialog from 'components/organisms/Dialogs/WizardDialog/WizardDialog.styles';
import AutoAwesome from '@mui/icons-material/AutoAwesome';

const WizardDialog = React.forwardRef((props, ref) => {
  const {
    text,
    placeholder,
    onChange,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'WizardDialog');

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const fields = useMemo(() => ([{
    name: 'wizard',
    inlineLabel: 'Wizard text',
    placeholder: placeholder ?? 'Type a description',
    type: constants.formFieldTypes.textarea,
    validation: constants.formFieldValidationTypes.text,
    initial: text,
    required: true,
    FormFieldProps: {
      autoFocus: true,
      hiddenLabel: true,
      variant: 'outlined',
      debounce: false,
      minRows: 2,
      maxRows: 12,
      InputLabelProps: {
        shrink: true,
      }
    }
  }]), [text, placeholder]);

  const handleChange = (field, value) => {
    onChange?.(value);
    setError(null);
    setValidation(null);
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)(values['wizard'])
      .then(() => {
        innerProps.onClose?.(null, 'saveButtonClick');
      })
      .catch(() => {
        setError('Saving wizard failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e);
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              variant="contained"
              children={'Search'}
              startIcon={<Icon icon={AutoAwesome}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledWizardDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader title="Wizard search" />
    <DialogContent>
      <InlineForm ref={formRef} fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="WizardDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledWizardDialog>
});

WizardDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  text: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

WizardDialog.defaultProps = {};

export default WizardDialog;
