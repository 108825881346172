import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useCollectionStatCompleteness} from 'services/collection/stat/stat.utils';
import CompletenessBarChart from 'components/organisms/Charts/CompletenessBarChart/CompletenessBarChart';
import {useClientCustomFieldCache} from 'services/client/customField/customField.hooks';
import {useAuthClientId} from 'services/auth/auth.utils';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {P} from 'components/atoms/Text/Typography/Typography';
import StyledCollectionEntitiesCompletenessGraphCard
  from 'components/organisms/Cards/CollectionEntitiesCompletenessGraphCard/CollectionEntitiesCompletenessGraphCard.styles';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useCollectionCustomFieldCache} from 'services/collection/customField/customField.hooks';

const CollectionEntitiesCompletenessGraphCard = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesCompletenessGraphCard');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const graphState = tableProvider.graphState;

  const clientId = useAuthClientId();

  const [completeness, setCompleteness] = useState(null);

  const navigate = useLinkNavigate();

  const listState = useEffectItem(tableProvider.appliedListState());
  const fieldSet = graphState.graphSettings['completeness']?.filter?.fieldSet ?? 'general';

  const clientCustomFields = useClientCustomFieldCache({clientId}, {enabled: clientId >= 0});
  const collectionCustomFields = useCollectionCustomFieldCache({clientId, collectionId: collection?.collectionId, addLinks: false},
    {enabled: clientId >= 0 && collection?.collectionId > 0});
  const customFields = ((clientCustomFields.data?.data ?? clientCustomFields.data) ?? [])
    .concat((collectionCustomFields.data?.data ?? collectionCustomFields.data) ?? []);

  const [completenessBreakdown, isLoading] = useCollectionStatCompleteness(
    collection?.collectionId,
    listState.search,
    listState.filter
  );

  useEffect(() => {
    if (completenessBreakdown) {
      setCompleteness(completenessBreakdown);
    }
  }, [completenessBreakdown]);

  const fields = useMemo(() => {
    const initialOption = constants.data.completeness.find((opt) => opt.value === fieldSet);

    return [{
      name: 'fieldSet',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.text,
      placeholder: 'Select a field set',
      initial: initialOption,
      FormFieldProps: {
        autoFocus: false,
        hiddenLabel: true,
        clearable: false,
        size: 'small'
      },
      options: constants.data.completeness
    }];
  }, [fieldSet]);

  const visibleFields = useMemo(() => {
    const option = constants.data.completeness.find((opt) => opt.value === fieldSet);

    let visibleFields = [];
    if (customFields && option) {
      option.fields.forEach((f) => {
        const cf = customFields.find((cf) => utils.camelcase(cf.name) === f);
        if (cf) {
          visibleFields.push({
            label: cf.label,
            value: f
          })
        } else if (f === 'fte') {
          visibleFields.push({
            label: 'FTE',
            value: f
          })
        } else {
          const fh = Object.keys(constants.entity.financeHistory).find((k) => k === f);
          if (fh) {
            visibleFields.push({
              label: fh.label,
              value: f
            })
          } else {
            visibleFields.push({
              label: utils.upperFirst(f),
              value: f
            })
          }
        }
      });
    }
    return visibleFields;
  }, [fieldSet, customFields])

  const handleFilterChange = (field, value) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'completeness': {
        ...current?.['completeness'],
        filter: {
          ...current?.['completeness']?.filter,
          fieldSet: value?.value ?? value
        }
      }
    }));
  };

  const handleVisibilityChange = (visibility) => {
    graphState.setGraphSettings((current) => ({
      ...current,
      'completeness': {
        ...current?.['completeness'],
        visibility
      }
    }));
  };

  const handleClick = (e, release, data) => {
    const type = data.tooltipPayload?.[0]?.dataKey;
    if (type && data?.id) {
      navigate({
        event: e,
        to: `/collections/${collection.collectionId}/entities?custom=completeness:${type === 'complete' ? '' : '-'}${data.id}`,
        keepSearchParams: true
      });
    }
  };

  return <StyledCollectionEntitiesCompletenessGraphCard ref={ref} {...innerProps}
                                                        title="Data completeness"
                                                        context={(fields ? <InlineForm onChange={handleFilterChange}
                                                                                       fields={fields} /> : null)}>
    {(completeness?.total > 0 || isLoading) ? <CompletenessBarChart completeness={completeness}
                                                                    fields={visibleFields}
                                                                    showLegend={false}
                                                                    isLoading={isLoading}
                                                                    onClick={handleClick}
                                                                    visibility={graphState.graphSettings['completeness']?.visibility}
                                                                    onVisibilityChange={handleVisibilityChange} /> : null}
    {(completeness?.total === 0 && !isLoading) ?
      <Box className="GraphCard-empty">
        <P>No company data found</P>
      </Box> : null}
  </StyledCollectionEntitiesCompletenessGraphCard>
});

CollectionEntitiesCompletenessGraphCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesCompletenessGraphCard.defaultProps = {};

export default CollectionEntitiesCompletenessGraphCard;
