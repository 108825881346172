import {useServiceApiMutation} from 'stores/hooks/query';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useAnalyseAssistant (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'analyse/assistant',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useAnalyseCleanup (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'analyse/cleanup',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useAnalyseQuestion (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'analyse/question',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useAnalyseGraph (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'analyse/graph',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}
